
import React, { useState } from 'react';
import Navigation from '../../../components/lnacomponents/navigation';
import LeaveGridParent from './leavegridparent';

const OnleaveContainer = () => {

  const [activeTab, setActiveTab] = useState('own'); // Setting default active tab
  const [secActiveTab, setSecActiveTab] = useState('thisWeek');
  const [tabId,setTabid] = useState('');

  <style>
    {`

              .outerBox{
                position: absolute;
            
                
              }

              .header{
                  margin-top: 70px;
                 width: 100%;
                 height: 50px;
                 background-color: black;
               }

               
            `}
  </style>

  const changeTab = () => {
    
    setActiveTab(activeTab==='own'?'team':'own');
    clearFilter();
  }
  const dataSourceChanged = (pComponentId) => {
    try {
      setTimeout(() => {
        if (document.getElementById(pComponentId) !== null) { document.getElementById(pComponentId).ej2_instances[0].refresh(); }
      });
    } catch (error) { console.log(error); }
  };

const clearFilter = () => {
  if (document.getElementById('leaveReportNew')) {
    document.getElementById('leaveReportNew').ej2_instances[0].clearFiltering();
    document.getElementById('leaveReportNew').style.border = "1px solid #fff";
    dataSourceChanged('leaveReportNew')
  }

}
const  getUidFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('tabId');
};
  React.useEffect(() => {
    const uidFromUrl = getUidFromUrl();
    if (uidFromUrl) {
      setTabid(uidFromUrl);
      setActiveTab('team');
    }

  }, [tabId]);
  return (
    <>
      <div style={{
        margin: '16px', background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #D0D0D0',
        opacity: '1', height: 'calc(100vh - 115px)'
      }} className='outerBox' >


        {/* <Navigation /> */}
        <div className='outerBox'>
          <div>
            <Navigation activeTab={activeTab} setActiveTab={changeTab} secActiveTab={secActiveTab} setSecActiveTab={setSecActiveTab}/>
          </div>
          <LeaveGridParent activeTab={activeTab} secActiveTab={secActiveTab} />
        </div>

      </div >
    </>
  )
}

export default OnleaveContainer