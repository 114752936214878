import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { SIGNIN_USER, SIGNOUT_USER } from "../constant/constant";
import {
  displayMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from "../actions/auth";
import {
  setMeetingMaster
} from "../actions/meetingmasteraction";

import {
  setTIBUserList
} from "../actions/tibacton";

import { FutureBridge, ERROR, errorConnectingBD } from "../../assets/constants/constants"
import { urlConfig, defMeetingCategory } from "../../config/config";
import { encrypt, encodeString, getNotify, decryptMethod, encryptMethod } from '../../app/other/commonfunction';

export const adLogout = (clientName) =>
  axios.post(`${urlConfig.enterpriseControlApiUrl}/logout`, { clientName },{ headers: { Authorization: localStorage.getItem('jwtToken') } }).then((res) => res.data);

//Function to login with email and password
const signInUserWithEmailAndPasswordRequest = async (user) =>
  axios
    .post(`${urlConfig.apiurl}authenticate`, user)
    .then((authUser) => authUser)
    .catch((error) => error);
//Function to to authenticate user when the user change the app.
const autoAuthenticationOnChangingApp = async (user, token) =>
  axios
    .post(`${urlConfig.apiurl}authenticate/app-change`, user, { headers: { Authorization: `Bearer ${token}` } })
    .then((authUser) => authUser)
    .catch((error) => error);

//Function for auto login when user click on software link
const autoLoginThroughSoftwareLink = async (user) =>
  axios
    .post(`${urlConfig.apiurl}authenticate/ad-authentication`, user)
    .then((authUser) => authUser)
    .catch((error) => error);

function* signInUserWithEmpCodePassword({ payload }) { // NOSONAR
  try {
    // eslint-disable-next-line
    let signInUserData;
    const authenticationReason = payload.authenticationReason;
    const token = payload.token
    delete payload.authenticationReason;
    delete payload.token;
    if (authenticationReason === 'app-change') {
      signInUserData = yield call(autoAuthenticationOnChangingApp, payload, token)
    } else if (authenticationReason === 'email-login') {
      const newPayLoad = { email: encryptMethod(payload.key), password: encryptMethod(payload.password) }
      signInUserData = yield call(
        signInUserWithEmailAndPasswordRequest,
        newPayLoad
      );
    }
    else {
      signInUserData = yield call(
        autoLoginThroughSoftwareLink,
        payload
      );
    }
    if (signInUserData.error) {
      yield put(displayMessage(signInUserData.error));
      if (authenticationReason !== 'email-login') {
        window.location.href = window.origin + '/invalidurl';
      }
    } else if (signInUserData.data.success) {      
      localStorage.clear();
      localStorage.setItem("jwtToken", `Bearer ${signInUserData.data.data.accessToken}`);      
      let encryptUserId = encrypt(Number(decryptMethod(signInUserData.data.userId)));
      localStorage.setItem("user_id", encryptUserId);
      let encryptBdUserId = encrypt(Number(decryptMethod(signInUserData.data.bdUserId)));
      localStorage.setItem("bd_user_id", encryptBdUserId);
      localStorage.setItem("user_nm", encodeString(`${decryptMethod(signInUserData.data.firstName)} ${decryptMethod(signInUserData.data.lastName)}`));
      localStorage.setItem("pnl", encodeString(Number(decryptMethod(signInUserData.data.pnl))));
      let encryptPnlId = encrypt(Number(decryptMethod(signInUserData.data.pnlId)));
      localStorage.setItem("pnlId", encryptPnlId);
      localStorage.setItem("def_timezone", encrypt(decryptMethod(signInUserData.data.timezone)));
      localStorage.setItem("user_type", encodeString(decryptMethod(signInUserData.data.usertype)));
      localStorage.setItem("meetingCategory", encodeString(defMeetingCategory));
      localStorage.setItem("ops_access", encrypt(decryptMethod(signInUserData.data.ops_access)));
      localStorage.setItem("bulkAttendeeAccess", encrypt(decryptMethod(signInUserData.data.bulkAttendeeAccess)));
      localStorage.setItem("notificationSettingAccess", encrypt(decryptMethod(signInUserData.data.notificationSettingAccess)));
      localStorage.setItem("telegramNotificationAccess", encrypt(decryptMethod(signInUserData.data.telegramNotificationAccess)));
      localStorage.setItem("downloadDocAccess", encrypt(decryptMethod(signInUserData.data.downloadDocAccess)));
      localStorage.setItem("emailAddress", encodeString(decryptMethod(signInUserData.data.emailAddress)));
      localStorage.setItem("digitalEmailAddress", encodeString(decryptMethod(signInUserData.data.digitalEmailAddress)));
      localStorage.setItem("authUserDigitalEmailAddress", encodeString(decryptMethod(signInUserData.data.digitalEmailAddress)));
      localStorage.setItem("editEmployeeAccess", encrypt(decryptMethod(signInUserData.data.editEmployeeAccess)));
      localStorage.setItem("helpdeskUserName", encodeString(decryptMethod(signInUserData.data.helpdeskUserName)));
      localStorage.setItem("helpdeskUserPass", encodeString(decryptMethod(signInUserData.data.helpdeskUserPass)));
      localStorage.setItem("helpdeskUserId", encrypt(Number(decryptMethod(signInUserData.data.helpdeskUserId))));     

      const defActualDays1 = () => {
        let noOfDays;        
        if (Number(decryptMethod(signInUserData.data.pnlId ?? '')) === FutureBridge && decryptMethod(signInUserData.data.usertype) === 'Ops') {
          const currentYear = new Date().getFullYear();
          const previousYear = currentYear - 1;
          const noOfDays1 = (new Date().getTime() - new Date(`${previousYear}-04-01`).getTime())
          noOfDays = Math.floor(noOfDays1 / (1000 * 60 * 60 * 24))
        } else {
          const weekEndDay = (new Date().getDay() === 7 ? 6 : 7);
          noOfDays = (new Date().getDay() === 6 ? 5 : weekEndDay);
        }
        return noOfDays
      }
      const defActualDays = defActualDays1()
      localStorage.setItem("defActualDays", encrypt(defActualDays));
      if (typeof decryptMethod(signInUserData.data.bdConnectivity) !== 'undefined' && decryptMethod(signInUserData.data.bdConnectivity) === "N")
        setTimeout(() => {
          getNotify(ERROR, errorConnectingBD);
        }, 5000)
      yield put(userSignInSuccess(Number(decryptMethod(signInUserData.data.userId))));
    } else {
      yield put(displayMessage(signInUserData.data.error));
      if (authenticationReason !== 'email-login') {
        window.location.href = window.origin + '/invalidurl';
      } else {
        getNotify(ERROR, 'Invalid email and password')
      }
    }
  } catch (error) {
    yield put(displayMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("user_id");
    localStorage.removeItem("bd_user_id");
    localStorage.removeItem("user_nm");
    localStorage.removeItem("pnl");
    localStorage.removeItem("pnlId");
    localStorage.removeItem("def_timezone");
    localStorage.removeItem("meetingCategory");
    localStorage.removeItem("user_type");
    localStorage.removeItem("ops_access");
    localStorage.removeItem("appToken");
    localStorage.removeItem("downloadDocAccess");
    localStorage.clear();

    yield put(setMeetingMaster({ meetingType: [] }));
    yield put(setTIBUserList([]));

    // eslint-disable-next-line
    yield put(userSignOutSuccess(signOutUser));

  } catch (error) {
    yield put(displayMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmpCodePassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}


