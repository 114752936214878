import * as React from 'react';
import { Component } from "react"; // NOSONAR
import NotificationContainer from "../../../components/notification/notifycontainer";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { Query } from "@syncfusion/ej2-data";
import { Button } from '@material-ui/core';
import Progressbar from '../../../components/progressbar/progressbar';
import UTRReport from '../../../components/utilizationreport/utrreport';
import reportService from "../../../services/reportsevices";
import ErrorMessage from '../../../components/errormessage/errormessage'
// NOSONAR import './utilization.css';
import { getNotify } from '../../other/commonfunction';
import { ECReportWarning, WARNING, ECReportMsg, monthSelect, ECReportWarningMaxUser, viewByTypeSelect, dataFields, UNSELECT_PRACTICE_AREA, UTRReportWarningMaxUser } from '../../../assets/constants/constants';
import { MultiSelectComponent, CheckBoxSelection, Inject, DropDownTreeComponent, ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { utilizationReportUpdate } from '../../../redux/actions/tibacton';


const SAMPLECSS = `
    #multigroupchkUser .e-outline.e-input-group .e-clear-icon,
    #multigroupchkUser .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
        font-size: 16px;
        padding: 0px !important;
        padding-bottom: 12px;

    }
    .e-ddt .e-overflow.e-show-text {
        padding: 10px 3px 0 12px !important;
    }
    #multigroupchkUser .e-ddt.e-input-group.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon,
    #multigroupchkUser .e-ddt.e-input-group.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon,
    #multigroupchkUser .e-ddt.e-float-input.e-control-wrapper.e-show-chip.e-show-dd-icon .e-clear-icon,
    #multigroupchkUser .e-ddt.e-float-input.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
        right: 31px;
        top: 4px;
    }
    `;
class UTRContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUserMonthsIds: [],
            selectedViewByDataIds: 1,
            selectedDataField: 3,
            changedData: false,
            userList: [],
            userIds: [],
            viewByData: [{
                Name: "Daily",
                Id: 1
            }, {
                Name: "Weekly",
                Id: 2
            }, {
                Name: "Monthly",
                Id: 3
            }],
            dataFeilds: [{
                Name: "Time Availability",
                Id: 1
            }, {
                Name: "Planning Analytics",
                Id: 2
            }, {
                Name: "All",
                Id: 3
            }],
            showLoader: true,
            selectedMonthsSingle: 0,
            isLoading: false,
            counter: 0
        };
        this.selectedSdUserList = [];
        this.showCheckBox = true;
        this.getValues = this.getValues.bind(this);
        this.getReportData = this.getReportData.bind(this);
        this.getSelectedMonths = this.getSelectedMonths.bind(this)
        this.removeMonths = this.removeMonths.bind(this)
        this.UserSelected = false
        this.maxUserSelection = 5
        this.setCounter = this.setCounter.bind(this)
    }

    setCounter = (val) => {
        this.setState((prevState) => ({ counter: prevState.counter + val }), () => {
            const { changedData } = this.state
            // console.log("counter", this.state.counter, "utilizationReportId", this.props.utilizationReportId);
            // console.log("this.state.counter", this.state.counter)
            if (this.state.counter === 0) {
                this.setState({ isLoading: false, changedData: !changedData })
            } else {
                this.setState({ isLoading: true, changedData: !changedData })
            }
        }
        )
    }

    componentDidMount = async () => {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        // setTimeout(()=>this.refreshUserElement("selectuser"),1000)
        try {
            const { authUser, utilisationMonths } = this.props;
            const resposnse = reportService.getReportMasterData(authUser, 'Utilization')
            resposnse.then((data) => {
                if (typeof data !== 'undefined' && data !== null) {
                    const { changedData } = this.state
                    let selectedTIBMonthsId;
                    if (utilisationMonths.length > 0) {
                        selectedTIBMonthsId = utilisationMonths[0].currentMonth
                    }
                    // setTimeout(() => this.created("multigroupchkUser", "selectuser"), 700)
                    setTimeout(() => {
                        this.setState({ userList: data, changedData: !changedData, showLoader: false }, () => this.refreshUserElement("selectuser"));

                    }, 900)
                }
            })
            this.selectCurrentMonth();
        } catch (error) {
        }
    }

    componentWillUnmount() {
        try {
            this.props.utilizationReportUpdate({ utilizationReportUsersList: [], utilizationReportId: 0, utilizationViewByUpdated: "", utilizationViewByData: 2, utilisationSelectedDataField: 3 })
        } catch (error) {
        }
    }

    /**
    * Calculate & Update state of new dimensions
    */
    updateDimensions() {
        let updateHeight =
            (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) - 130;
        if (document.querySelector(".usrsel") !== null) {
            updateHeight -= document.querySelector(".usrsel").offsetHeight;
        }
        // eslint-disable-next-line
        this.setState({ WindowHeight: updateHeight, changedData: !this.state.changedData });
    }

    /**
    * handleFilteringByName
    * this function is used for to search data in select user dropdown.
    */
    handleFilteringByName = (e) => { // NOSONAR
        const newData = [];
        // eslint-disable-next-line
        this.state.userList.forEach((eachD) => {
            newData.push(eachD);
        });
        if (newData != null && newData.length > 0) {
            let query = new Query();
            query =
                e.text !== ""
                    ? query.where("user_name", "contains", e.text, true)
                    : query;
            e.updateData(newData, query);
        }
    };
    /**
    * shouldComponentUpdate
    * @param {*} nextProps
    * @param {*} nextState
    * This funcion is manage the rerender of this component using change data state.
    */
    shouldComponentUpdate(_nextProps, nextState) {
        const { changedData } = this.state;
        return changedData !== nextState.changedData;
    }
    getValues(e) {
        if (!this.UserSelected) {
            document.getElementById("select-sd-user").innerHTML = "";
            if (e.itemData.isChecked === 'true') {
                let eachEle = this.state.userList.find(x => x.id === parseInt(e.itemData.id, 10));
                if (typeof eachEle.fk_user_id === 'undefined' && eachEle.sd_id === 0) {
                    getNotify(WARNING, ECReportWarning)
                } else
                    this.selectedSdUserList.push(eachEle);
            }
            else if (e.itemData.isChecked === 'false') {
                this.selectedSdUserList = this.selectedSdUserList.filter(function (obj) {
                    return obj.id !== parseInt(e.itemData.id, 10);
                });

            }
            if (this.selectedSdUserList.length > this.maxUserSelection) {
                document.getElementById("select-sd-user").innerHTML = UTRReportWarningMaxUser;
            }
        }
    }
    getReportData() { // NOSONAR
        const { userList, changedData, selectedUserMonthsIds, selectedMonthsSingle, selectedViewByDataIds, } = this.state
        // this.state.showLoader = true
        this.selectedSdUserList = [...new Set(this.selectedSdUserList)]
        if (this.selectedSdUserList.length === 0 || selectedViewByDataIds === '' || selectedMonthsSingle === 0 || selectedMonthsSingle === "" || (selectedViewByDataIds == 3 && selectedUserMonthsIds.length === 0) || selectedMonthsSingle === undefined) {
            if (selectedViewByDataIds == 3 && selectedUserMonthsIds.length === 0) {
                document.getElementById("select-month").innerHTML = monthSelect;
            }
            if (selectedMonthsSingle === 0 || selectedMonthsSingle === "" || selectedMonthsSingle === undefined) {
                document.getElementById("select-month").innerHTML = monthSelect;
            }
            if (this.selectedSdUserList.length === 0) {
                document.getElementById("select-sd-user").innerHTML = ECReportMsg;
            }
            if (selectedViewByDataIds === '') {
                document.getElementById("select-viewby").innerHTML =
                    viewByTypeSelect;
            }
            // NOSONAR // if (selectedDataField === '') { 
            //     document.getElementById("select-data").innerHTML = 
            //         dataFields;
            // }
            return;
        }
        for (let obj of this.selectedSdUserList) {
            if (obj.hasOwnProperty('hasChild')) {
                document.getElementById("select-sd-user").innerHTML = UNSELECT_PRACTICE_AREA;
                return;
            }
        }
        if (this.selectedSdUserList.length > this.maxUserSelection) {
            document.getElementById("select-sd-user").innerHTML = UTRReportWarningMaxUser;
            return
        }
        // this.setState({ showLoader: true, changedData: !changedData });
        // remove duplicates
        let list = [];
        let i = 0;
        let monthData = (selectedViewByDataIds != 3) ? selectedMonthsSingle : selectedUserMonthsIds
        if (this.selectedSdUserList.length > 0 && selectedViewByDataIds > 0) {
            this.selectedSdUserList.forEach(eachItem => {
                let sdPAId, empId;
                if (typeof eachItem.fk_user_id === 'undefined') {
                    sdPAId = eachItem.sd_id
                } else {
                    sdPAId = userList.filter(x => (x.id === parseInt(eachItem.pid, 10) && x.hasChild === true))[0].sd_id
                }
                empId = typeof eachItem.fk_user_id === 'undefined' ? 0 : eachItem.fk_user_id
                let key = empId.toString() + sdPAId.toString() + monthData.toString().replace(/,/gi, '') + selectedViewByDataIds.toString()  // + selectedDataField.toString()
                i = i + 1;
                let listObj = {};
                listObj.empId = empId;
                listObj.sdPAId = sdPAId;
                listObj.monthYear = monthData.toString();
                listObj.key = key
                listObj.Id = i
                list.push(listObj);
            });
            this.props.utilizationReportUpdate({ utilizationReportUsersList: list, utilizationReportId: 0 })
            this.setState({ changedData: !changedData, showLoader: false });
        } else {
            this.props.utilizationReportUpdate({ utilizationReportUsersList: list, utilizationReportId: 0 })
            this.setState({ changedData: !changedData, showLoader: false });
        }
        setTimeout(() => {
            this.props.utilizationReportUpdate({ utilizationReportId: 1 })
        })
    }

    /**
    * getSelectedMonths
    * @param {*} e :event
    * this function is used for to select the element of remove user dropdown data.
    */
    getSelectedMonths = (e) => {
        document.getElementById("select-month").innerHTML = "";
        // eslint-disable-next-line
        let selectedMonthsIds = this.state.selectedUserMonthsIds;
        selectedMonthsIds.push(e.itemData.Id);
        this.setState({ selectedUserMonthsIds: selectedMonthsIds });
    };
    getSelectedViewBy = (e) => {
        if (e.itemData !== null) {
            this.props.utilizationReportUpdate({ utilizationViewByData: e.itemData.Id, })
            this.setState({ selectedViewByDataIds: e.itemData.Id, changedData: !this.state.changedData })
            if (this.state.selectedUserMonthsIds !== '') {
                document.getElementById("select-month").innerHTML = "";
            }
        } else {
            this.setState({ selectedViewByDataIds: "" })
            document.getElementById("select-viewby").innerHTML = viewByTypeSelect;
        }
        document.getElementById("select-viewby").innerHTML = "";

    };
    getSelectedDataFeilds = (e) => { // NOSONAR
        if (e.itemData !== null) {
            this.props.utilizationReportUpdate({ utilisationSelectedDataField: e.itemData.Id, })
            this.setState({ selectedDataField: e.itemData.Id, changedData: !this.state.changedData })
        } else {
            this.setState({ selectedDataField: "" })
            document.getElementById("select-datafield").innerHTML = dataFields;
        }

        document.getElementById("select-datafield").innerHTML = "";

    };
    getSelectedMonthsSingle = (e) => {
        if (e.itemData !== null) {
            this.props.utilizationReportUpdate({ utilizationViewByUpdated: 'updated' })
            this.setState({ selectedMonthsSingle: e.itemData.Id });
        } else {
            this.setState({ selectedMonthsSingle: "" })
            document.getElementById("select-month").innerHTML = monthSelect;
        }

        document.getElementById("select-month").innerHTML = "";

    };

    /**
     * removeMonths
     * @param {*} e :event
     * this function is used for to select the element of month remove dropdown data.
     */
    removeMonths(e) {
        // eslint-disable-next-line
        let selectedUserMonthsIds = this.state.selectedUserMonthsIds;
        // eslint-disable-next-line
        selectedUserMonthsIds = selectedUserMonthsIds.filter(el => el !== e.itemData.Id
        );
        if (selectedUserMonthsIds.length === 0) {
            this.setState({ selectedUserMonthsIds });
            document.getElementById("select-month").innerHTML =
                monthSelect;
            return;
        }
        this.setState({ selectedUserMonthsIds });
    }

    change(e) {
        try {
            this.UserSelected = true
            document.getElementById("select-sd-user").innerHTML = "";
            this.selectedSdUserList = []
            e.value.forEach(item => {
                let eachEle = this.state.userList.find(x => x.id === parseInt(item, 10));
                if (typeof eachEle.fk_user_id === 'undefined' && eachEle.sd_id === 0) {
                    getNotify(WARNING, ECReportWarning)
                } else
                    this.selectedSdUserList.push(eachEle);
            })
            if (this.selectedSdUserList.length > this.maxUserSelection)
                document.getElementById("select-sd-user").innerHTML = UTRReportWarningMaxUser;
        } catch (error) {

        }
    }

    created(_pDivId, pComponentId) {
        try {
            setTimeout(() => {
                if (document.getElementById(pComponentId)) {
                    document.getElementById(pComponentId).ej2_instances[0].refresh()
                }
            }, 100)

        } catch (error) {
        }
    }

    refreshUserElement = (id) => {
        try {
            if (document.getElementById(id))
                document.getElementById(id).ej2_instances[0].showPopup()
            document.getElementById(id).ej2_instances[0].hidePopup()

        } catch (error) {
        }
    }
    selectCurrentMonth() {
        const monthsArray = this.props.utilisationMonths;
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonthName = `${currentDate.toLocaleString('default', { month: 'short' })}-${currentYear.toString().substring(2)}`;
        const currentMonthObject = monthsArray.find(obj => obj.Name === currentMonthName);
        this.setState({ selectedMonthsSingle: currentMonthObject.Id })
    };

    render() {
        const { showLoader, userList, selectedUserMonthsIds, isLoading } = this.state;
        const { utilizationReportUsersList } = this.props

        const { authUser } = this.props;
        let counter = 0;
        // console.log("isLoading", isLoading)
        return (
            <div className='col-lg-12 usrsel' style={{ width: "100%" }}>
                <NotificationContainer />
                <div className="row" style={{ padding: 5, margin: "10px 3px 5px", display: showLoader ? "none" : "flex", gap: "1rem", transform: "translate(-15px, 0)", width: "100%" }} >
                    <div
                        className="control-section tib-dropdown-padding"
                        style={{ width: 510 }}
                    >
                        <div id="multigroupchkUser" className="control-styles" >
                            <style>{SAMPLECSS}</style>
                            <DropDownTreeComponent
                                id="selectuser"
                                style={{ padding: 10 }}
                                fields={{ dataSource: userList, value: 'id', parentValue: 'pid', text: 'name', hasChildren: 'hasChild' }}
                                ref={(scope) => { this.ddtreeObj = scope; }} // NOSONAR
                                showCheckBox={this.showCheckBox}
                                select={(e) => { this.getValues(e) }}
                                change={this.change.bind(this)}
                                mode="Delimiter"
                                filterType="Contains"
                                allowFiltering={true}
                                placeholder="Select Team Member *"
                                popupHeight="300px"
                                cssClass="e-outline"
                                ignoreCase={true}
                                showClearButton={true}
                                allowMultiSelection={false}
                                maximumSelectionLength={1}
                                enabled={!isLoading}
                            // created={this.created("multigroupchkUser", "selectuser")}
                            />
                        </div>
                        <div id="select-sd-user" className="error-div pl-sm" />

                    </div>
                    <div style={{ width: window.innerWidth < 1380 ? 140 : 250 }}>
                        <div id="multigroup" className="control-styles">
                            <ComboBoxComponent
                                id="selectType"
                                fields={{ text: "Name", value: "Id" }}
                                data-msg-containerid="selectTypeErr"
                                name="selectTypeErr"
                                allowFiltering
                                allowCustom={false}
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                placeholder="View By *"
                                change={this.getSelectedViewBy}
                                dataSource={this.state.viewByData}
                                value={this.state.selectedViewByDataIds}
                                enabled={!isLoading}
                            // enabled={!showLoader}
                            />
                        </div>
                        <div id="select-viewby" className="error-div pl-sm" />
                    </div>
                    {this.state.selectedViewByDataIds != 3 ? <div style={{ width: 250, padding: "0 !important" }}>
                        <div id="multigroup" className="control-styles">
                            <ComboBoxComponent
                                id="selectType"
                                fields={{ text: "Name", value: "Id" }}
                                data-msg-containerid="selectTypeErr"
                                name="selectTypeErr"
                                allowFiltering
                                allowCustom={false}
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                placeholder="Select Month*"
                                change={this.getSelectedMonthsSingle}
                                dataSource={this.props.utilisationMonths}
                                value={this.state.selectedMonthsSingle}
                                enabled={!isLoading}
                            />
                        </div>
                        <div id="select-month" className="error-div pl-sm" />
                    </div> :
                        <div style={{ width: 310, padding: "0 !important" }}>
                            <div id="multigroupMonthUTR" className="control-styles">
                                <MultiSelectComponent
                                    id="monthcheckboxUTR"
                                    cssClass="e-outline"
                                    // eslint-disable-next-line
                                    dataSource={this.props.utilisationMonths}
                                    // eslint-disable-next-line
                                    value={selectedUserMonthsIds}
                                    fields={{ text: "Name", value: "Id" }}
                                    placeholder="Select Month"
                                    mode="CheckBox"
                                    showSelectAll={false}
                                    showDropDownIcon
                                    select={this.getSelectedMonths}
                                    removed={this.removeMonths}
                                    filterBarPlaceholder="Select Month*"
                                    popupHeight="350px"
                                    maximumSelectionLength={12}
                                    created={this.created("multigroupMonthUTR", "monthcheckboxUTR")}
                                    enabled={!isLoading}
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>
                            <div id="select-month" className="error-div pl-sm" />
                        </div>
                    }
                    {/* <div className="col-lg-1" style={{ width: 250 }}>
                        <div id="multigroup" className="control-styles">
                            <ComboBoxComponent
                                id="selectType"
                                fields={{ text: "Name", value: "Id" }}
                                data-msg-containerid="selectTypeErr"
                                name="selectTypeErr"
                                allowFiltering
                                allowCustom={false}
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                placeholder="Data Fields*"
                                change={this.getSelectedDataFeilds}
                                dataSource={this.state.dataFeilds}
                                value={this.state.selectedDataField}
                            />
                        </div>
                        <div id="select-datafield" className="error-div pl-sm" />
                    </div> */}
                    <div style={{ width: "100px !important" }}>
                        <div id="multigroup" className="control-styles" style={{ width: "100px !important" }}>
                            <Button
                                onClick={this.getReportData}
                                style={{ color: !isLoading ? "white" : "#c1baba", cursor: !isLoading ? "pointer" : "not-allowed" }}
                                disabled={isLoading}
                                color={isLoading ? "default" : "primary"}
                                variant="contained"
                                title="View Report">
                                View
                            </Button>

                        </div>
                    </div>

                    {/* <div >
                        <div style={{marginLeft:"auto"}}>
                            <p>M : Total Meetings - S : Self Meetings | O : Other Meetings</p>
                        </div>
                    </div> */}
                </div>
                <div id='utr-progress' style={{ zIndex: 10000, display: 'none', background: "white" }}> <Progressbar /></div>
                <div className='scrollbar control-section component-section'
                    style={{
                        height: this.state.WindowHeight,
                        marginLeft: 10,
                        marginRight: 10,
                        overflow: showLoader ? "hidden" : "auto",
                        width: "100%",
                        display: showLoader ? "none" : "block"
                    }}>
                    {(showLoader || isLoading) && <div id='utr-progress' style={{ zIndex: 10000, background: "white" }}> <Progressbar /> </div>}
                    {(!showLoader || isLoading) && <div>
                        {
                            utilizationReportUsersList !== undefined && //NOSONAR
                                utilizationReportUsersList.length > 0 ?
                                utilizationReportUsersList.map((item) =>
                                (
                                    (item.empId !== 0 || item.sdPAId !== 0) &&
                                    <div key={`divUtilization${item.key}`} id={`divUtilization${item.key} utr-report`} >
                                        <div style={{ "display": "none" }}>
                                            {
                                                counter = (counter + (document.getElementById(`divUtilization${item.key}`)) ? 0 : 1) // NOSONAR
                                            }
                                        </div>
                                        {
                                            <UTRReport
                                                reportKey={item.key}
                                                empId={item.empId}
                                                sdPAId={item.sdPAId}
                                                monthYear={item.monthYear}
                                                authUser={authUser}
                                                actualIndex={counter}
                                                Id={item.Id}
                                                setCounter={this.setCounter.bind(this)}
                                            />
                                        }
                                    </div>
                                )
                                )
                                :
                                <div className='row' style={{ width: '100%' }}>
                                    <ErrorMessage message={ECReportWarning} />
                                </div>
                        }
                    </div>}
                </div>
            </div>);
    }
}
const mapStateToProps = ({ auth, tibReducer }) => {
    const { authUser } = auth;
    const { tibMonts, utilisationMonths, selectedTIBMonthsId, utilizationReportUsersList, utrLoading, utilizationReportId } = tibReducer;

    return { authUser, tibMonts, utilisationMonths, selectedTIBMonthsId, utilizationReportUsersList, utrLoading, utilizationReportId };
};
export default connect(mapStateToProps, { utilizationReportUpdate })(UTRContainer);