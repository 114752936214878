import React, { Component } from 'react'
import { PivotViewComponent, FieldList, Inject, ConditionalFormatting, NumberFormatting, CalculatedField } from '@syncfusion/ej2-react-pivotview';
import reportService from "../../services/reportsevices";
import { Dialog, Button } from "@material-ui/core";
import { DialogContent, DialogActions, DialogTitle } from "../../components/customdialog/customdialog";
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { secToTime, getMonthYearFromDate, } from '../../app/other/commonfunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faToolbox, faUser, faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from '../progressbar/progressbar';
import { utilizationReportUpdate } from '../../redux/actions/tibacton';
import { connect } from "react-redux";
const SAMPLECSS =
    `
.e-grid .e-rowcell{
   
    font-size:12px !important;
    justify-content: center!important;
    align-self: center !important;
    text-align: center !important;
  }
  .e-pivotview .e-stackedheadertext.e-cellvalue{
    text-overflow: ellipsis !important;
    overflow: hidden;
  }
  .e-pivotview .e-stackedheadercelldiv, .e-pivotview .e-headertext, .e-pivotview .e-rowsheader .e-cellvalue{
    color:black !important;
  }
.e-grid .e-gridheader tr th {
    justify-content: center!important;
    align-self: center !important;
    text-align: center !important;
    padding: 0px 0px 0px 1px !important;
    font-weight: bold !important;
    color: black;
    padding-right: 0px !important;
  }
  
  .e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv{
      padding:0px 0px !important      
  }
  .e-grid .e-gridheader .e-headercontent .e-movableheader .e-table>thead>tr>th .e-headercell .e-headercelldiv{    
    text-align:center !important;
    
   }
  
   .e-headercelldiv {
    text-align: center !important;
    margin-bottom: -15px;
   }
   .e-stackedheadercelldiv{
       display:flex !important;
       align-items:center !important;
   }

   td.e-rowcell.e-rowsheader {
    text-align: left !important;
    color:black !important;
    }
    
    .custom-bold-text{
        font-weight:590 !important;
        background-color: rgb(236, 235, 233);
    }
    .custom-width-total-meeting{
        width:51px !important;
        border-left:0px !important;
    }
    .custom-width-self-meeting{
        width:51px !important;
        border-left:0px !important;
    }
    .custom-width-other-meeting{
        width:51px !important;
        border-left:0px !important;
    }
    
    .hide-self-other{
        display:none !important;
    }
    
    .e-pivotview .e-frozenheader > .e-table, .e-pivotview .e-frozencontent > .e-table{
        border-right-color:  #b7b2b2 !important;
        
    }   
    .e-grid .e-headercontent{
        background: white !important;
    } 
    .e-grid.e-default tr td:first-child, .e-grid.e-default tr th.e-headercell:first-child:not(.e-firstcell), .e-grid.e-default tr th.e-detailheadercell:first-child, .e-grid.e-default tr th.e-filterbarcell:first-child{
        background-color: #fafafa00 ;
    }
    .e-pivotview .e-grid .e-gridheader .e-headercontent .e-movableheader .e-table>thead>tr:first-child>th, .e-columnheader .e-headercell .e-stackedheadercell .e-columnsheader .e-movablefirst{
        background-color: #fff !important;
        height:30px !important;
    }
    .e-pivotview .e-grid .e-gridheader .e-headercontent .e-movableheader .e-table>thead>tr:nth-child(2)>th, .e-columnheader .e-headercell .e-stackedheadercell .e-columnsheader .e-movablefirst{
        background-color: #fff !important;
        height:30px !important;
    }
    .e-pivotview .e-grid .e-gridheader .e-headercontent .e-movableheader .e-table>thead>tr:nth-child(3)>th, .e-columnheader .e-headercell .e-stackedheadercell .e-columnsheader .e-movablefirst{
        border-color: none  !important;
        background-color: #fff !important;
    }
    .e-pivotview .e-grid .e-gridheader .e-headercontent .e-movableheader .e-table>thead>tr:nth-child(4)>th, .e-columnheader .e-headercell .e-stackedheadercell .e-columnsheader .e-movablefirst{
        background-color: #fff !important;
    }
    .e-cellvalue{
        font-size:${window.innerWidth < 1441 ? 11 : 12}px !important
    }
    .e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv{
        font-size:12px !important;
        // justify-content: center;
    }
    .today-color{
        border-right: 3px solid yellow !important;
        border-left: 3px solid red !important;
    }
    .availability-red{
        color:red;
    }
    .availability-green{
        color:green;
    }
    .center-align-date{
    display: flex;
    flex-direction: column;
    }
    .text-leave-color{
        color:blue !important;
    }
    .text-weekoff-color{
        color:#d9d9d9 !important;
    }
    .total-bg-color{
        background-color:#F2F2F2 !important
    }
    .total-final-color{
        justify-content: center !important;
    }
    .e-grid.e-responsive .e-rowcell,
    .e-grid.e-responsive .e-headercelldiv {

    }

    .main-color{
        background-color:#2f668b !important;
        color:white !important;
    }
    .sub1-color{
        background-color:#bae7ff !important;
        color:black !important;
        font-weight: 600 !important;
    }
    .sub2-color{
        background-color:#e6f7ff !important;
        color:black !important;
    }
    .date-bg-color{
        background:white !important;
    }
    .e-grid .e-gridheader tr:first-child th{
        background: white !important;
    }
    .number-space-text{
        padding-left: 6px;
    }
    .asper-bg-color {
        color: rgba(0, 0, 0, 0.4);
    }
    .hightlight-as-per{
        color: red;
    }
    .wrap-week-text{
        display:flex;
        flex-direction:column;
    }
    .wrap-week-big-text{
        display:flex;
        flex-direction:row;
    }
    .wrap-week-font{
        font-size:${window.innerWidth < 1441 ? 11 : 12}px;
        text-align: center;
        margin-top: 5px;
        margin-left: -6px;
        font-weight: 500;
    }
    .wrap-week-normal{
        font-size:${window.innerWidth < 1441 ? 11 : 12}px;
    }
    .wrap-week-font2{
        font-size: ${window.innerWidth < 1441 ? 11 : 12}px;
        margin-top: -4px;
        margin-left: -6px;
        font-weight: 500;
        text-align: center;
    }
    .planning-score-utr{
    /* color: white !Important; */
    font-weight: 500;
    margin-left: 9px;
    }
    .past-day-color{
        color: #d9d9d9 !important;
    }
    .text-pl-color{
        color:blue !important;
        font-weight: 500;
    }
    .text-wo-color{
        color: #d9d9d9 !important;
        font-weight: 500;
    }
    .text-absent-color{
        color:#fa6673!important
    }
    @media (resolution: 1.25dppx) {
        .scale::after {
          content: '125%';
        }
        
      }
      .e-pivotview .e-grid .e-icons {
        color: rgba(0, 0, 0, 0.54) !important;
        margin-top: 5px;
      }
    .e-grid .e-frozencontent {
        height: max-content !important;
    }
`
/**
 * ::-webkit-scrollbar {
        width: 3px;
      }
      

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px #e0e0e0; 
        border-radius: 10px;
      }
       

      ::-webkit-scrollbar-thumb {
        background: #e0e0e0; 
        border-radius: 10px;
      }
      

      ::-webkit-scrollbar-thumb:hover {
        background: #e0e0e0; 
      }
 * .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after
 */
let fieldCollections = {};
let filterCollections = {};
let isInitial = true;
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let dataSourceSettings = {
    valueSortSettings: { headerDelimiter: " - " },
    sortSettings: [{ name: 'monthType', order: 'None' }],
    dataSource: {},
    type: 'JSON',

    enableSorting: true,
    tooltipTemplate: "#template",
    formatSettings: [
        { name: "totalTime", useGrouping: true },
    ],
    expandAll: false,
    rows: [
        { name: 'mainCategory', caption: 'Main Category', showSubTotals: true },
        { name: 'meetingCategory', caption: 'Meeting Category', showSubTotals: true },
        { name: 'activityType', caption: 'Activity Type', showSubTotals: true },
        { name: 'subActivityType', caption: 'Sub Activity Type', showSubTotals: true },
        { name: 'subActivityType1', caption: 'Sub Activity Type1', showSubTotals: true },
        { name: 'subActivityType2', caption: 'BTB Id', showSubTotals: true },

    ],
    columns: [
        { name: 'monthType', caption: 'Month', order: 'None' },
        { name: 'weekType', caption: 'Week' },
        { name: 'meetingDate', caption: 'Date' }
    ],
    values: [
        { name: 'selfMeetingCount', caption: 'Self', type: 'Sum' },
        { name: 'otherMeetingCount', caption: 'Other', type: 'Sum' },
        { name: 'totalTime', caption: 'Time', type: 'Sum' },
    ],
    conditionalFormatSettings: [
        {
            measure: 'totalTime',
            value1: "00:00",
            conditions: 'Equals',
            style: {
                color: '#bfbfbf',
            }
        },
        {
            measure: 'totalTime',
            value1: "00:00",
            conditions: 'Equals',
            style: {
                color: '#bfbfbf',
            }
        }
    ],
    showGrandTotals: false,
    showRowGrandTotals: false,
    showColumnGrandTotals: true,
    showSubTotals: true,
    showHeaderWhenEmpty: false,
    enableVirtualization: true,
    showAggregationOnValueField: false
};

const getHeightAccordingToScreen = () => {
    if (window.innerHeight === 911 || document.documentElement.clientHeight === 911 || document.body.clientHeight === 911) {
        return parseInt((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 237);
    } else {
        return parseInt((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 245);
    }
}
class UTRReport extends Component {
    pivotObj;
    fieldCollections = {};
    isInitial = true;
    storeMembers = { 'mainCategory': [], 'monthType': [], 'weekType': [], 'meetingDate': [] }; //  , 
    isRowSelect = false;
    isColumnSelect = false;
    values = [];
    index;
    fieldsddl;
    membersOrder;
    field1;
    applyBtn;
    fields = [
        { Field: 'mainCategory', expandAll: false },
        { Field: 'monthType', expandAll: false },
        { Field: 'weekType', expandAll: false },
        { Field: 'meetingDate', expandAll: false },
    ];
    options = [
        { value: 'allHeaders', text: 'All headers' },
        { value: 'rowHeaders', text: 'Row headers' },
        { value: 'columnHeader', text: 'Column headers' },
        { value: 'specificFields', text: 'Specific fields' },
        { value: 'specificHeaders', text: 'Specific headers' }
    ];
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isLoading: false,
            height: getHeightAccordingToScreen(),
            width: (window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth) - 70,
            openDialog: false,
            cellWidth: 73
        }
        this.selfCount = 0
        this.otherCount = 0
        isInitial = true;
        this.indexsTibPerSch = {}
        this.indexsTibPerAct = {}
        this.indexsActVsSch = {}
        this.monthindexsTibPerSch = {}
        this.monthindexsTibPerAct = {}
        this.indexsMonthperAvailbleOld = {}
        this.monthindexsActVsSch = {}
        this.indexsperAvailbleOld = {}
        this.data = {}
        this.aggregateCellInfo = this.aggregateCellInfo.bind(this)
        this.getSelectedMembers = this.getSelectedMembers.bind(this)
        this.queryCellInfo = this.queryCellInfo.bind(this)
        this.LoadingStatus = "None"
        this.pivotObj = null
        this.excludeAvg = ['PL', 'WO', 'HO', 'FH']
        this.selectedDate = new Date(this.props.monthYear * 1000)
        this.selectedMonth = monthNames[this.selectedDate.getMonth()]
        this.selectedYear = this.selectedDate.getFullYear()
        this.todayDay = weekday[new Date().getDay()]
        this.todayMonth = monthNames[new Date().getMonth()]
        this.todayDateNum = new Date().getDate()
        this.todayDate = this.todayDateNum + '-' + this.todayMonth + '(' + this.todayDay + ')'  // "20-Jun (Mon)"
        this.multipleMonthsForView = this.props.monthYear.split(",").map((e) => getMonthYearFromDate(new Date(e * 1000)))
        this.monthYearForView = this.multipleMonthsForView[0]
        this.emptyMeetingCount = " (<span class='number-space-text'>0</span> M )"  // - <span class='number-space-text'>0</span> S | <span class='number-space-text'>0</span> O)"
        this.planningScoreValues = { actTib: { x: -4, y: 1.5 }, actSch: { x: 1, y: -1 }, tibSch: { x: 1, y: -2 } }
        this.style1440Res = this.props.utilizationViewByData === 1 && window.innerWidth < 1441 && `.e-headercontent>div>table{
            height:124px !important
        }`
        this.currentWeek = this.todayMonth + '-' + new Date().getFullYear().toString().slice(2) + " - Wk-" + (parseInt(Math.ceil((new Date().getDate() - 1 - new Date().getDay()) / 7)) + 1) // Jul-22 - Wk-5 
        this.currentWeekMonth = "Wk-" + (parseInt(Math.ceil((new Date().getDate() - 1 - new Date().getDay()) / 7)) + 1) // Jul-22 - Wk-5 
        this.allLeaveColors = {

        }

    }



    /** it is to add space to count of meetings */
    addSpacetoCount = (val) => {
        return (val < 10 ? `<span class='number-space-text'>${val}</span>` : val)
    }
    /**it is add classname to cell the leave or week off or today color */
    updateTextLeaveColor = (val) => {
        let classname = ''
        classname = this.allLeaveColors[val]
        if (val === this.todayDate || val === 'thismonth' || val === 'thisweek') {
            classname = 'text-today-div-border'
        }
        return classname
    }

    knowPastOrFuture = (date) => {
        const { utilizationViewByData } = this.props
        try {
            if (utilizationViewByData === 1 && !date.includes("Wk-") && date !== this.selectedMonth + "-" + this.selectedYear.toString().slice(2)) {
                const y = new Date(this.selectedYear + "-" + (monthNames.indexOf(date.split("-")[1].split("(")[0]) + 1) + "-" + date.split("-")[0]);
                const x = new Date();
                x.setDate(x.getDate() - 1)
                return x > y

            } else if (utilizationViewByData === 3 || (utilizationViewByData === 2 && !date.includes("Wk-")) || (utilizationViewByData === 1 && date === this.selectedMonth + "-" + this.selectedYear.toString().slice(2))) {
                let [month, year] = date.split("-")
                const y = new Date("20" + year + "-" + (monthNames.indexOf(month) + 1) + "-02");
                const x = new Date();
                x.setDate(1)
                return x > y
            } else if (utilizationViewByData === 2 || (utilizationViewByData === 1 && date.includes("Wk-"))) {
                if (date.includes("Wk-")) {
                    const startDate = date.split("(")[1].split("-")[0]
                    const Week = this.getWeekNumber(startDate)
                    let currentdate = new Date();
                    let oneJan = new Date(currentdate.getFullYear(), 0, 1);
                    let numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
                    const currentWeek = Math.ceil((numberOfDays) / 7);
                    return (Week < currentWeek && this.selectedYear === new Date().getFullYear()) || this.selectedYear < new Date().getFullYear()
                }
            }
        }
        catch (err) {
        }
        return false
    }

    getPercentAvailableClass = (val) => {
        let style = ''
        if (val <= 10) {
            style = 'availability-red'
        }
        else if (val >= 25) {
            style = 'availability-green'
        }
        return style
    }

    /**this is to modify aggregation of grouped data */
    aggregateCellInfo = (args) => { // NOSONAR
        const { reportKey, utilizationViewByData } = this.props
        try {
            document.getElementById('utr-progress').style.display = ''
            if (document.getElementById('PivotView' + reportKey)) document.getElementById('PivotView' + reportKey).style.opacity = 0.2

            setTimeout(() => {
                if (document.getElementById('PivotView' + reportKey)) document.getElementById('PivotView' + reportKey).style.opacity = 1
                document.getElementById('utr-progress').style.display = 'none'
            }
                , 600)
            if (args.fieldName === "selfMeetingCount") {
                if (typeof args.value !== 'undefined') {
                    this.selfCount = !isNaN(parseInt(args.value)) ? parseInt(args.value) : 0
                }
                else {
                    this.selfCount = 0
                }
            }
            if (args.fieldName === "otherMeetingCount") {
                if (typeof args.value !== 'undefined') {
                    this.otherCount = !isNaN(parseInt(args.value)) ? parseInt(args.value) : 0
                }
                else {
                    this.otherCount = 0
                }
            }
            if (args.fieldName === "totalTime") {
                let totalMeetingCount = this.selfCount + this.otherCount
                const meetingCount = " (" + this.addSpacetoCount(totalMeetingCount) + " M )"  // + this.addSpacetoCount(this.selfCount) + " S | " + this.addSpacetoCount(this.otherCount) + " O)"
                if (args.row.actualText.includes("% Available")) {
                    args.skipFormatting = true
                    let style = ''
                    if (this.knowPastOrFuture(args.column.actualText)) {
                        style = 'past-day-color'
                    } else {
                        style = this.getPercentAvailableClass(args.value)
                    }

                    args.value = `<span class=${style}>${args.value.toFixed().toString() + "%"}</span>`
                }
                else if (args.row.actualText.includes("Planning Score") || args.row.actualText.includes("Planning Rank")) {
                    args.skipFormatting = true
                    if (args.value > 10) {
                        args.value = null;
                    } else if (args.value <= 10 && args.column.formattedText.includes("Wk-")) {
                        args.value = null;
                    } else if (args.column.formattedText.includes("WO")) {
                        args.value = "-";
                    }
                    args.value = args.value === null ? " " : (args.value ? Math.ceil(args.value.toFixed(1)).toString() : '-');
                }
                else if (args.row.actualText.includes("as % of")) {
                    args.skipFormatting = true
                    if (args.cellSets.length <= 1 && utilizationViewByData === 1) {
                        let newValue = args.value ? parseFloat(args.value) * 100 : 0
                        let styleasPer = ''
                        if ((newValue > 150 || newValue < 50) && args.column.actualText.split("(")[1].includes(",Y)")) {
                            styleasPer = 'hightlight-as-per'
                        } else {
                            styleasPer = 'asper-bg-color'
                        }
                        args.value = args.value ? `<span class='${styleasPer}'>${newValue.toFixed().toString() + "%"}</span>` : `<span class='asper-bg-color'>0%</span>`
                    } else if (!String(args.value).includes("%")) {
                        args.value = `<span class='asper-bg-color'>0%</span>`
                    }
                }
                else if (args.row.actualText !== "B.1. Scheduled Hours in EC" && !args.row.actualText.includes("Hours Available to") && !args.row.actualText.includes("Planning Score")) {
                    let attendTye = ''
                    if (args.column.actualText.split("(").at(-1) && !args.column.actualText.includes(this.todayDate) && utilizationViewByData === 1) {
                        attendTye = args.column.actualText.split("(").at(-1).split(")")[0].split(",")[1]
                    } else if (args.column.actualText.includes(this.todayDate) && utilizationViewByData == 1) {
                        attendTye = this.todayDate
                    } else if (args.column.actualText === (this.todayMonth + "-" + new Date().getFullYear().toString().slice(2)) && utilizationViewByData == 3) {
                        attendTye = 'thismonth'
                    } else if (args.column.actualText.includes(this.currentWeekMonth) && utilizationViewByData == 2 && args.column.actualText.includes(this.todayMonth)) {
                        attendTye = 'thisweek'
                    }

                    const classNameLeave = this.updateTextLeaveColor(attendTye)
                    args.skipFormatting = true
                    const meetingCountValue = meetingCount !== this.emptyMeetingCount ? meetingCount : ''
                    args.value = `<span class='${classNameLeave}'  
                        ${args.row.actualText !== "A.1. Standard Hours Available (HH:MM)" ? `onclick='window["openUTRCountDialog"]("${secToTime(args.value)}",${this.selfCount},"${args.row.actualText}","${args.column.actualText}",${this.otherCount})'
                        style='cursor:pointer;'` : ""
                        }
                        >
                                 ${secToTime(args.value) + `<span style='color:rgb(140 134 134);'>${meetingCountValue}</span>`}
                        </span>`
                }
                else if (args.row.actualText.includes("Hours Available to")) {
                    args.skipFormatting = true
                    const meetingCountValue = meetingCount !== this.emptyMeetingCount ? meetingCount : ''
                    const timeInsec = parseInt(args.value)
                    let style = ''
                    if (this.knowPastOrFuture(args.column.actualText)) {
                        style = 'past-day-color'
                    }
                    else if (timeInsec === 0) {
                        style = 'availability-red'
                    }
                    else if (timeInsec >= 21600) {
                        style = 'availability-green'
                    }

                    args.value = `<span class=${style}>${timeInsec === 0 ? '00:00' : secToTime(args.value) + meetingCountValue}</span>` //   + meetingCountValue
                }
                if (args.row.actualText === "B.1. Scheduled Hours in EC" || args.row.actualText === "B.2. Actual Hours in EC" || args.row.actualText === 'C. Input Time in Business') {
                    let data = args.cellSets.filter((e) => e.mainCategory === args.row.actualText && typeof e.meetingCategory === 'undefined' &&
                        typeof e.activityType === 'undefined')
                    if (args.cellSets.length > 1) {
                        let totalSum = 0;
                        for (let eachArg of data) {
                            if (!this.excludeAvg.includes(eachArg.attendType) && typeof eachArg.totalTime !== 'undefined') {
                                totalSum += eachArg.totalTime
                            }
                        }
                        args.skipFormatting = true
                        args.value = String(secToTime(totalSum));
                    } else {
                        let totalCount = 0

                        for (let each of data) {
                            if (each.totalTime) {
                                totalCount = totalCount + each.totalTime
                            }
                        }
                        args.skipFormatting = true
                        args.value = String(secToTime(totalCount))
                    }
                }
            }
        } catch (error) {
        }
    }
    /**it converts hours to sec */
    hoursToSec = (time) => {
        return parseInt(time[0]) * 60 * 60 + parseInt(time[1]) * 60
    }
    /** it is to get planning score from scheduled,actual,tib hours */
    calCulatePlanningScore = (monthTotalSch, monthTotalAct, monthTotalTib) => {
        // const decimalScheduleVal = parseFloat(monthTotalSch / (60 * 60)).toFixed(2)
        // const decimalActualVal = parseFloat(monthTotalAct/(60*60)).toFixed(2)
        // const decimalTibVal = parseFloat(monthTotalTib/(60*60)).toFixed(2)

        // const diffScheduleTib = parseFloat(Math.abs(decimalTibVal - decimalScheduleVal)).toFixed(2)
        // const diffScheduleActual = parseFloat(Math.abs(decimalScheduleVal - decimalActualVal)).toFixed(2)
        // const diffActualTib = parseFloat(Math.abs(decimalTibVal - decimalActualVal)).toFixed(2)

        // const planningScore = parseFloat(Math.abs(100 - ((diffScheduleTib / decimalTibVal || 0 * 0.3) + (diffScheduleActual / decimalTibVal || 0 * 0.3) + (diffActualTib / decimalTibVal || 0 * 0.4)) * 100)).toFixed(2);

        return "-"
    }

    /**it is to refresh the pivot view component */
    refreshComponent = () => {
        try {

            setTimeout(() => {
                if (this.props) {
                    const { reportKey } = this.props
                    document.getElementById("PivotView" + reportKey).ej2_instances[0].refresh()

                }
            }, 300)
        } catch (err) {

        }
    }
    /**it will be called when pivot component loaded */
    enginePopulated(args) { // NOSONAR
        const { utilizationViewByData, reportKey } = this.props

        document.getElementById('utr-progress').style.display = ''
        if (args.dataSourceSettings && args["dataSourceSettings"]["drilledMembers"][0].items.length > 0) {
            this.setState({ cellWidth: 120 })
        } else {
            this.setState({ cellWidth: utilizationViewByData === 1 ? 70 : 60 })
        }
        document.getElementById("PivotView" + reportKey + "_grid").children[1].children[0].children[0].setAttribute("style", 'height:30px;')

        document.getElementById("PivotView" + reportKey + "_grid_content_table").setAttribute("style", 'width: 228px;') // id="PivotView441016592922001_grid_content_table" PivotView441016592922001_gridcolgroup
        document.getElementById("PivotView" + reportKey + "_gridcolgroup").parentElement.setAttribute("style", 'width: 228px;') // id="PivotView441016592922001_grid_content_table" PivotView441016592922001_gridcolgroup
        // }
        if (utilizationViewByData === 2) {
            if (args.dataSourceSettings && args["dataSourceSettings"]["drilledMembers"][1].items.length > 0) {
                this.setState({ leftHeight: 45 })
            } else {
                this.setState({ leftHeight: 35 })
            }
            if (!args.dataSourceSettings) {
                this.setState({ leftHeight: 45 })
            }
        }
        else if (utilizationViewByData === 3) {
            if (!args.dataSourceSettings) {
                this.setState({ leftHeight: 35 })
            }
        }
        setTimeout(() => this.onCreated(), 400)
        try {
            for (let eachMonth of this.multipleMonthsForView) { // NOSONAR
                for (let k = 1; k <= 6; k++) {
                    let weeklyTotalSch = 0;
                    let weeklyTotalTib = 0;
                    let weeklyTotalAct = 0;
                    let weeklyTotal = 0;
                    let weeklyTotalActVsSch = 0;
                    let weeklyTotalActVsTib = 0;
                    let monthTotalSch = 0;
                    let monthTotalTib = 0;
                    let monthTotalAct = 0;
                    let monthTotal = 0;
                    let monthTotalActVsSch = 0;
                    let monthTotalActVsTib = 0;
                    let weeklyStandardHours = 0;
                    let weeklyhoursAvailSch = 0;
                    let weeklyPercentAvailable = 0;
                    let monthStandardHours = 0;
                    let monthhoursAvailSch = 0;
                    let monthPercentAvailable = 0;
                    let weeklyPlanningScore = 0;
                    let monthlyPlanningScore = 0;
                    this.indexsTibPerSch = {}
                    this.indexsTibPerAct = {}
                    this.indexsActVsSch = {}
                    this.monthindexsTibPerSch = {}
                    this.monthindexsTibPerAct = {}
                    this.indexsMonthperAvailbleOld = {}
                    this.monthindexsActVsSch = {}
                    this.indexsperAvailbleOld = {}
                    this.weeklyPlanningScoreInd = {}
                    this.monthlyPlanningScoreInd = {}
                    for (var i = 3; i < args.pivotValues.length; i++) { // NOSONAR
                        for (var j = 0; args.pivotValues[i] != null && j < args.pivotValues[i].length; j++) { // NOSONAR
                            if (args.pivotValues[i][j] != null && args.pivotValues[i][j].axis == 'value' && args.pivotValues[i][j].actualText == "totalTime") {
                                if (args.pivotValues[i][j].columnHeaders.includes('Wk-' + k) && args.pivotValues[i][j].actualText == "totalTime" && args.pivotValues[i][j].columnHeaders.length < 36) {
                                    if (args.pivotValues[i][j].rowHeaders == 'A.1. Standard Hours Available (HH:MM)' && args.pivotValues[i][j].actualText == "totalTime" && args.pivotValues[i][j].columnHeaders.length < 36) {
                                        let newValue = args.pivotValues[i][j].value.split('>')[1].split('<')[0]
                                        if (newValue?.toString().includes(":")) {
                                            weeklyStandardHours = this.hoursToSec(newValue.toString().split(":"))
                                        }
                                    }
                                    if (args.pivotValues[i][j].rowHeaders == "A.2. Hours Available to Schedule (HH:MM)" && args.pivotValues[i][j].actualText == "totalTime" && args.pivotValues[i][j].columnHeaders.length < 36) {
                                        let newValue = args.pivotValues[i][j].value.split('>')[1].split('<')[0]
                                        if (newValue?.toString().includes(":")) {
                                            weeklyhoursAvailSch = this.hoursToSec(newValue.toString().split(":"))
                                        }
                                    }

                                    if (weeklyhoursAvailSch !== 0 && weeklyStandardHours !== 0) {
                                        weeklyPercentAvailable = `<span class=${this.getPercentAvailableClass(((weeklyhoursAvailSch / weeklyStandardHours) * 100).toFixed())}>${((weeklyhoursAvailSch / weeklyStandardHours) * 100).toFixed().toString() + "%"}</span>`
                                    }


                                    const perAvailbleOld = args.pivotValues[i].filter((e) => e.rowHeaders === "A.3. % Available" && e.actualText == "totalTime" && e.columnHeaders.includes('Wk-' + k) && e.columnHeaders.length < 36)
                                    if (perAvailbleOld.length > 0) {
                                        this.indexsperAvailbleOld[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: perAvailbleOld[perAvailbleOld.length - 1].rowIndex, j: perAvailbleOld[perAvailbleOld.length - 1].colIndex }
                                    }
                                    const newperAvailbleOld = this.indexsperAvailbleOld[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newperAvailbleOld && weeklyPercentAvailable !== 0) {
                                        args.pivotValues[newperAvailbleOld.i][newperAvailbleOld.j].formattedText = weeklyPercentAvailable
                                    }



                                    if (args.pivotValues[i][j].rowHeaders == 'B.1. Scheduled Hours in EC' && args.pivotValues[i][j].actualText == "totalTime" && args.pivotValues[i][j].columnHeaders.length < 36) {
                                        if (args.pivotValues[i][j].value?.toString().includes(":")) {
                                            weeklyTotalSch = this.hoursToSec(args.pivotValues[i][j].value.toString().split(":"))
                                        }
                                    }
                                    if (args.pivotValues[i][j].rowHeaders == 'B.2. Actual Hours in EC' && args.pivotValues[i][j].actualText == "totalTime" && args.pivotValues[i][j].columnHeaders.length < 36) {
                                        if (args.pivotValues[i][j].value?.toString().includes(":")) {
                                            weeklyTotalAct = this.hoursToSec(args.pivotValues[i][j].value.toString().split(":"))
                                        }
                                    }
                                    if (args.pivotValues[i][j].rowHeaders == 'C. Input Time in Business' && args.pivotValues[i][j].actualText == "totalTime" && args.pivotValues[i][j].columnHeaders.length < 36) {
                                        if (args.pivotValues[i][j].value?.toString().includes(":")) {
                                            weeklyTotalTib = this.hoursToSec(args.pivotValues[i][j].value.toString().split(":"))
                                        }
                                    }
                                    const tibVsSched = args.pivotValues[i].filter((e) => e.rowHeaders === 'B.1.1. TIB as % of Scheduled Hours' && e.actualText == "totalTime" && e.columnHeaders.includes('Wk-' + k) && e.columnHeaders.length < 36)
                                    if (tibVsSched.length > 0) {
                                        this.indexsTibPerSch[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: tibVsSched[tibVsSched.length - 1].rowIndex, j: tibVsSched[tibVsSched.length - 1].colIndex }
                                    }
                                    if (weeklyTotalSch !== 0 && weeklyTotalTib !== 0 && weeklyTotalAct !== 0) {
                                        weeklyTotal = `<span class='asper-bg-color'>${((weeklyTotalTib / weeklyTotalSch) * 100).toFixed().toString() + "%"}</span>`
                                        weeklyTotalActVsTib = `<span class='asper-bg-color'>${((weeklyTotalTib / weeklyTotalAct) * 100).toFixed().toString() + "%"}</span>`
                                        weeklyTotalActVsSch = `<span class='asper-bg-color'>${((weeklyTotalAct / weeklyTotalSch) * 100).toFixed().toString() + "%"}</span>`
                                        weeklyPlanningScore = this.calCulatePlanningScore(weeklyTotalSch, weeklyTotalAct, weeklyTotalTib)
                                    }
                                    else {
                                        weeklyTotal = `<span class='asper-bg-color'></span>`
                                        weeklyTotalActVsTib = `<span class='asper-bg-color'></span>`
                                        weeklyTotalActVsSch = `<span class='asper-bg-color'></span>`
                                        weeklyPlanningScore = ''
                                    }




                                    const newi = this.indexsTibPerSch[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newi && weeklyTotal !== 0) {
                                        args.pivotValues[newi.i][newi.j].formattedText = weeklyTotal
                                    }

                                    const actVsSch = args.pivotValues[i].filter((e) => e.rowHeaders === "B.2.2. Actual Hours as % of Scheduled Hours" && e.actualText == "totalTime" && e.columnHeaders.includes('Wk-' + k) && e.columnHeaders.length < 36)
                                    if (actVsSch.length > 0) {
                                        this.indexsActVsSch[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: actVsSch[actVsSch.length - 1].rowIndex, j: actVsSch[actVsSch.length - 1].colIndex }
                                    }

                                    const newiactVsSch = this.indexsActVsSch[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newi && weeklyTotal !== 0) {
                                        args.pivotValues[newiactVsSch.i][newiactVsSch.j].formattedText = weeklyTotalActVsSch
                                    }

                                    const actVsTib = args.pivotValues[i].filter((e) => e.rowHeaders === "B.2.1. TIB as % of Actual Hours" && e.actualText == "totalTime" && e.columnHeaders.includes('Wk-' + k) && e.columnHeaders.length < 36)
                                    if (actVsTib.length > 0) {
                                        this.indexsTibPerAct[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: actVsTib[actVsTib.length - 1].rowIndex, j: actVsTib[actVsTib.length - 1].colIndex }
                                    }

                                    const newiactVsTib = this.indexsTibPerAct[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newiactVsTib && weeklyTotal !== 0) {
                                        args.pivotValues[newiactVsTib.i][newiactVsTib.j].formattedText = weeklyTotalActVsTib
                                    }
                                    const weeklyPlanningScoreIndexOld = args.pivotValues[i].filter((e) => e.rowHeaders === "D. Planning Score" && e.actualText == "totalTime" && e.columnHeaders.includes('Wk-' + k))
                                    if (weeklyPlanningScoreIndexOld.length > 0) {
                                        this.weeklyPlanningScoreInd[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: weeklyPlanningScoreIndexOld[weeklyPlanningScoreIndexOld.length - 1].rowIndex, j: weeklyPlanningScoreIndexOld[weeklyPlanningScoreIndexOld.length - 1].colIndex }
                                    }

                                    const weeklyPlanningScoreIndexOldNewVal = this.weeklyPlanningScoreInd[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (weeklyPlanningScoreIndexOldNewVal && weeklyTotal !== 0) {
                                        args.pivotValues[weeklyPlanningScoreIndexOldNewVal.i][weeklyPlanningScoreIndexOldNewVal.j].formattedText = weeklyPlanningScore
                                    }
                                }
                                if (args.pivotValues[i][j].columnHeaders == eachMonth) {
                                    if (args.pivotValues[i][j].rowHeaders == 'A.1. Standard Hours Available (HH:MM)' && args.pivotValues[i][j].actualText == "totalTime") {
                                        let newValue = args.pivotValues[i][j].value.split('>')[1].split('<')[0]
                                        if (newValue?.toString().includes(":")) {
                                            monthStandardHours = this.hoursToSec(newValue.toString().split(":"))
                                        }
                                    }
                                    if (args.pivotValues[i][j].rowHeaders == "A.2. Hours Available to Schedule (HH:MM)" && args.pivotValues[i][j].actualText == "totalTime") {
                                        let newValue = args.pivotValues[i][j].value.split('>')[1].split('<')[0]
                                        if (newValue?.toString().includes(":")) {
                                            monthhoursAvailSch = this.hoursToSec(newValue.toString().split(":"))
                                        }
                                    }

                                    const perMonthAvailbleOld = args.pivotValues[i].filter((e) => e.rowHeaders === "A.3. % Available" && e.actualText == "totalTime" && e.columnHeaders === eachMonth)
                                    if (perMonthAvailbleOld.length > 0) {
                                        this.indexsMonthperAvailbleOld[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: perMonthAvailbleOld[perMonthAvailbleOld.length - 1].rowIndex, j: perMonthAvailbleOld[perMonthAvailbleOld.length - 1].colIndex }
                                    }
                                    if (monthhoursAvailSch !== 0 && monthStandardHours !== 0) {

                                        monthPercentAvailable = `<span class=${this.getPercentAvailableClass(((monthhoursAvailSch / monthStandardHours) * 100).toFixed())}>${((monthhoursAvailSch / monthStandardHours) * 100).toFixed().toString() + "%"}</span>`
                                    }

                                    const newperAvailbleOld = this.indexsMonthperAvailbleOld[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newperAvailbleOld && monthPercentAvailable !== 0) {
                                        args.pivotValues[newperAvailbleOld.i][newperAvailbleOld.j].formattedText = monthPercentAvailable
                                    }

                                    if (args.pivotValues[i][j].rowHeaders == 'B.1. Scheduled Hours in EC' && args.pivotValues[i][j].actualText == "totalTime") {
                                        if (args.pivotValues[i][j].value?.toString().includes(":")) {
                                            monthTotalSch = this.hoursToSec(args.pivotValues[i][j].value.toString().split(":"))
                                        }
                                    }
                                    if (args.pivotValues[i][j].rowHeaders == 'B.2. Actual Hours in EC' && args.pivotValues[i][j].actualText == "totalTime") {
                                        if (args.pivotValues[i][j].value?.toString().includes(":")) {
                                            monthTotalAct = this.hoursToSec(args.pivotValues[i][j].value.toString().split(":"))
                                        }
                                    }
                                    if (args.pivotValues[i][j].rowHeaders == 'C. Input Time in Business' && args.pivotValues[i][j].actualText == "totalTime") {
                                        if (args.pivotValues[i][j].value?.toString().includes(":")) {
                                            monthTotalTib = this.hoursToSec(args.pivotValues[i][j].value.toString().split(":"))
                                        }
                                    }
                                    const tibVsSched = args.pivotValues[i].filter((e) => e.rowHeaders === 'B.1.1. TIB as % of Scheduled Hours' && e.actualText == "totalTime" && e.columnHeaders === eachMonth)
                                    if (tibVsSched.length > 0) {
                                        this.monthindexsTibPerSch[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: tibVsSched[tibVsSched.length - 1].rowIndex, j: tibVsSched[tibVsSched.length - 1].colIndex }
                                    }
                                    if (monthTotalSch !== 0 && monthTotalTib !== 0 && monthTotalAct !== 0) {
                                        monthTotal = `<span class='asper-bg-color'>${((monthTotalTib / monthTotalSch) * 100).toFixed().toString() + "%"}</span>`
                                        monthTotalActVsTib = `<span class='asper-bg-color'>${((monthTotalTib / monthTotalAct) * 100).toFixed().toString() + "%"}</span>`
                                        monthTotalActVsSch = `<span class='asper-bg-color'>${((monthTotalAct / monthTotalSch) * 100).toFixed().toString() + "%"}</span>`

                                        monthlyPlanningScore = this.calCulatePlanningScore(monthTotalSch, monthTotalAct, monthTotalTib)
                                    }
                                    const newi = this.monthindexsTibPerSch[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newi && monthTotal !== 0) {
                                        args.pivotValues[newi.i][newi.j].formattedText = monthTotal
                                    }

                                    const actVsSch = args.pivotValues[i].filter((e) => e.rowHeaders === "B.2.2. Actual Hours as % of Scheduled Hours" && e.actualText == "totalTime" && e.columnHeaders === eachMonth)
                                    if (actVsSch.length > 0) {
                                        this.monthindexsActVsSch[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: actVsSch[actVsSch.length - 1].rowIndex, j: actVsSch[actVsSch.length - 1].colIndex }
                                    }

                                    const newiactVsSch = this.monthindexsActVsSch[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newi && monthTotal !== 0) {
                                        args.pivotValues[newiactVsSch.i][newiactVsSch.j].formattedText = monthTotalActVsSch
                                    }

                                    const actVsTib = args.pivotValues[i].filter((e) => e.rowHeaders === "B.2.1. TIB as % of Actual Hours" && e.actualText == "totalTime" && e.columnHeaders === eachMonth)
                                    if (actVsTib.length > 0) {
                                        this.monthindexsTibPerAct[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: actVsTib[actVsTib.length - 1].rowIndex, j: actVsTib[actVsTib.length - 1].colIndex }
                                    }

                                    const newiactVsTib = this.monthindexsTibPerAct[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (newi && monthTotal !== 0 && args.pivotValues[i][j].columnHeaders.split("(")[0] === eachMonth) {
                                        args.pivotValues[newiactVsTib.i][newiactVsTib.j].formattedText = monthTotalActVsTib
                                    }

                                    const monthlyPlanningScoreIndexOld = args.pivotValues[i].filter((e) => e.rowHeaders === "D. Planning Score" && e.actualText == "totalTime" && e.columnHeaders === eachMonth)
                                    if (monthlyPlanningScoreIndexOld.length > 0) {
                                        this.monthlyPlanningScoreInd[args.pivotValues[i][j].columnHeaders.split("(")[0]] = { i: monthlyPlanningScoreIndexOld[monthlyPlanningScoreIndexOld.length - 1].rowIndex, j: monthlyPlanningScoreIndexOld[monthlyPlanningScoreIndexOld.length - 1].colIndex }
                                    }

                                    const MonthlyPlanningScoreIndexOldNewVal = this.monthlyPlanningScoreInd[args.pivotValues[i][j].columnHeaders.split("(")[0]]
                                    if (MonthlyPlanningScoreIndexOldNewVal && monthTotal !== 0) {
                                        args.pivotValues[MonthlyPlanningScoreIndexOldNewVal.i][MonthlyPlanningScoreIndexOldNewVal.j].formattedText = monthlyPlanningScore
                                    }


                                }
                            }
                        }
                    }
                }
            }


        } catch (err) {
        }
    }
    /**it is to get week number by using date */
    getWeekNumber = (date) => {
        let currentDate = new Date(this.selectedYear + '-' + this.selectedMonth + '-' + date);
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));
        return Math.ceil(days / 7);
    }

    queryCellInfo(args) { // NOSONAR
        try {
            const { utilizationViewByData } = this.props
            const colIndex1 = args.cell.getAttribute('aria-label')
            const colIndex = Number(args.cell.getAttribute('data-colindex'));

            if ((colIndex1.includes("column header Self") || colIndex1.includes("column header Other"))) {
                args.cell.classList.add('hide-self-other')
            }

            else if (((!colIndex1.includes("A.") && !colIndex1.includes("B.") && !colIndex1.includes("C.") && !colIndex1.includes(":") && !colIndex1.includes("%") && !colIndex1.includes("Planning") && !colIndex1.includes("Time") && !colIndex1 !== '') || colIndex1.includes("M -") || colIndex1.includes("BTB"))) {
                args.cell.setAttribute("style", "font-size:11px !important;") // color:#5e5b5b !important;

            }
            const { cell, data } = args;

            if (data && (data[0].actualText.includes("A. ") || data[0].actualText.includes("B. "))) {

                if (colIndex == 0) {
                    cell.innerHTML = `<span class="e-cellvalue" style="font-size:${window.innerWidth < 1441 ? 12 : 13}px!important;font-weight:bold !important;color:black !important;">${data[0].actualText.includes("A. ") ?
                        "Time Availability" : "Planning Analytics"}</span>`
                    cell.setAttribute("style", "font-weight:bold");
                } else {
                    cell.setAttribute("style", "text-align:center; color:black !important;background-color:#fff;font-size:13px !important;color:white !important;");
                }
            }
            if (data[0].actualText.includes("D. Planning Score")) {
                if (colIndex == 0) {
                    cell.innerHTML = `<span class="e-cellvalue" style="font-weight:bold;color:white !important;">D. Planning Score</span>`
                    cell.setAttribute("style", "font-weight:bold");
                } else {
                    cell.setAttribute("style", "text-align:center; color:black !important;background-color:#3f43ad!important;color:white !important;");
                }
            }


            if (data[0].actualText.includes("A.4.") || data[0].actualText.includes("C.1.")) {
                cell.innerHTML = ""
                cell.setAttribute("style", "background-color:#fff;");
            }
            if (data[0].actualText.includes(".")) {
                const digits = data[0].actualText.toString().split(".")
                if (digits.length && Number(digits[0])) {
                    return
                }
                if (!(data[0].actualText.includes("A1.") || data[0].actualText.includes("A2.") || data[0].actualText.includes("A3.") || data[0].actualText.includes("A4.") || data[0].actualText.includes("A5.") ||
                    data[0].actualText.includes("B1.") || data[0].actualText.includes("B2.") || data[0].actualText.includes("B3.") || data[0].actualText.includes("B4.") || data[0].actualText.includes("B5."))) {
                    if (colIndex == 0) {
                        let actualText = data[0].actualText.toString()
                        actualText = actualText.substring(actualText.lastIndexOf(".") + 2, actualText.length)
                        if (actualText.includes("TIB as %") || actualText.includes("Actual Hours as %")) {
                            cell.innerHTML = `<span class="e-cellvalue" style="font-size:11px !important; margin-left: 37px !important;color:rgba(0, 0, 0, 0.6) !important; ">${actualText}</span>`
                        }

                        else if (actualText.includes("Scheduled Hours in EC") || actualText.includes("Actual Hours in EC")) {
                            /**
                             * ``
                             */
                            // cell.children[0].innerHTML = `<span class="e-cellvalue" style='margin-left: -4px !important; color:black !important ;font-weight:bold !important;'>${actualText === "Scheduled Hours in EC" ? "A. Scheduled Hours in EC" : "B. Actual Hours in EC"}</span>`
                            cell.children[1].innerHTML = `<span class="e-cellvalue" style='margin-left: -4px !important; color:black !important ;font-weight:bold !important;'>${actualText === "Scheduled Hours in EC" ? "A. Scheduled Hours in EC" : "B. Actual Hours in EC"}</span>`
                            // console.log("🚀 ~ file: utrreport.jsx:951 ~ data[0].actualText.includes ~ cell.children[1]:", cell.children)
                        }
                        else if (!actualText.includes("Capacity Availability") && !actualText.includes("Planning Analytics") && !actualText.includes("Input Time in Business") && !actualText.includes("% Available") && !(actualText.includes("Scheduled Hours in EC") || actualText.includes("Actual Hours in EC"))) {
                            cell.innerHTML = `<span class="e-cellvalue">${actualText}</span>`
                        }
                        else if (actualText.includes("Input Time in Business")) {
                            cell.innerHTML = `<span class="e-cellvalue" style="font-weight:bold !important;">${"C. " + actualText}</span>`
                        }
                        else if (actualText.includes("% Available")) {
                            cell.innerHTML = `<span style="font-size:11px !important;font-weight:normal  !important;margin-left: 8px;">${actualText}</span>`
                        }
                        else if (actualText.includes("Planning Score")) {
                            cell.innerHTML = `<span style="font-size:11px !important;font-weight:normal  !important;color:white !important;">D. ${actualText}</span>`
                        }
                    }
                }
                if ((data[0].actualText.includes("A1.") || data[0].actualText.includes("A2.") || data[0].actualText.includes("A3.") || data[0].actualText.includes("A4.") || data[0].actualText.includes("A5.") || data[0].actualText.includes("B1.") || data[0].actualText.includes("B2.") || data[0].actualText.includes("B3.") || data[0].actualText.includes("B4.") || data[0].actualText.includes("B5.") || data[0].actualText.includes("as % of"))) {
                    cell.classList.add("sub2-color")
                } else if (data[0].actualText.includes("Planning Score")) {
                    cell.setAttribute("style", "text-align:center; color:black !important;background-color:#3f43ad!important;color:white !important;");
                }
                else if (data[0].actualText.includes("Scheduled Hours in EC") || data[0].actualText.includes("Actual Hours in EC") || data[0].actualText.includes("Input Time in Business")) {
                    cell.classList.add("sub1-color")
                    if (data[0].actualText.includes("Input Time in Business")) {
                        if (cell.children[0].innerText.includes("Input Time in Business")) {
                            cell.children[0].style.marginLeft = '31px'
                        }
                    }
                }
                else if (data[0].actualText.includes("Structured")) {
                    cell.classList.add("sub1-color")
                }

            }

            if (
                colIndex >= 1 &&
                (args.data[colIndex].columnHeaders.includes(
                    this.todayDate) || args.data[colIndex].columnHeaders === this.todayMonth + '-' + new Date().getFullYear().toString().slice(2) && utilizationViewByData === 3 ||
                    args.data[colIndex].columnHeaders.includes(this.currentWeek) && utilizationViewByData === 2)
            ) {
                args.cell.classList.add('today-color');
            }
            if (
                colIndex >= 1 &&
                args.data[colIndex].columnHeaders.includes("Total")
            ) {
                args.cell.classList.add('total-bg-color');
            }
            if (colIndex >= 1 && args.data[colIndex].columnHeaders.includes(",")) {
                const leaveName = args.data[colIndex].columnHeaders.split(",")[1].split(")")[0]
                if (args.cell.children[0]) {
                    args.cell.children[0].classList.add(this.allLeaveColors[leaveName])
                }
            }

            setTimeout(() => {

                document.getElementById('utr-progress').style.display = 'none'
            }
                , 600)
        } catch (error) {
            // console.log("🚀 ~ file: utrreport.jsx:1017 ~ queryCellInfo ~ error:", error)
        }
    }
    componentDidMount() {
        this.props.setCounter(-1)
        // console.log("componentDidMount", this.props.utilizationReportId)
        for (let eachLeave of this.props.tibGrayLeaves) {
            const { grayLeave, color } = eachLeave
            let leaveClass = ''
            if (color === 'gray') {
                leaveClass = 'text-weekoff-color'
            } else if (color === 'red') {
                leaveClass = 'text-absent-color'
            } else if (color === 'blue') {
                leaveClass = 'text-leave-color'
            }
            this.allLeaveColors[grayLeave] = leaveClass
        }
        window.addEventListener("resize", this.refreshComponent);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.refreshComponent);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.utilizationReportId === this.props.Id) {
            if (this.LoadingStatus === "None") {
                try {
                    // console.log("UNSAFE_componentWillReceiveProps", nextProps.utilizationReportId)
                    this.LoadingStatus = "In Process"
                    const { empId, sdPAId, monthYear, authUser, utilizationViewByData, utilisationSelectedDataField } = this.props;
                    const resposnse = reportService.getUtilizationReportData(authUser, monthYear, sdPAId, utilizationViewByData, empId, utilisationSelectedDataField);
                    resposnse.then((data) => {
                        this.refineData(data);
                        setTimeout(() => {
                            this.setState({ isLoaded: true });
                        }, 100);
                        this.LoadingStatus = "Done";
                        this.props.utilizationReportUpdate({ utilizationReportId: nextProps.utilizationReportId + 1 });
                        this.props.setCounter(1)
                    })
                } catch (error) {
                    // console.log("UNSAFE_componentWillReceiveProps", error)
                }
            }
            else {
                this.props.utilizationReportUpdate({ utilizationReportId: nextProps.utilizationReportId + 1 })
            }
        }
    }

    refineData(data) {
        if (typeof data !== 'undefined' && data !== null) {
            this.setDefaultData(data);
            const drillCnt = [...new Set(data.map(item => item.monthType))];
            dataSourceSettings.dataSource = data;
            if (drillCnt.length === 1) // NOSONAR
                dataSourceSettings.drilledMembers = [{ name: 'monthType', items: [...new Set(data.map(item => item.monthType))], order: 'None' }, { name: "mainCategory", items: ["Scheduled Hours in EC", "B.1. Scheduled Hours in EC", "Actual Hours in EC", "B.2. Actual Hours in EC"] }];
            else {
                dataSourceSettings.drilledMembers = [{ name: "mainCategory", items: ["Scheduled Hours in EC", "B.1. Scheduled Hours in EC", "Actual Hours in EC", "B.2. Actual Hours in EC"] }];
            }
            this.data = { userName: data[0].userName, empCode: data[0].empCode, jobTitle: data[0].jobTitle, sdpaNm: data[0].sdpaNm };
        }
    }

    columnRender(args) {
        const { reportKey, } = this.props
        args.columns[0].width = 243;
        let width = 0
        for (let each of args.columns) {
            if (each.headerText.includes("Time")) {
                width = width + this.state.cellWidth
            }
        }
        width += 263
        const pivotWidth = document.getElementById(`PivotView${reportKey}`)
        if (pivotWidth && width <= window.innerWidth) {
            document.getElementById(`PivotView${reportKey}`).style.width = width + "px"

        } else {
            document.getElementById(`PivotView${reportKey}`).style.width = window.innerWidth - 150 + "px"
        }
        for (var i = 1; i < args.stackedColumns.length; i++) { // NOSONAR
            if (args.stackedColumns[i].headerText.includes("Self") || args.stackedColumns[i].headerText.includes("Other")) {
                args.stackedColumns[i].visible = false;
            }
        }

    }
    /** it is to by default show and hide rows or columns  */
    loadView = (utilizationViewByData, utilizationViewByUpdated, timeout, empId) => { // NOSONAR
        if (utilizationViewByData === 1) {
            try {
                this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: "mainCategory", items: [] }, { name: 'monthType', items: [], order: 'None' }, { name: 'weekType', items: [] }] } }, true); //
                this.isRowSelect = false;
                this.updateRowColumn(false, false, true);
            }
            catch (err) {
            }
            if (utilizationViewByUpdated === 'updated') {
                setTimeout(() => {
                    document.getElementById('utr-progress').style.display = 'none'
                    document.getElementById(`divPivotView${empId}`).style.display = ''
                }
                    , timeout + 100)
            }
        }
        if (utilizationViewByData === 3) {
            setTimeout(() => {
                try {
                    this.membersOrder.dataSource = []
                    this.membersOrder.value = []
                    this.membersOrder.dataSource = this.fieldCollections["monthType"];
                    this.membersOrder.value = this.getSelectedMembers("monthType");
                    this.membersOrder.dataBind();
                    this.field1.dataBind();
                    this.setMemberCheckedState("monthType", this.monthYearForView, this.monthYearForView + '_' + false);
                    this.storeMembers["monthType"] = []
                    this.fieldsddl.value = [];
                    this.isRowSelect = false;
                    this.isColumnSelect = false;
                    this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: 'mainCategory', items: this.storeMembers['mainCategory'] }, { name: 'monthType', order: 'None', items: this.storeMembers['monthType'] },] } }, true); // { name: 'weekType', items: this.storeMembers['weekType'] }, { name: 'meetingDate', items: this.storeMembers['meetingDate'] }
                    this.updateRowColumn(false, false, false);
                }
                catch (err) {
                }
            }
                , timeout)
            if (utilizationViewByUpdated === 'updated') {
                setTimeout(() => {
                    document.getElementById('utr-progress').style.display = 'none'
                }
                    , timeout + 200)
            }
        }
        if (utilizationViewByData === 2) {
            setTimeout(() => {
                try {
                    this.membersOrder.dataSource = []
                    this.membersOrder.value = []
                    this.membersOrder.dataSource = this.fieldCollections["monthType"];
                    this.membersOrder.value = this.getSelectedMembers("monthType");
                    this.membersOrder.dataBind();
                    this.field1.dataBind();
                    this.setMemberCheckedState("monthType", this.monthYearForView, this.monthYearForView + '_' + true);
                    this.applyBtn.disabled = false;
                    this.storeMembers["monthType"].push(this.monthYearForView);
                    this.fieldsddl.value = [];
                    this.isRowSelect = false;
                    this.isColumnSelect = false;
                    this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: 'mainCategory', items: this.storeMembers['mainCategory'] }, { name: 'monthType', order: 'None', items: this.storeMembers['monthType'] }, { name: 'weekType', items: this.storeMembers['weekType'] },] } }, true); // { name: 'meetingDate', items: this.storeMembers['meetingDate'] }  
                    this.updateRowColumn(false, false, false);
                }
                catch (err) {
                }
            }
                , timeout)
            if (utilizationViewByUpdated === 'updated') {
                setTimeout(() => {
                    document.getElementById('utr-progress').style.display = 'none'
                }
                    , timeout + 200)
            }
        }
    }
    /**it will be called on creation of pivot component */
    onCreated = () => { // NOSONAR
        document.getElementById('utr-progress').style.display = ''
        const fixedColum = document.getElementsByTagName('thead')
        for (let each of fixedColum) {
            each.children[each.children.length - 1].innerText = ''
            each.children[each.children.length - 1].style.display = 'none'
        }
        const fieldWeek = document.querySelectorAll("[fieldname=weekType]")

        const { utilizationViewByData } = this.props;
        let indexChild = utilizationViewByData === 1 ? true : false
        const fieldmonthType = document.querySelectorAll("[fieldname=monthType]")
        if (fieldmonthType) {

            for (let each of fieldmonthType) {
                each.parentElement.setAttribute("style", 'height:35px !important')
                if (indexChild) {
                    if (each.children[0]) {
                        if (each.children[0].children[1]) {
                            each.children[0].children[1].style = 'margin-left: -10px;'
                        }
                    }

                }
                if (utilizationViewByData === 2) {
                    if (each.children[0]) {
                        each.children[0].style = 'margin-left:-5px;'
                        if (each.children[0].children[1]) {
                            each.children[0].children[1].style = 'margin-left: -11px;'
                        }
                    }

                }
            }
        }

        if (fieldWeek && utilizationViewByData !== 3) {
            try {
                for (const element of fieldWeek) {
                    if (element.children[0].parentElement.offsetWidth < 150) {
                        if (indexChild) {
                            if (element.children[0].innerText.includes("(")) {
                                element.children[0].children[1].innerHTML = `<div class='week-utr-daily'><p class="wrap-week-font">  ${element.children[0].children[1].innerText.split("(")[0].split("-")[0] + '-' + this.getWeekNumber(element.children[0].children[1].innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0])}</p><p  class="wrap-week-font2"> ${element.children[0].children[1].innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0].trim() + "-" + element.children[0].children[1].innerText.split("(")[1].slice(0, -1).split("to")[1].split("-")[0].trim()}</p>`  // : fieldWeek[index].children[0].innerHTML
                                element.children[0].children[1].classList.add("wrap-week-text")
                            }
                        }
                        else {
                            if (element.children[0].innerText.includes("(")) {
                                element.children[0].innerHTML = `<div class='week-utr-daily'><p class="wrap-week-font">  ${element.children[0].innerText.split("(")[0].split("-")[0] + '-' + this.getWeekNumber(element.children[0].innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0])}</p><p  class="wrap-week-font2"> ${element.children[0].innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0].trim() + "-" + element.children[0].innerText.split("(")[1].slice(0, -1).split("to")[1].split("-")[0].trim()}</p>` //  : fieldWeek[index].children[0].innerHTML
                                element.children[0].classList.add("wrap-week-text")
                            }
                        }
                        if (element.nextElementSibling && !element.nextElementSibling.innerText.includes("(") && element.nextElementSibling.innerText.includes("Total")) {
                            element.nextElementSibling.children[0].innerHTML =
                                `<div class='week-utr-daily'><p class="wrap-week-font">${this.monthYearForView}</p><p class="wrap-week-font2">Total</p>`
                            element.nextElementSibling.children[0].classList.add("wrap-week-text")
                        }

                    } else {
                        if (indexChild) {
                            if (element.children[0].innerText.includes("(")) {
                                fieldWeek[0].parentElement.setAttribute("style", 'height:35px !important')
                                element.children[0].children[1].innerHTML = element.children[0].children[1].innerText.split("(")[0].split("-")[0] + '-' + this.getWeekNumber(element.children[0].children[1].innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0]) + ' (' + element.children[0].children[1].innerText.split("(")[1]
                                element.children[0].children[1].classList.add("wrap-week-text")
                            }
                            if (element.nextElementSibling && !element.nextElementSibling.innerText.includes("(") && element.nextElementSibling.innerText.includes("Total")) {
                                element.nextElementSibling.children[0].innerHTML =
                                    `<div class='week-utr-daily'><p class="wrap-week-font">${this.monthYearForView}</p><p class="wrap-week-font2">Total</p></div>`
                                element.nextElementSibling.children[0].classList.add("wrap-week-text")
                            }
                        } else {
                            if (element.children[0].innerText.includes("(")) {
                                fieldWeek[0].parentElement.setAttribute("style", 'height:35px !important')
                                element.children[0].innerHTML = element.children[0].innerText.split("(")[0].split("-")[0] + '-' + this.getWeekNumber(element.children[0].innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0]) + " (" + element.children[0].innerText.split("(")[1]
                                element.children[0].classList.add("wrap-week-text")

                            }
                        }

                    }
                }
            } catch (err) {
            }
        }
        const fieldname = document.querySelectorAll("[fieldname=meetingDate]")
        if (fieldname) {
            for (let each of fieldname) {
                if (each.innerHTML) {
                    each.children[0].innerHTML =
                        `<p class="wrap-week-font">${each.children[0].innerText.toString().split("(")[0]}</p><p class="wrap-week-font2">(${each.children[0].innerText.toString().split("(")[1]}</p>`
                    each.children[0].classList.add("center-align-date")
                }
                if (each.nextElementSibling && each.nextElementSibling.innerText.includes("Wk-") && each.nextElementSibling.innerText.includes("Total")) {
                    let newWeek = each.nextElementSibling
                    each.nextElementSibling.children[0].innerHTML = `<p class="wrap-week-font">  ${newWeek.innerText.split("(")[0].split("-")[0] + '-' + this.getWeekNumber(newWeek.innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0])}</p><p  class="wrap-week-font2" style='margin-left: -2px;'>${newWeek.innerText.split("(")[1].slice(0, -1).split("to")[0].split("-")[0] + "-" + newWeek.innerText.split("(")[1].slice(0, -1).split("to")[1].split("-")[0].trim()}</p>`  // : fieldWeek[index].children[0].innerHTML
                    each.nextElementSibling.children[0].classList.add("center-align-date")
                }
                /**for column header */
                if (each.children[0].innerHTML.includes('<p class="wrap-week-font2">') && each.children[0].innerHTML.split('class="wrap-week-font2">')[1].includes(",")) {
                    const leaveAttendType = each.children[0].innerHTML.split('class="wrap-week-font2">')[1].split(")")[0].split(",")[1]
                    each.children[0].classList.add(this.allLeaveColors[leaveAttendType])
                    if (each.children[0][0]) {
                        each.children[0][0].classList.add(this.allLeaveColors[leaveAttendType])
                    }
                    each.children[0].classList.remove("e-stackedheadercelldiv")
                    each.children[0].classList.add(this.allLeaveColors[leaveAttendType])
                }

            }
        }
        setTimeout(() => {
            const todayColumnEls = document.getElementsByClassName("text-today-div-border")
            const planningScoreText = document.querySelectorAll('[aria-label="D. Planning Score column header "]')
            const planningScoreHeight = document.getElementsByClassName("e-frozen-left-content")
            if (todayColumnEls) {
                for (let each of todayColumnEls) {
                    try {
                        each.offsetParent.classList.add('today-color')
                    }
                    catch (err) {
                    }
                }
            }
            if (planningScoreText) {
                for (let eachplan of planningScoreText) {
                    eachplan.innerHTML = '<span class="planning-score-utr" style="margin-left: 31px;">Planning Score (1-10) </span>'
                }
            }
            if (planningScoreHeight && indexChild) {
                for (let eachPlanHeight of planningScoreHeight) {
                    eachPlanHeight.style.height = Number(eachPlanHeight.style.height.split("px")[0]) + 17 + "px"
                }

            }
        }, 200)
    }


    dataBound(_args) {
        const { utilizationViewByData, utilizationViewByUpdated, empId } = this.props
        if (this.isInitial) {
            let fieldCnt = this.fields.length - 1;
            while (fieldCnt > -1) {
                let members = Object.keys(this.pivotObj.engineModule.fieldList[this.fields[fieldCnt].Field].members);
                let memberCnt = members.length;
                let membersCollection = [];
                for (let i = 0; i < memberCnt; i++) {
                    membersCollection.push({ Member: members[i], Checked: members[i] + '_' + false });
                }
                this.fieldCollections[this.fields[fieldCnt].Field] = membersCollection;
                fieldCnt--;
            }
            this.values = this.fieldCollections[this.fields[0].Field];
            this.loadView(utilizationViewByData, utilizationViewByUpdated, 100, empId)
            this.membersOrder.dataSource = this.values;
            this.membersOrder.dataBind();
            this.fieldsddl.dataBind();
            this.isInitial = false;
        }
    }
    onChange(e) {
        this.membersOrder.dataSource = this.fieldCollections[e.itemData['Field']];
        this.membersOrder.value = this.getSelectedMembers(e.itemData['Field']);
        this.membersOrder.dataBind();
        this.field1.dataBind();
    }

    onChangeOption(args) {
        document.querySelector('.field_cls').style.display = 'none';
        document.querySelector('.field_cls_1').style.display = 'none';
        document.querySelector('.members_cls').style.display = 'none';
        document.querySelector('.apply_cls').style.display = 'none';
        if (args.value == 'allHeaders') {
            this.clear();
            this.pivotObj.setProperties({ dataSourceSettings: { expandAll: true, drilledMembers: [{ name: "mainCategory", items: [] }, { name: 'monthType', order: 'None', items: [] }] } }, true); // , { name: 'weekType', items: [] }
            this.pivotObj.refreshData();
        }
        else if (args.value == 'rowHeaders') {
            this.clear();
            this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: "mainCategory", items: [] }, { name: 'monthType', order: 'None', items: [] }] } }, true);
            this.updateRowColumn(false, true, false);
        }
        else if (args.value == 'columnHeader') {
            this.clear();
            this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: "mainCategory", items: [] }, { name: 'monthType', order: 'None', items: [] }] } }, true);
            this.updateRowColumn(false, false, true);
        }
        else if (args.value == 'specificFields') {
            document.querySelector('.field_cls').style.display = '';
        }
        else if (args.value == 'specificHeaders') {
            document.querySelector('.field_cls_1').style.display = '';
            document.querySelector('.members_cls').style.display = '';
            document.querySelector('.apply_cls').style.display = '';
        }
    }
    onMembersSelect(args) {
        this.setMemberCheckedState(this.field1.itemData.Field, args['item'].textContent, args['item'].textContent + '_' + true);
        this.applyBtn.disabled = false;
        this.storeMembers[this.field1.itemData.Field].push(args.itemData['Member']);
    }
    onMembersRemove(args) {
        this.setMemberCheckedState(this.field1.itemData.Field, args['item'].textContent, args['item'].textContent + '_' + false);
        this.index = this.storeMembers[this.field1.itemData.Field].indexOf(args.itemData['Member']);
        if (this.storeMembers[this.field1.itemData.Field].indexOf(args.itemData['Member']) > -1) {
            this.storeMembers[this.field1.itemData.Field].splice(this.index, 1);
        }
    }
    onFieldSelect(args) {
        this.membersOrder.value = [];
        if (this.storeMembers['mainCategory'].length > 0 || this.storeMembers['monthType'].length > 0 || this.storeMembers['weekType'].length > 0 || this.storeMembers['meetingDate'].length > 0) {
            this.storeMembers = { 'mainCategory': [], 'monthType': [], }; // 'weekType': [], 'meetingDate': [] 
            this.isInitial = true;
        }
        if (args.itemData['Field'] === 'mainCategory') {
            this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: "mainCategory", items: ["Scheduled Hours in EC", "B.1. Scheduled Hours in EC", "Actual Hours in EC", "B.2. Actual Hours in EC"] }, { name: 'monthType', items: [], order: 'None' }, { name: 'weekType', items: [] }] } }, true);
            this.updateRowColumn(false, true, this.isColumnSelect);
            this.isRowSelect = true;
        }
        else if (args.itemData['Field'] === 'monthType' || args.itemData['Field'] === 'weekType' || args.itemData['Field'] === 'meetingDate') {
            this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: "mainCategory", items: ["Scheduled Hours in EC", "B.1. Scheduled Hours in EC", "Actual Hours in EC", "B.2. Actual Hours in EC"] }, { name: 'monthType', items: [], order: 'None' }, { name: 'weekType', items: [] }] } }, true);
            this.updateRowColumn(false, this.isRowSelect, true);
            this.isColumnSelect = true;
        }

    }
    onFieldRemove(args) {
        if (args.itemData['Field'] === 'monthType' || args.itemData['Field'] === 'weekType') {
            this.updateRowColumn(false, false, this.isColumnSelect);
            this.isRowSelect = false;
        }

        else if (args.itemData['Field'] === 'mainCategory') {
            this.updateRowColumn(false, this.isRowSelect, false);
            this.isColumnSelect = false;
        }
    }
    open(args) {
        args.popup.element.querySelector(".e-filter-parent").style.display = 'none';
    }
    onClick() {
        this.fieldsddl.value = [];
        this.isRowSelect = false;
        this.isColumnSelect = false;
        this.pivotObj.setProperties({ dataSourceSettings: { drilledMembers: [{ name: 'mainCategory', items: this.storeMembers['mainCategory'] }, { name: 'monthType', order: 'None', items: this.storeMembers['monthType'] },] } }, true); //  { name: 'weekType', items: this.storeMembers['weekType'] }, { name: 'meetingDate', items: this.storeMembers['meetingDate'] }
        this.updateRowColumn(false, false, false);
    }
    /** To set the checked status of the members maintained in the object fieldCollections. */
    setMemberCheckedState(field, member, checkedState) {
        let members = this.fieldCollections[field];
        let membersLength = members.length - 1;
        while (membersLength > -1) {
            if (members[membersLength].Member === member) {
                members[membersLength].Checked = checkedState;
                break;
            }
            membersLength--;
        }
    }
    /** To get the checked members/status here as string array. */
    getSelectedMembers(field) {
        let membersCollection = [];
        let members = this.fieldCollections[field];
        let membersLength = members.length - 1;
        while (membersLength > -1) {
            if (members[membersLength].Checked === members[membersLength].Member + '_' + true) {
                membersCollection.push(members[membersLength].Member.toString());
            }
            membersLength--;
        }
        return membersCollection;
    }
    updateRowColumn(isExpand, isRowExpand, isColumnExpand) { // NOSONAR
        const { utilizationViewByData } = this.props
        let columnsData;
        if (utilizationViewByData === 1) {
            columnsData = [
                { name: 'monthType', expandAll: this.fieldsddl.dataSource[1].expandAll = isColumnExpand, order: 'None' }, // NOSONAR
                { name: 'weekType', expandAll: this.fieldsddl.dataSource[1].expandAll = isColumnExpand }, // NOSONAR
                { name: 'meetingDate', expandAll: this.fieldsddl.dataSource[1].expandAll = isColumnExpand },]// NOSONAR
        } else if (utilizationViewByData === 2) {
            columnsData = [
                { name: 'monthType', expandAll: this.fieldsddl.dataSource[1].expandAll = isColumnExpand, order: 'None' },// NOSONAR
                { name: 'weekType', expandAll: this.fieldsddl.dataSource[1].expandAll = isColumnExpand },]// NOSONAR
        } else {// NOSONAR
            columnsData = [
                { name: 'monthType', expandAll: this.fieldsddl.dataSource[1].expandAll = isColumnExpand, order: 'None' },]// NOSONAR
        }
        this.pivotObj.setProperties({
            dataSourceSettings: {
                expandAll: isExpand, rows: [
                    { name: 'mainCategory', expandAll: this.fieldsddl.dataSource[0].expandAll = isRowExpand },// NOSONAR
                    { name: 'meetingCategory', expandAll: this.fieldsddl.dataSource[0].expandAll = isRowExpand },// NOSONAR
                    { name: 'activityType', expandAll: this.fieldsddl.dataSource[0].expandAll = isRowExpand },// NOSONAR
                    { name: 'subActivityType', expandAll: this.fieldsddl.dataSource[0].expandAll = isRowExpand },// NOSONAR
                    { name: 'subActivityType1', expandAll: this.fieldsddl.dataSource[0].expandAll = isRowExpand },// NOSONAR
                    { name: 'subActivityType2', expandAll: this.fieldsddl.dataSource[0].expandAll = isRowExpand },// NOSONAR
                ], columns: columnsData
            }
        }, true);
        this.pivotObj.refreshData();
    }
    clear() {
        this.fieldsddl.value = [];
        this.isRowSelect = false;
        this.isColumnSelect = false;
        this.membersOrder.value = [];
        if (this.storeMembers['mainCategory'].length > 0 || this.storeMembers['monthType'].length > 0 || this.storeMembers['weekType'].length > 0 || this.storeMembers['meetingDate'].length > 0) {
            this.storeMembers = { 'mainCategory': [], 'monthType': [], 'weekType': [], 'meetingDate': [] };
            this.isInitial = true;
        }
    }

    setDefaultData(data) {
        data.forEach(item => {
            if (item.meetingCategory === "")
                item.meetingCategory = undefined;
            if (item.activityType === "")
                item.activityType = undefined;
            if (item.subActivityType === "")
                item.subActivityType = undefined;
            if (item.subActivityType1 === "")
                item.subActivityType1 = undefined;
            if (item.subActivityType2 === "")
                item.subActivityType2 = undefined;
            if (item.selfMeetingCount === 0)
                item.selfMeetingCount = undefined;
            if (item.otherMeetingCount === 0)
                item.otherMeetingCount = undefined;
        });
    }
    renderPopupInfo = () => {
        return (
            <div >
                <Dialog
                    aria-labelledby="uploading-title"
                    open={true}
                    id='utr-report-main-popup-meeting-count'

                    style={{ display: "none" }}
                >
                    <DialogTitle style={{ background: " #4B4B55", color: "white" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "166px" }}>
                                <p id="utr-report-main-popup-title" style={{ fontSize: "13px" }}>
                                </p>
                                <p id="utr-report-main-popup-sub-title" style={{ fontSize: "11px", marginTop: "-9px", marginBottom: "-11px" }}>
                                </p>
                            </div>
                            {/* <p onClick={() => {
                                document.getElementById("utr-report-main-popup-meeting-count").style.display = 'none'
                            }} style={{ cursor: "pointer", margin: "8px -13px -3px 17px", fontSize: "15px" }}>
                                X
                            </p> */}
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div id='utr-meeting-count-info' style={{ paddingLeft: "12px", paddingRight: "13px" }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "141px" }}>Total Time (HH:MM)</td>
                                        <td>:</td>
                                        <td id='utr-meeting-total-time-count' style={{ paddingLeft: 5 }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "141px" }}>Total Meetings </td>
                                        <td>:</td>
                                        <td id='utr-meeting-total-count' style={{ paddingLeft: 5 }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "141px" }}>Self Meetings  </td>
                                        <td>:</td>
                                        <td id='utr-meeting-self-count' style={{ paddingLeft: 5 }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "141px" }}>Meetings with others  </td>
                                        <td>:</td>
                                        <td id='utr-meeting-other-count' style={{ paddingLeft: 5 }}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ paddingRight: "1.1em", borderTop: "1px solid #c3c3c3" }}>
                        <Button color="secondary" variant="contained" onClick={() => {
                            document.getElementById("utr-report-main-popup-meeting-count").style.display = 'none'
                        }}> Close </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }


    render() {
        const { isLoaded, leftHeight } = this.state;
        const { empId, reportKey, } = this.props;
        const { userName, empCode, jobTitle, sdpaNm } = this.data


        const heightCss = `tr.e-columnheader{
            height:${leftHeight}px !important;
        }
        `
        return (
            <div>
                {!isLoaded && <ProgressBar />}
                {isLoaded &&
                    <div className="control-pane" id={`divPivotView${empId}`} style={{ fontFamily: 'Roboto,Segoe UI,GeezaPro,DejaVu Serif,sans-serif,-apple-system,BlinkMacSystemFont' }}>
                        <div className="control-section">
                            {/* Employee Details */}
                            <style>{this.style1440Res}</style>
                            <div className="control-section col-md-12 ursdetails">
                                <div className="row emp-details">
                                    <div style={{
                                        border: "lightgray 1px solid",
                                        margin: "0px 25px 0px 0px",
                                    }} />

                                    {userName === '' &&
                                        <div className="col-md-3 remove-padding" style={{ paddingTop: 5, paddingLeft: 0, paddingBottom: 5, width: "100%" }}>
                                            <div className="col-md-2 remove-padding" style={{ height: "100%" }}>
                                                <div className="row1 user-icon-div">
                                                    <FontAwesomeIcon
                                                        icon={faLaptopHouse}
                                                        className="mr-sm icons-timeinbusiness"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-10 remove-padding icon-left-align">
                                                <div className="row icon-header-text-font-style-user">
                                                    Practice Area / Sales Unit / Sub Function
                                                </div>
                                                <div className="row icon-text-font-style-user">
                                                    {sdpaNm}
                                                </div>
                                            </div>
                                        </div>

                                    }

                                    {userName !== '' &&
                                        <>
                                            <div className="col-md-3 remove-padding"
                                                style={{
                                                    paddingTop: 5,
                                                    paddingLeft: 0,
                                                    paddingBottom: 5,
                                                }}>
                                                <div
                                                    className="col-md-2 remove-padding"
                                                    style={{ height: "100%" }}
                                                >
                                                    <div className="row1 user-icon-div">
                                                        <FontAwesomeIcon
                                                            icon={faUser}
                                                            className="mr-sm icons-timeinbusiness"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10 remove-padding icon-left-align">
                                                    <div className="row icon-header-text-font-style-user">
                                                        Name
                                                    </div>
                                                    <div className="row icon-text-font-style-user">
                                                        {userName}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3 remove-padding"
                                                style={{
                                                    paddingTop: 5,
                                                    paddingLeft: 0,
                                                    paddingBottom: 5,
                                                }}>
                                                <div className="col-md-2 remove-padding"
                                                    style={{ height: "100%" }}>
                                                    <div className="row1 user-icon-div">
                                                        <FontAwesomeIcon
                                                            icon={faIdCard}
                                                            className="mr-sm icons-timeinbusiness"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10 remove-padding">
                                                    <div className="row icon-header-text-font-style-user">
                                                        Employee Code
                                                    </div>
                                                    <div className="row icon-text-font-style-user">
                                                        {empCode}
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="col-md-3 remove-padding"
                                                style={{
                                                    paddingTop: 5,
                                                    paddingLeft: 0,
                                                    paddingBottom: 5,
                                                }}>
                                                <div className="col-md-2 remove-padding"
                                                    style={{ height: "100%" }}>
                                                    <div className="row1 user-icon-div">
                                                        <FontAwesomeIcon
                                                            icon={faToolbox}
                                                            className="mr-sm icons-timeinbusiness"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10 remove-padding">
                                                    <div className="row icon-header-text-font-style-user">
                                                        Title
                                                    </div>
                                                    <div className="row icon-text-font-style-user">
                                                        {jobTitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>

                            {/* Employee Details */}
                            <div className="col-md-12"
                                style={{ marginLeft: 0, marginBottom: 25, paddingRight: 10 }}>
                                <style>{SAMPLECSS}</style>
                                <style>{heightCss}</style>
                                <PivotViewComponent id={`PivotView${reportKey}`} className="pivotGrid" ref={(pivotview) => { this.pivotObj = pivotview; }}
                                    dataSourceSettings={dataSourceSettings}
                                    showTooltip={false}
                                    height={this.state.height}
                                    // width={'100%'}
                                    displayMode
                                    gridSettings={{
                                        columnWidth: this.state.cellWidth,
                                        allowReordering: false,
                                        allowTextWrap: false,
                                        allowSelection: false,
                                        // selectionSettings: { mode: 'Row', type: 'Single' },
                                        allowAutoResizing: false,
                                        queryCellInfo: this.queryCellInfo.bind(this),
                                        columnRender: this.columnRender.bind(this),
                                        textWrapSettings: { Content: "wrapMode" }
                                        // headerCellInfo: this.headerCellInfo.bind(this)

                                    }}
                                    aggregateCellInfo={(e) => { this.aggregateCellInfo(e) }}
                                    enginePopulated={this.enginePopulated.bind(this)}

                                    allowConditionalFormatting={true}
                                    allowNumberFormatting={true}
                                    allowCalculatedField={true}
                                    toolbar={true}
                                    rowHeight={60}
                                    dataBound={this.dataBound.bind(this)}
                                // created={this.onCreated}
                                >
                                    <Inject services={[ConditionalFormatting, FieldList, NumberFormatting, CalculatedField]} />
                                </PivotViewComponent>

                                {this.renderPopupInfo()}

                            </div>
                            <div className='col-lg-4 property-section pivot-property-section'>
                                <div title='Properties'>
                                    <table id='property' title='Properties' className='property-panel-table' style={{ width: '100%', height: '100%' }}>
                                        <tbody>
                                            <tr style={{ height: '50px', display: "none" }}>
                                                <td>
                                                    <div className='hdrlabel' style={{ height: '50px' }}>
                                                        Drill Down:
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <DropDownListComponent enabled={true} type="text" tabIndex={1} change={this.onChangeOption.bind(this)} width={"98%"} id="etype" dataSource={this.options} fields={{ value: 'value', text: 'text' }} value='rowHeaders' />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className='field_cls' style={{ height: '50px', display: 'none' }}>
                                                <td>
                                                    <div className='hdrlabel'>
                                                        Fields:
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <MultiSelectComponent ref={(scope) => { this.fieldsddl = scope; }} select={this.onFieldSelect.bind(this)} removed={this.onFieldRemove.bind(this)} open={this.open.bind(this)} width={"98%"} placeholder="Select fields" id="etype" type='text' tabIndex={1} dataSource={this.fields} mode='CheckBox' showDropDownIcon={true} showClearButton={false} enableSelectionOrder={false} fields={{ text: 'Field' }}>
                                                            <Inject services={[CheckBoxSelection]} />
                                                        </MultiSelectComponent>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className='field_cls_1' style={{ height: '50px', display: 'none' }}>
                                                <td>
                                                    <div className='hdrlabel'>
                                                        Fields:
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <DropDownListComponent enabled={true} ref={(scope) => { this.field1 = scope; }} placeholder="Select fields" change={this.onChange.bind(this)} width={"100%"} id="etype" type='text' tabIndex={1} dataSource={this.fields} fields={{ text: 'Field' }} value="Country" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className='members_cls' style={{ height: '50px', display: 'none' }}>
                                                <td>
                                                    <div className='hdrlabel'>
                                                        Headers:
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <MultiSelectComponent ref={(scope) => { this.membersOrder = scope; }} select={this.onMembersSelect.bind(this)} removed={this.onMembersRemove.bind(this)} open={this.open.bind(this)} width={"98%"} placeholder="Select headers" id="etype" type='text' tabIndex={1} dataSource={this.values} mode='CheckBox' showDropDownIcon={true} showClearButton={false} enableSelectionOrder={false} fields={{ text: 'Member' }}>
                                                            <Inject services={[CheckBoxSelection]} />
                                                        </MultiSelectComponent>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className='apply_cls' style={{ height: '50px', display: "none" }}>
                                                <td></td>
                                                <td>
                                                    <div id="btn-control" style={{ float: 'right' }}>
                                                        <ButtonComponent id='apply' ref={(scope) => { this.applyBtn = scope; }} onClick={this.onClick.bind(this)} isPrimary={true}>Apply</ButtonComponent>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ tibReducer }) => {
    const { utilizationReportId, utilizationReportUsersList, utilizationViewByData, utilizationViewByUpdated, tibGrayLeaves, utilisationSelectedDataField, } = tibReducer;
    return { utilizationReportId, utilizationReportUsersList, utilizationViewByData, utilizationViewByUpdated, tibGrayLeaves, utilisationSelectedDataField, };
};
export default connect(mapStateToProps, { utilizationReportUpdate })(UTRReport);







