/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */
import React, { useState, useEffect } from "react";
import './style.css';
import GroupsOutlinedIcon from '@material-ui/icons/GroupOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { getNotify } from "../../app/other/commonfunction";
import { EMAIL_COPIED, SUCCESS } from "../../assets/constants/constants";

const Sanpshot = (props) => {
  const [showProfile, setProfile] = useState(true)
  const [profileData, setProfileData] = useState({})
  const [teamsMessage, setTeamsMessage] = useState("")
  const [teamsEmail, setTeamsEmail] = useState("")
  const [doj, setDOJ] = useState("")
  const [emaiId, setEmailId] = useState('')
  const [designation, setDesignation] = useState('')
  const [userAccess] = useState(props.userAccess)
  const showHide = () => {
    props.plotingObj.nodes.each(e => {
      e.isSelected = false
    })
    setProfile(false)
    props.onSelectProfile(false)
  }
  function dateDiff(startingDate, endingDate) {
    let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10);
    }
    let endDate = new Date(endingDate);
    if (startDate > endDate) {
      const swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    const startYear = startDate.getFullYear();
    const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    let yearText = (yearDiff > 1) ? yearDiff + ' Years ' : yearDiff + ' Year '
    let montText = monthDiff + ' Months '
    return yearText + montText;
  }
  const GetDate = (update_date) => {
    try {
      let dateObj = new Date(update_date);
      let MonthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      let mont = (parseInt(dateObj.getMonth()) + 1)
      return dateObj.getDate() + "-" + MonthArr[mont - 1] + "-" + dateObj.getFullYear();
    } catch (err) {
      console.log(err)
    }
  }
  // convertDaysToText  
  const convertDaysToText = (days) => {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    // const remainingDays = days % 30; ${remainingDays} Day${remainingDays<10 ? '':'s'}
    return `${years} Years ${months} Months `;
  }

  useEffect(() => {
    setProfileData(props.profileData)
    setTeamsMessage("MSTeams:/l/chat/0/0?users=" + props.profileData.teams)
    setTeamsEmail("mailto:" + props.profileData.emailId)
    setDOJ(GetDate(props.profileData.joiningDate) + " (" + dateDiff(new Date(), props.profileData.joiningDate) + ")")
    let eamil = props.profileData.emailId
    let Designation = props.profileData.Designation
    if (eamil && eamil.length > 25) {
      eamil = eamil.substring(0, 25) + '...'
    }
    if (Designation && Designation.length > 25) {
      Designation = Designation.substring(0, 25) + '...'
    }
    setEmailId(eamil)
    setDesignation(Designation)
  }, [props.profileData])
  
  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email).then(() => {
      getNotify(SUCCESS,EMAIL_COPIED)
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };


  if (showProfile && Object.keys(profileData).length > 0) {
    let teamMemClass = profileData.teamMemStatus ? (profileData.teamMemStatus.includes("Upcoming") ? "upcoming" : profileData.teamMemStatus.toLowerCase()) : ""
    teamMemClass = (new Date(profileData.joiningDate).getTime() > new Date().getTime()) ? "upcoming" : teamMemClass
    return (<div style={{ "width": "100%", "height": "100%" }}><div className="profile-container">
      <div className="profile-header"><h4>Profile Snapshot</h4> <button type="button" className="close" onClick={showHide}></button></div>
      <div className="profile-section-1">
        <div className="profile-img"><img style={{ width: "60px", height: "60px", borderRadius: "50%" }} src={profileData.ImageUrl} alt="user-profile" /></div>
        <div className="profile-section">
          <div className="name">{profileData.Name}</div>
          <TooltipComponent
            closeOn="Click"
            content={profileData.Designation}
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
            style={{ fontWeight: 500 }}
          >
            <div className="disig">{designation}</div>
          </TooltipComponent>
          <div className="emp-code">{profileData.varEmpCode}</div>
          {profileData.teamMemStatus !== 'Actual' && <div className={"func func-" + teamMemClass}>{new Date(profileData.joiningDate).getTime() > new Date().getTime() ? 'Upcoming Team Member' : profileData.teamMemStatus}</div>}
          <TooltipComponent
            closeOn="Click"
            content={profileData.emailId}
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
            style={{ fontWeight: 500, display: 'flex' }}
          >
            <div className="email">
              <a style={{ textDecoration: 'none' }} href={teamsEmail} >
                <div className="message-icon" style={{ marginRight: "5px" }}><EmailOutlinedIcon style={{ height: 15, width: 15 }} /></div>
              </a>
              {emaiId}
            </div>
            <FontAwesomeIcon
              icon={faCopy}
              className="mt-sm ml-sm fa-lg"
              onClick={() => copyToClipboard(profileData.emailId)}
              style={{ cursor: 'pointer' }}
            />
          </TooltipComponent>
          <div className="username"><a style={{ textDecoration: 'none' }} href={teamsMessage} >
            <div className="message-icon" style={{ marginRight: "5px" }}><GroupsOutlinedIcon style={{ height: 15, width: 15 }} /></div>
          </a>
            {profileData.username} </div>
        </div>
      </div>
      <div className="profile-section-2">
        <div className="head">OTHER DETAILS</div>
        <div className="prow">
          <div className="label">Date of Joining</div>
          <div className="text">{doj}</div>
        </div>
        <div className="prow">
          <div className="label">Business Unit / Group Function</div>
          <div className="text">{profileData.businessPnl}</div>
        </div>
        <div className="prow">
          <div className="label">Sub Business Unit / Function</div>
          <div className="text">{profileData.subPnl}</div>
        </div>
        <div className="prow">
          <div className="label">Practice Area / Sales Unit / Sub Function</div>
          <div className="text">{profileData.practiceArea}</div>
        </div>
        <div className="prow">
          <div className="label">Layer</div>
          <div className="text">{profileData.layer}</div>
        </div>
        <div className="prow">
          <div className="label">Sub Layer</div>
          <div className="text">{profileData.subLayer}</div>
        </div>

        {/* <div className="prow-team">          
            <div className="label">Team Size</div>
            <div className="text">{profileData.totalReportee}</div>        
            <div className="label">Direct Reportees</div>
            <div className="text">{profileData.directReportee}</div>        
        </div> */}
        <div className="prow">
          <div className="label">Country of Work</div>
          <div className="text">{profileData.basedInCountry}</div>
        </div>
        {
          userAccess.length > 0 && <>
            <div className="prow">
              <div className="label">Mobile Number</div>
              <div className="text">{userAccess[3]?.Name === 'Yes' ? profileData.mobileNumber : '-'}</div>
            </div>
            <div className="prow">
              <div className="label">CTC</div>
              <div className="text">{userAccess[4]?.Name === 'Yes' ? (profileData.totalCtc ? profileData.totalCtc : "-") : '-'}</div>
            </div>
          </>
        }
        <div className="prow">
          <div className="label">Type of Employment</div>
          <div className="text">{profileData.workType}</div>
        </div>
        <div className="team-size">
          <div className="label">Team Size</div>&npsp;<div className="label">Direct Reportees</div>
          <div className="text1">{profileData.totalReportee}</div><div className="text2">{profileData.directReportee}</div>
        </div>
        <div className="prow" style={{ marginTop: 19 }}>
          <div className="label">9 Blocker Position</div>
          <div className="text">{profileData.nineBlockerPos ? profileData.nineBlockerPos : '-'}</div>
        </div>
        <div className="prow" style={{ paddingBottom: 20 }}>
          <div className="label">Professional Experience</div>
          <div className="text">{profileData.expInMonths ? convertDaysToText(profileData.expInMonths) : '-'}</div>
        </div>
      </div>
    </div>
    </div>
    )
  } else {
    return (<div></div>)
  }


}

export default Sanpshot