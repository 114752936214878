import React, { Component } from 'react'
import LPImage from '../../../assets/images/LP.png'

export default class LP extends Component {
    render() {
        return (
            
                    <div  style={{ overflowY: 'auto',width:'100%',height:'calc(100vh - 80px)'}}>
                        <center>
                            <img src={LPImage} style={{ objectFit: 'contain', padding: 10, overflowY: 'auto',height:'100%',width:'100%'}} alt="Leadership Principles" />
                        </center>
                    </div>
              
        )
    }
}
