export const MEETING_CATEGORY = 'meeting_category';
export const MY_MEETING = '1';
export const MEETING_SET_BY_ME = '3';
export const PLAN_MEETING = '4';
export const ACTUAL_MEETING = '5';
export const PLAN_VS_ACTUAL_MEETING = '6';
export const OTHERS_CALENDAR = '2';
export const OTHERS_CHECK_AVAILABILITY = '7';

export const INDIVIDUAL_VIEW = 'Individual View';

export const TIME_IN_BUSINESS = '8';
export const UPLOAD_DAILY_DATA = '34';
export const TIB_AVERAGE_REPORT = '45';
export const CALENDAR_REPORT = '39';
export const DOWNLOAD_USER_ACTIVITY_DATA = '40';
export const TEAM_VIEW = 'Team View';
export const ROOM_AVAILABILITY = 'Room Availability';
export const HOME_OFFICE = '33';
export const UTILIZATION_OF_TIME_REPORT = '73';


export const ON_BOARDING = '10';
export const CONFIRMATION = "11";
export const EMPLOYEE_PROFILE = '14';
export const PRE_OFFERE = '9';
export const ON_BOARDING_FUNCTION = "13";
export const RESIGNATIONMANAGERAPPROVAL = "20";//'Manager - Approval';
export const RESIGNATIONPNLAPPROVAL = "21";//'PNL - Approval';
export const RESIGNATION = "12";
export const HRAPPROVAL = "22"; //'HR - Approval';
export const ABSCONDING = "15";
export const TERMINATION = "16";
export const EXITCLEARANCEGRID = "19";
export const ABSCONDINGMANAGER = "23"//'Absconding (Manager Approval)';
export const ABSCONDINGHR =  "25";//'Absconding (HR)';
export const TERMINATIONMANAGER =  "26";//'Absconding (HR)';
export const TERMINATIONHR=  "28";//'Termination (HR)';
export const EXIT_CLEARANCE_MANAGER= "29";//'Exit Clearance (Functional Clearance)';
export const EXIT_CLEARANCE_FUNCTION= "30";//'Exit Clearance (Functional Clearance)';
export const EMPLOYEE_LIST= "31";//'Exit Clearance (Functional Clearance)';
export const ORG_CHART= "32";//'Orgnization Chart';
export const ORG_CHART_MASTERS= "35";//'Orgnization Chart';
export const ORG_CHART_NEW= "111";//'Orgnization Chart';
export const OKR_OBJECTIVE_KPI= "36";//'Objective & KPI';
export const OKR_PRODUCTIVE_ALLOCATION= "37";//'Productive Allocation';
export const OKR_COST_SHARING= "38";//'Cost Sharing';
export const OKR_SELF_REVIEW= "41";//'Okr Slef Review';
export const OKR_SUPERVISOR_REVIEW= "42";//'Okr Supervisor Review';
export const OKR_THIRD_PART_REVIEW= "43";//'Okr Third Party Review';
export const OKR_REVIEW= "44";//'OKR Review ';
export const OKR_SIDA= "53";//'OKR SIDA ';
export const OKR_RATING_AND_REVIEW= "52";//'OKR SIDA ';


export const USER_ROLE_MAPPING="46"; // User-Role Mapping
export const ROLE_MASTER="47"; // Manage Roles
export const MODULE_MASTER="48"; // Manage Modules

export const ADMIN_MODULE = "106"; // Admin Module Master
export const ORG_MASTER = "50"; // Org Master
export const ORG_MAPPING = "51";//Org Mapping
export const LNALAYOUT = "54";//Org Mapping
export const SETUP = "55";//Setup
// export const ATTENDANCE = "56";//Attendance
export const LEAVES = "57";//Leaves
export const CLUSTERSETP = "58";//Cluster Setup
export const TEAM_CONFIG = "59";//Team Config
export const APPLYSWIPE = "60";//Apply Swipe
export const APPROVESWIPE = "61";//Approve Swipe
export const VIEWDAILY = "62";//View Daily
export const VIEWMONTHLY = "63";//View Monthly
export const ATTENDANCEHRREPORT = "65";//Attendance HR Report
export const VIEWLEAVEBALACE = "66";//View Leave Balance
export const APPLYLEAVE = "67";//Apply Leave
export const CANCELLEAVE = "124";//Leave Cancel
export const APPROVELEAVE = "61";//Approve Leave
export const LEAVEHRREPORT = "69";//Leave HR Report
export const DASHBOARD = "71";
export const COMPLIANCE_REPORT = "72";
export const HOLIDAY_LIST = "74";
export const LEAVENOTIFIERLIST='75' //Leave Notifier
export const LEAVEDETAILS='76' //Leave Details
export const APPLYCOMPOFF='107' //Leave Details
export const LEAVETRANSFER='108' //Leave Details
export const ONLEAVE="123"; //ON LEAVE
export const ALLAPPROVAL = "61";//ALL Approve 
export const VIEWYEARLY = "64";//View Yearly
export const NEWSERVICEREQUEST = "114";//New Service Request 
export const NEWIDEA = "115";//New Idea 
export const MYTICKET = "116";//My Ticket 

// LnA Constants
export const ATTENDANCE = "1";
export const WORKDAY_WINDOW = "2";
export const WEEKDAY = "3";
export const DAYBREAK = "5";
export const ATTENDANCE_SOURCE = "6";
export const LEAVE = "7";
export const HOLIDAY = "9";
export const OPTIONAL_HOLIDAY = "10";
export const STATUTORY = "11";
export const EXCEPTIONAL_ATTENDANCE = "4";
export const WORKING_DAY = "8";
export const COLOUR = "12";
export const ROLE = "13";
export const SYMBOL = "14";
export const SPECIAL_ATTENDANCE = "113";
export const LOCK_UNLOCK = "41";
export const LEAVE_YEAR_CONFIG = "42";
export const WORKDAY_DAYBREAK_MAPPING = "46";
export const LEAVE_YEAR = "40";


export const AXIS = "15";
export const PERFORMANCE = "16";
export const POTENTIAL = "17";
export const AXIS_CUTOFF = "18";
export const NINE_BLOCKER = "19";
export const FREQUENCY = "20";
export const POTENTIAL_CALCULATION = "21";
export const SUB_POTENTIAL_CALCULATION = "22";
export const RATING_SYSTEM = "23";
export const TEAM_MEMBER = "25";

// tmlc sub modules
export const ORG_CHART_MASTER = '26';
export const LAYER_SUB_LAYER_MAPPING = '27';
export const EMAIL_MAPPING_MASTER = '44';

export const BUSINESS_RULE_ENGINE = "112";
export const RATING_SYSYTEM = "23";
export const RATING_MASTER = "24";
export const TICKET_RESOLUTION = "118"
export const TICKET_REPORTS = "119"
export const TICKET_ADMIN_MODULE = "120"
export const HELPDESK_SUb_CATEGORY = 28;

// appId for module separation
export const CONNECT_LANDING_PAGE_APP = 28;
export const PRE_OFFER_APP = 3;
export const ON_BOARDING_APP = 4;
export const CONFIRMATIONS_APP = 5;
export const SEPARATION_APP = 6;
export const ON_BOARDING_NEW_APP = 26;
export const VENDOR_MANAGEMENT_APP = 25;
export const PURCHASE_ORDER_APP = 29;
export const MASTERS_APP = 30;
export const APPROVALS_APP = 31;



