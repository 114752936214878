import React, { Component } from 'react';
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter,
  Freeze,
  Sort,
  Toolbar, ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Progressbar from '../../progressbar/progressbar';
import EmployeeNameTemplate from "../employeeNameTemplate";
import {
  getGridColumn,
  EMPLOYEE_LIST_EMPLOYEE_NAME,
  BUSINESS_UNIT_HEAD,
  LEVEL_1_TEAM_MEMBER_NAME,
  EMPLOYEE_LIST_ACTION,
  OPS_SALE_CENTRAL_PNL_HEAD,
  OPS_SUB_SALE_PNL_FUNCTION_HEAD,
  SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD,
  GROUP_SALE_MANAGER_NAME,
  PROJECT_MANAGER_SALES_PERSON_NAME,
  LEVEL8_TEAM_MEMBER_NAME,
  LEVEL9_TEAM_MEMBER_NAME,
  DATE_OF_JOINING,
  LEVEL_9,
  BASE_LOCATION1,
  REVISED_ROLE_TITLE,
  DATE_OF_BIRTH,
  TENURE_END_DATE,
  LEFT_DATE,
  EMPLOYEE_CODE,
  OFFERED_DATE,
  OFFER_ACCEPTANCE_DATE
} from "../../../app/other/gridcolumns";
import '../../../app/containers/tmlc/tmlc.css';
import '../resignationcomponent/resignationcomponent.css';
import './employeelist.css';
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { updateOrgChartDetailsAction, setOrhChartDetaila, addEditCostSharingAction, addEditCalendarAccess } from '../../../redux/actions/entryexit/orgchartaction';
import { updateCandidateData } from '../../../redux/actions/entryexit/candidateaction';
import { addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import { addUpdateObjectveKpi } from '../../../redux/actions/okr/okractions';
import { updateHelpDeskUserInfo } from "../../../redux/actions/helpdesk/helpdeskaction";
import { ABSCONDING, TERMINATION, EMPLOYEE_PROFILE, OKR_PRODUCTIVE_ALLOCATION } from '../../../redux/constant/meetingcategoryconstant';
import { encrypt, decodeString, encodeString, getDisplayDateOnly, dencrypt, getExcelDate } from '../../../app/other/commonfunction';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {
  DialogContent,
  DialogActions,
  DialogTitleTMLC,
  DialogTitle
} from "../../customdialog/customdialog";
import { Button, Dialog } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorBoundary from "../../error/errorboundary";
import EmployeeCostSharing from "./employeecostsharing";

import { syncUserLocal } from "../../../app/other/syncuser";
import { syncUser } from "../../../redux/actions/entryexit/onboardingaction";
import orgChatMaster from "../../../services/entryexit/onbordingServices";
import EmployeeEditPopup from "./employeeeditpopup";
import { ActingActualStatus, AllDateFormat } from "../allcommoncomponents";
import ErrorMessage from "../../errormessage/errormessage";

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import OtherCalendarAccessPopup from './otherCalendarAccessPopup';
import LockOKRRatingPopup from './lockokrratingmonthpopup';
import { format } from 'date-fns';
/** import { NoOfRowsPerPage } from "../../../config/config"; */
library.add(faTrashAlt)

class Employeelist extends Component {
  constructor(props) {/**NOSONAR */
    super(props);
    /** setSpinner({
      template: "<img src='https://icon-library.com/images/progress-icon-gif/progress-icon-gif-1.jpg' class='custom-rolling'></img>",
    });
    */
    this.state = {
      redirect: null,
      redirestCategary: "",
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      editEmployeeData: false,
      employeeUpdateData: [],
      openMenuDialog: false,
      openCostSharing: false,
      updateCostSharingData: [],
      reportingHeadMaster: [],
      getCostSharing: [],
      groupSalesManagerData: [],
      projectManagerSalesPersonData: [],
      projectIndividualInchargeData: [],
      costSharingDataExists: false,
      showLoading: false,
      openDialog: false,
      empId: null,
      employeeName: "",
      openOKRLockPopup: false
    };

    /** setSpinner({
      template: () => (<Progressbar />),
    });
    */
    const toolbarId = props.empListType + "ClearAllFilters";
    this.toolbarOptions = [
      // 'ExcelExport',
      { text: 'Excel Export', tooltipText: 'Excel Export', id: 'ExcelExport', align: 'Right', prefixIcon: 'e-export-excel' },
      { text: 'Clear Filters', cssClass: 'e-clear-all-filter', tooltipText: 'Clear Filters', id: toolbarId, align: 'Right', prefixIcon: 'e-filter-clear' }
    ];
    this.grid = React.createRef();
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100; // 100
    this.addEditCalendarAccessApi = this.addEditCalendarAccessApi.bind(this)
    this.Filter = {
      type: "Menu",
    };
    this.check = { type: 'CheckBox' };
    this.dateFormat = 'dd-MMM-yy';

    this.joiningDateFilterValue = {
      type: 'CheckBox',
      params: { format: this.dateFormat },
      itemTemplate: (e) => {
        if (e.JoiningDate) {
          return <>{getDisplayDateOnly(new Date(e.JoiningDate))}</>
        } else {
          return <span></span>
        }
      }
    };

    this.dateOfBirthFilterValue = {
      type: 'CheckBox',
      params: { format: this.dateFormat },
      itemTemplate: (e) => {
        if (e.DateofBirth) {
          return <>{getDisplayDateOnly(new Date(e.DateofBirth))}</>
        } else {
          return <span></span>
        }
      }
    };

    this.tenureEndDateFilterValue = {
      type: 'CheckBox',
      params: { format: this.dateFormat },
      itemTemplate: (e) => {
        if (e.TenureEndDate)
          return <>{getDisplayDateOnly(new Date(e.TenureEndDate))}</>
        else
          return <span></span>
      }
    }

    this.leftDateFilterValue = {
      type: 'CheckBox',
      params: { format: this.dateFormat },
      itemTemplate: (e) => {
        if (e.LeftDate)
          return <span>{getDisplayDateOnly(new Date(e.LeftDate))}</span>
        else
          return <span></span>
      }
    }

    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
    this.view_prod_allocation = false;
    this.view_cost_sharing = false;
    this.view_edit = false;
    this.view_termination = false;
    this.view_absconding = false;
    this.items = [
      {
        text: 'Edit',
        iconCss: 'e-icons e-edit'
      },
      {
        text: 'Edit Other Calendar Access',
        iconCss: 'e-icons e-edit'
      },
      {
        text: 'Manage OKR Lock',
        iconCss: 'e-icons e-lock'
      },
    ];
    this.moveToActive = [
      {
        text: 'Move to active',
        iconCss: 'e-icons e-undo'
      },
    ];
    this.onComplete = this.onComplete.bind(this);
    this.onBegin = this.onBegin.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
  }

  gridInstance;

  updateDimensions = () => {
    let activeGridHeight = window.innerHeight - 307; //255
    this.setState({ gridHeight: activeGridHeight });
  }
  getMasterData = () => {
    const { authUser } = this.props;
    const getMasterData = Promise.resolve(
      orgChatMaster.getMasterData("All", authUser)
    );
    getMasterData.then((resultData) => {
      if (resultData !== null) {
        const jobTitleData = resultData.filter((item) => {
          return item.Type === "Job Title";
        });
        const addressCountryData = resultData.filter((item) => {
          return item.Type === "BasedInCountry";
        });
        this.props.updateCandidateData({
          jobTitleData: jobTitleData,
          addressCountryMaster: addressCountryData
        })
      }
    });
  }
  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.getMasterData();
    this.getAllMasterData();
    this.getReportingMaster();
  };
  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);
          if (ele[index].innerText === EMPLOYEE_LIST_ACTION) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 500);
  }
  componentWillReceiveProps(newProps) {
    if (newProps.orgchartupdateStatus === "update") {
      this.setState({ editEmployeeData: false });
      this.props.setOrhChartDetaila({ orgchartupdateStatus: "" });
    }
  }
  getReportingMaster = () => {
    const { authUser } = this.props;
    const { reportingHeadMaster } = this.state
    if (reportingHeadMaster.length === 0) {
      const reportingHeadMasterRes = Promise.resolve(orgChatMaster.getMasterData("ORH", authUser, null));
      reportingHeadMasterRes.then((data) => {
        if (data !== null) {
          this.setState({ reportingHeadMaster: data });
        }
      })
    }
  }
  getAllMasterData = () => {
    this.setState({
      allDataloaded: true,
    });
  }


  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; padding-left:18px !important"
    );
  };
  /**
   * @description navigate to Productive allocation page
   */
  navigateToProductiveAllocationScreens = (args) => {
    try {
      localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
      this.props.addUpdateObjectveKpi({ okrreloadStatus: 'updated', employeeId: args.EmployeeId })
      localStorage.setItem("meeting_category", OKR_PRODUCTIVE_ALLOCATION);
      const path = `/okr?empId=${encrypt(args.EmployeeId)}`;
      this.setState({
        redirect: path,
        redirestCategary: OKR_PRODUCTIVE_ALLOCATION
      });

    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };
  /**
   * @author Vivek Khodadde
   * @description navigate to Productive allocation page
   */
  navigateToProductiveAllocationScreens = (args) => {
    try {
      localStorage.setItem('last_meeting_category', decodeString(localStorage.getItem('meeting_category')))
      this.props.addUpdateObjectveKpi({ reloadStatus: 'updated', employeeId: args.EmployeeId })
      localStorage.setItem("meeting_category", encodeString(OKR_PRODUCTIVE_ALLOCATION));
      // this.props.setMeetingCategory(TERMINATION)
      const path = `/okr?empId=${encrypt(args.EmployeeId)}`;
      this.setState({
        redirect: path,
        redirestCategary: OKR_PRODUCTIVE_ALLOCATION
      }
        // ,()=>window.location.reload()
      );
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };
  /**
   * @author Vivek Khodadde
   * @description navigate to employee page
   */
  navigateToSeparationScreens = (args, type) => {
    try {
      if (type === 'Terminate') {
        localStorage.setItem('last_meeting_category', decodeString(localStorage.getItem("meeting_category")))
        this.props.addUpdateSeparationDetails({ reloadStatus: 'updated', empid: args.EmployeeId })
        localStorage.setItem("meeting_category", encodeString(TERMINATION));
        // this.props.setMeetingCategory(TERMINATION)
        const path = `/separation?empId=${encrypt(args.EmployeeId)}`;
        this.setState({
          redirect: path,
          redirestCategary: TERMINATION
        }
          // ,()=>window.location.reload()
        );
      } else {
        localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
        localStorage.setItem("meeting_category", encodeString(ABSCONDING));
        const path = `/separation?empId=${encrypt(args.EmployeeId)}`;
        this.setState({ redirect: path, redirestCategary: ABSCONDING });
        this.props.addUpdateSeparationDetails({ reloadStatus: 'updated', empid: args.EmployeeId })
        // this.props.setMeetingCategory(ABSCONDING)
        // ,()=>window.location.reload()
        // );
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };
  openMenuBar = (event) => {
    this.setState({ openMenuDialog: true });
  };

  closeMenuBar = () => {
    this.setState({ openMenuDialog: false });
  };

  openOtherCalendarAccessPopup = (args) => {
    this.setState({ openOtherCalendarAccessPopup: true, employeeName: args.EmployeeName, empId: args.EmployeeId });
  }

  closeOtherCalendarPopup = () => {
    this.setState({ OtherCalendarAccessData: [], openOtherCalendarAccessPopup: false, employeeName: "" });
  }

  openOKRLockPopup = (args) => {
    this.setState({ openOKRLockPopup: true, employeeName: args.EmployeeName, empId: args.EmployeeId });
  }

  closeOKRLockPopup = () => {
    this.setState({ openOKRLockPopup: false, employeeName: "" });
  }

  actionTemplate = (props) => {
    if (this.props.empListType === "leftEmployee") {
      const onSelect = (args) => {
        if (args.item.text === 'Move to active') {
          this.setState({ openDialog: true, empId: props.EmployeeId });
        }
      }
      return (
        <div className="col-12" style={{
          textAlign: "center",
          padding: '0px 20px 0px 0px'
        }}
        >
          <div className="row1">
            <DropDownButtonComponent items={this.moveToActive} select={onSelect} iconCss='e-icons e-more-vertical-2' cssClass='e-caret-hide e-custom e-vertical' iconPosition='Top'></DropDownButtonComponent>
          </div>
        </div>
      );
    } else {
      const onSelect = (args) => {
        if (args.item.text === 'Edit') {
          this.editEmployeeDetailPopup(props);
        } else if (args.item.text === 'Cost Sharing') {
          this.openCostSharingPopup(props);
        } else if (args.item.text === 'OKR Add / Edit / View') {
          this.navigateToProductiveAllocationScreens(props);
        } else if (args.item.text === 'Absconding') {
          this.navigateToSeparationScreens(props, 'Abscond');
        } else if (args.item.text === 'Termination') {
          this.navigateToSeparationScreens(props, 'Terminate');
        }
        else if (args.item.text === "Edit Other Calendar Access") {
          this.openOtherCalendarAccessPopup(props);
        } else if (args.item.text === "Manage OKR Lock") {
          this.openOKRLockPopup(props);
        }

      }
      return (
        <div className="col-12" style={{
          textAlign: "center",
          padding: '0px 20px 0px 0px'
        }}
        >
          <div className="row1">
            <DropDownButtonComponent items={this.items} select={onSelect} iconCss='e-icons e-more-vertical-2' cssClass='e-caret-hide e-custom e-vertical' iconPosition='Top'></DropDownButtonComponent>
          </div>
        </div>
      );
    }
  };


  openCostSharingPopup = (props) => {
    this.getcostSharingData(props)
    this.setState({ openCostSharing: true, updateCostSharingData: props });
    this.props.setOrhChartDetaila({ employeeId: props.EmployeeId });
  }
  getcostSharingData = async (props) => {
    const { authUser } = this.props;
    const data = {
      costSharing: [{ reportingHead: 1, sharingCost: 100, id: 1 }],
      employeeId: props.EmployeeId,
      authUser: authUser,
      type: "Get"
    }
    const getMasterData = await orgChatMaster.getCostSharingList(data);

    // getMasterData.then((resultData) => {
    if (getMasterData.result.length > 0) {
      await this.setState({ getCostSharing: getMasterData.result, costSharingDataExists: true });
    } else {
      await this.setState({ getCostSharing: [{ reportingHead: '', sharingCost: "", id: 1 }], costSharingDataExists: false });
    }
    // });
  }
  closeCostSharingPopup = () => {
    this.setState({ openCostSharing: false, getCostSharing: [] });
    this.props.setOrhChartDetaila({ costSharing: [], employeeId: 0 });
  }
  editEmployeeDetailPopup = (props) => {
    
    this.setState({ editEmployeeData: true, employeeUpdateData: props });
    this.openEmployeeDetailPopup(props);
  }
  openEmployeeDetailPopup = async (props) => {    
    const { EmployeeId, serviceDevisionId } = props;
    try {//NOSONAR
      const getMasterDataList = Promise.resolve(
        orgChatMaster.getMasterData("ORGMASTER", EmployeeId, serviceDevisionId)
      );
      getMasterDataList.then((resultData) => {
        if (resultData !== null) {
          let groupSalesManagerData = resultData.filter(item => { return item.Type === 'GM_SM'; })
          let projectManagerSalesPersonData = resultData.filter(item => { return item.Type === 'PM_SP'; })
          let projectIndividualInchargeData = resultData.filter(item => { return item.Type === 'PIC'; })
          this.props.setOrhChartDetaila({
            lavel6Head: groupSalesManagerData,
            lavel7Head: projectManagerSalesPersonData,
            lavel8Head: projectIndividualInchargeData,
          });
        } else {
          console.log("error resultData");
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }
  closeEditEmployeePopup = () => {
    this.setState({ editEmployeeData: false });
  }
  addEditCostSharing = () => {
    const { costSharing, employeeId, authUser } = this.props;
    const { costSharingDataExists } = this.state;
    let totalcostSharing = 0;
    let cnt = 0;
    document.getElementById('allErrMsg').innerHTML = ""
    // eslint-disable-next-line
    costSharing.map((data, index) => {
      totalcostSharing += parseFloat(data.sharingCost);
      if (data.sharingCost === "" || data.reportingHead === "" || data.reportingHead === 0) {
        cnt += 1;
      }
    })
    if (cnt > 0) {
      document.getElementById('allErrMsg').innerHTML = "Please enter all required details";
      return;
    }
    if (totalcostSharing > 100) {
      document.getElementById('allErrMsg').innerHTML = "Cost sharing should not be greater or lesser then 100 %";
      return;
    }
    if (totalcostSharing < 100 && costSharing.length >= 1) {
      document.getElementById('allErrMsg').innerHTML = "Cost sharing should not be greater or lesser then 100 %";
      return;
    }
    if (totalcostSharing === 'NaN') {/**NOSONAR */
      document.getElementById('allErrMsg').innerHTML = "Cost sharing should not be greater or lesser then 100 %";
      return;
    }

    if (costSharingDataExists || costSharing.length > 0) {
      const data = {
        costSharing: costSharing,
        employeeId: employeeId,
        authUser: authUser,
        type: "Add"
      }
      this.props.addEditCostSharingAction({ data });
    }
    this.closeCostSharingPopup();
  }
  navigateToAddBasicInformation = async (args) => {
    try {
      if ((dencrypt(localStorage.getItem("editEmployeeAccess")) === 1) && args.EmployeeId > 0) {
        const path = `/onboard?empId=${encrypt(args.EmployeeId)}&id=${encrypt(EMPLOYEE_PROFILE)}&sync=true`;
        return window.open(path, '_blank')
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  }
  employeeNameTemplate(args) {
    return (
      <EmployeeNameTemplate
        props={args}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
        type={"Emp"}
        empListType={this.props.empListType === "upcomingEmployee"}
      />
    );
  }
  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
    if (this.gridInstance !== null)
      this.gridInstance.hideSpinner()
  }
  onLoad(args) {
    const { empListType } = this.props
    document
      .getElementById(`overviewgrid${empListType}`)
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById(`overviewgrid${empListType}`)
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }
  onBegin(args) {
    this.setState({ showLoading: true })
    if (this.gridInstance !== null)
      this.gridInstance.hideSpinner()
  }
  onComplete(args) {
    this.setState({ showLoading: false })
    if (this.gridInstance !== null)
      this.gridInstance.hideSpinner()
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
    if (args.requestType === 'filtering') {
      const { empListType } = this.props;
      if (empListType === "employeeList") {
        document.getElementById("employeeListClearAllFilters").firstChild.classList.add('e-filter-clear-red');
      } else if (empListType === "leftEmployee") {
        document.getElementById("leftEmployeeClearAllFilters").firstChild.classList.add('e-filter-clear-red');
      } else if (empListType === "upcomingEmployee") {
        document.getElementById("upcomingEmployeeClearAllFilters").firstChild.classList.add('e-filter-clear-red');
      }
    }
  }
  baselocationTemp = (props) => {
    const { baseLocation } = props;
    return (
      <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content={baseLocation} cssClass='customtooltip'>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: "14px" }}>{baseLocation}</div>
      </TooltipComponent>
    );
  }
  roleTemp = (props) => {
    const { titleRole } = props;
    return (
      <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content={titleRole} cssClass='customtooltip'>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: "14px" }}>{titleRole}</div>
      </TooltipComponent>
    );
  }
  level1ActionRole = (props) => {
    const { groupHeadRoleAs, groupHead } = props;
    return (
      <ActingActualStatus actingActualstatus={groupHeadRoleAs} groupHeadName={groupHead} />
    );
  }
  level2ActionRole = (props) => {
    const { bussUnitFuncHeadRoleAs, busssUnitFuncHeadName } = props;
    return (
      <ActingActualStatus actingActualstatus={bussUnitFuncHeadRoleAs} groupHeadName={busssUnitFuncHeadName} />
    );
  }
  level3ActionRole = (props) => {
    const { opsSalesCentralHeadRoleAs, opsSalesCentralHeadName } = props;
    return (
      <ActingActualStatus actingActualstatus={opsSalesCentralHeadRoleAs} groupHeadName={opsSalesCentralHeadName} />
    );
  }
  level4ActionRole = (props) => {
    const { subPnlFunctHeadRoleAs, subPnlFunctHeadName } = props;
    return (
      <ActingActualStatus actingActualstatus={subPnlFunctHeadRoleAs} groupHeadName={subPnlFunctHeadName} />
    );
  }
  level5ActionRole = (props) => {
    const { sDPASUHeadRoleAs, sDPASUHeadName } = props;
    return (
      <ActingActualStatus actingActualstatus={sDPASUHeadRoleAs} groupHeadName={sDPASUHeadName} />
    );
  }
  level6ActionRole = (props) => {
    const { gMSMUserRoleAs, gMSMUserName } = props;
    return (
      <ActingActualStatus actingActualstatus={gMSMUserRoleAs} groupHeadName={gMSMUserName} />
    );
  }
  level7ActionRole = (props) => {
    const { pMSPUserRoleAs, pMSPUserName } = props;
    return (
      <ActingActualStatus actingActualstatus={pMSPUserRoleAs} groupHeadName={pMSPUserName} />
    );
  }
  level8ActionRole = (props) => {
    const { pICUserRoleAs, pICUserName } = props;
    return (
      <ActingActualStatus actingActualstatus={pICUserRoleAs} groupHeadName={pICUserName} />
    );
  }
  level9ActionRole = (props) => {
    const { level9UserRoleAs, level9UserName } = props;
    return (
      <ActingActualStatus actingActualstatus={level9UserRoleAs} groupHeadName={level9UserName} />
    );
  }
  dateOfJoining = (props) => {
    const { JoiningDate } = props;
    return (
      <AllDateFormat date={JoiningDate} />
    );
  }
  dateOfBirth = (props) => {
    const { DateofBirth } = props;
    return (
      // new Date(DateofBirth)
      <AllDateFormat date={DateofBirth} />
    );
  }
  tenureEndDate = (props) => {
    const { TenureEndDate } = props;
    return (
      <AllDateFormat date={TenureEndDate === "1970-01-01T00:00:00.000Z" ? null : TenureEndDate} />
    );
  }
  leftDate = (props) => {
    const { LeftDate } = props;
    return (
      <AllDateFormat date={LeftDate === "1970-01-01T00:00:00.000Z" ? null : LeftDate} />
    );
  }
  offeredDate = (props) => {
    const { OfferedDate } = props;
    return (
      <AllDateFormat date={OfferedDate} />
    );
  }
  offerAcceptanceDate = (props) => {
    const { OfferAcceptanceDate } = props;
    return (
      <AllDateFormat date={OfferAcceptanceDate} />
    );
  }
  level9 = (props) => {
    const { level9 } = props;
    if (level9 === "") {
      return (
        <span>{`Individual Incharge`}</span>
      );
    } else {
      return (
        <span>{level9}</span>
      );
    }
  }
  valueAccess = (field, data, column) => {
    return getDisplayDateOnly(new Date(data[field]));
  }

  dateExcelReturn(dt) {
    if (dt)
      return getExcelDate(new Date(dt))
    else
      return ""
  }

  excelHeaderQueryCellInfo(args) {
    try {
      args.style = { fontName: 'Arial', bold: true }
      if (args.cell.value === EMPLOYEE_LIST_ACTION)
        args.gridCell.visible = false;
    }
    catch (error) {
      console.log(error);
    }
  }

  exportQueryCellInfo(args) {

    try {
      args.style = { fontName: 'Arial', fontSize: '10.5' }
      switch (args.column.headerText) {
        case DATE_OF_JOINING:
          args.value = args.data.JoiningDate ? new Date(args.data.JoiningDate) : null;
          args.column.type = "Date"
          break;
        case TENURE_END_DATE:
          args.value = args.data.TenureEndDate ? new Date(args.data.TenureEndDate) : null;
          args.column.type = "Date"
          break;
        case DATE_OF_BIRTH:
          args.value = args.data.DateofBirth ? new Date(args.data.DateofBirth) : null;
          args.column.type = "Date"
          break;
        case LEFT_DATE:
          args.value = args.data.LeftDate ? new Date(args.data.LeftDate) : null;
          args.column.type = "Date"
          break;
        case OFFERED_DATE:
          args.value = args.data.OfferedDate ? new Date(args.data.OfferedDate) : null;
          args.column.type = "Date"
          break;
        case OFFER_ACCEPTANCE_DATE:
          args.value = args.data.OfferAcceptanceDate ? new Date(args.data.OfferAcceptanceDate) : null;
          args.column.type = "Date"
          break;
        case EMPLOYEE_LIST_ACTION:
          args.column.visible = false;
          break;
        default:
      }
    } catch (error) {
      console.log(error);
    }
  }

  toolbarClick(args) {
    const { empListType } = this.props;
    if (this.gridInstance && (args.item.text === 'Excel Export')) {
      // hide the particular columns before exporting
      if ((dencrypt(localStorage.getItem("editEmployeeAccess")) === 1) && (this.gridInstance.getColumnByField(EMPLOYEE_LIST_ACTION.toLowerCase()) !== null) && (empListType !== "upcomingEmployee")) {
        this.gridInstance.getColumnByField(EMPLOYEE_LIST_ACTION.toLowerCase()).visible = false;
      }
      const formattedDate = format(new Date(), 'yyMMdd')
      const excelExportProperties = {
        fileName: `${formattedDate}_${empListType}_export.xlsx`
      };
      this.gridInstance.excelExport(excelExportProperties);
    }
    if (args.item.text === 'Clear Filters') {
      // hide the particular columns before exporting
      this.gridInstance.clearFiltering();
      if (empListType === "employeeList") {
        document.getElementById("employeeListClearAllFilters").firstChild.classList.remove('e-filter-clear-red');
      } else if (empListType === "leftEmployee") {
        document.getElementById("leftEmployeeClearAllFilters").firstChild.classList.remove('e-filter-clear-red');
      } else if (empListType === "upcomingEmployee") {
        document.getElementById("upcomingEmployeeClearAllFilters").firstChild.classList.remove('e-filter-clear-red');
      }
    }
  }
  excelExportComplete() {
    const { empListType } = this.props;
    if (this.gridInstance) {
      // show the particular columns after exporting
      if ((dencrypt(localStorage.getItem("editEmployeeAccess")) === 1) && (this.gridInstance.getColumnByField(EMPLOYEE_LIST_ACTION.toLowerCase()) !== null) && (empListType !== "upcomingEmployee")) {
        this.gridInstance.getColumnByField(EMPLOYEE_LIST_ACTION.toLowerCase()).visible = true;
      }
    }
  }

  addEditCalendarAccessApi = (data2) => {
    this.props.addEditCalendarAccess(data2);
  }

  closeDialog = () => {
    this.setState({ openDialog: false });
  }

  moveLeftEmployee = async () => {
    try {
      const payload = {
        userId: this.props.authUser,
        empId: this.state.empId
      }
      this.props.moveLeftEmployeeToActiveAction(payload)
      this.props.getLeftEmpList(this.state.empId);
      setTimeout(async () => {
        let empData = await syncUserLocal(this.state.empId, this.props.authUser, 1);
        if (empData.hdUserId > 0) {
          let data = {
            userId: empData.hdUserId,
            email: empData.user_email,
            firstName: empData.user_first_name,
            lastName: empData.user_last_name,
            disabled: (empData.user_status === "active" || empData.user_status === "Active") ? false : true
          }
          await this.props.updateHelpDeskUserInfo(data);
        }
        await this.props.syncUser(empData);
      }, 3000)
      this.setState({ openDialog: false });
    } catch (error) {
    }
  }

  render() {/**NOSONAR */
    const styleSheet = `.e-pagerdropdown  {
    display: inline-block;
    height: 32px;
    margin-left: 12px;
    margin-right: 0;
    margin-top: 9px !important;
    overflow: hidden;
    vertical-align: middle;
    width: 90px;
    position: absolute;
    right: 15%;
}
.e-pagerconstant  {
  display: inline-block;
  height: 32px;
  margin-left: 12px;
  margin-right: 0px;
  margin-top: 14px !important;
  overflow: hidden;
  vertical-align: middle;
  width: 90px;
  position: absolute;
  right: 10%;
}
.e-input-group e-control-wrapper e-alldrop e-ddl e-lib e-keyboard e-valid-input {
  padding-left:40px ! important;
  width:100% ! important;
}
.e-grid .e-frozenheader > .e-table, .e-grid .e-frozencontent > .e-table, .e-grid .e-frozencontent .e-virtualtable > .e-table, .e-grid .e-frozenheader .e-virtualtable > .e-table {
  border-right-color: #222222 !important;
}
#overviewgridemployeeList .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader {
  height: 59px !important;
}
#overviewgridemployeeList .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader .e-headercell.e-centeralign .e-headercelldiv .e-headertext {
  font-size: .94rem;
}
#overviewgridleftEmployee .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader {
  height: 59px !important;
}
#overviewgridleftEmployee .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader .e-headercell.e-centeralign .e-headercelldiv .e-headertext {
  font-size: .94rem;
}
#overviewgridupcomingEmployee .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader {
  height: 59px !important;
}
#overviewgridupcomingEmployee .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader .e-headercell.e-centeralign .e-headercelldiv .e-headertext {
  font-size: .94rem;
}
`
    const { redirect, redirestCategary, gridHeight, editEmployeeData, employeeUpdateData, openCostSharing, openOtherCalendarAccessPopup, employeeName,
      updateCostSharingData, reportingHeadMaster, getCostSharing, showLoading, openDialog, openOKRLockPopup, empId } = this.state;
    const { othersCheckAvailablity, employeeData, empListType } = this.props
    if (redirect !== null && redirestCategary !== EMPLOYEE_PROFILE) {
      this.props.setMeetingCategory(redirestCategary)
      return <Redirect to={redirect} />;
    }
    var widthColumnConfirm = 0;
    var finalWidthConfirm;
    var peopleWidthColumnConfirm = 0;
    var peopleFinalWidthConfirm;
    // eslint-disable-next-line
    getGridColumn(empListType).forEach((data) => {
      widthColumnConfirm = widthColumnConfirm + 20 + data.width;
    });
    if (widthColumnConfirm <= this.width) {
      finalWidthConfirm = widthColumnConfirm;
    } else {
      finalWidthConfirm = this.width
    }
    getGridColumn("nonPeopleFunction").forEach((data) => {
      peopleWidthColumnConfirm = peopleWidthColumnConfirm + 20 + data.width;
    });
    if (peopleWidthColumnConfirm <= this.width) {
      peopleFinalWidthConfirm = peopleWidthColumnConfirm;
    } else {
      peopleFinalWidthConfirm = this.width
    }
    this.setHeaderCss();
    return (
      <div className='employee-list-manager-hr-grid-height'>
        <style>{styleSheet}</style>
        <div>
          {
            // The date piker user for get date month wise data in the grid
          }
          {/* <div style={{ width: '200px',padding: '10px 0px 8px 0px'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker style={{ width: '100%' }}
                openTo="month"
                views={["year", "month"]}
                id="activeEmpDate"
                name="activeEmpDate"
                format="MMM-yyyy"
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                value={this.selectedDate !== null ? new Date(this.selectedDate) : new Date()}
                onChange={(date) =>this.getMonthWiseData(date)}
                TextFieldComponent={this.TextFieldComponent}
                inputVariant="outlined"
                variant="inline"
                autoOk
                maxDate={new Date()}
                label="Org Chart Data Month *"
              />
            </MuiPickersUtilsProvider>
          </div> */}
          {
            employeeData.length > 0 ?
              <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
                <div>
                  <div style={{ paddingTop: "5px" }}
                    className="control-section"
                  >
                    <GridComponent
                      className="grid-head-custome"
                      id={`overviewgrid${empListType}`}
                      allowPaging
                      allowFiltering
                      filterSettings={this.Filter}
                      rowHeight={58}
                      height={gridHeight}
                      ref={(g) => {
                        this.gridInstance = g;
                      }}
                      actionComplete={this.onComplete}
                      actionBegin={this.onBegin}
                      load={this.onLoad}
                      dataBound={this.onDataBound}
                      queryCellInfo={this.onQueryCellInfoPreJoining}
                      pageSettings={{
                        // pageSizes: NoOfRowsPerPage,
                        pageCount: 5,
                        pageSize: (window.innerHeight ||
                          document.documentElement.clientHeight ||
                          document.body.clientHeight) > 767
                          ? 10 //12
                          : 7,
                      }}

                      dataSource={employeeData}
                      frozenColumns={(dencrypt(localStorage.getItem("editEmployeeAccess")) === 1) ? 3 : 2 /**NOSONAR */}
                      enableHover={false}
                      allowSorting={true}
                      allowTextWrap={true}
                      toolbar={this.toolbarOptions}
                      allowExcelExport={true}
                      toolbarClick={this.toolbarClick.bind(this)}
                      excelExportComplete={this.excelExportComplete.bind(this)}
                      excelQueryCellInfo={this.exportQueryCellInfo.bind(this)}
                      excelHeaderQueryCellInfo={this.excelHeaderQueryCellInfo.bind(this)}
                      width={(dencrypt(localStorage.getItem("editEmployeeAccess")) === 1) ? `${finalWidthConfirm}` : `${peopleFinalWidthConfirm}` /**NOSONAR */}

                    >
                      {/** NOSONAR */ (dencrypt(localStorage.getItem("editEmployeeAccess")) === 1) ? (<ColumnsDirective>
                        {getGridColumn(empListType).map((item) => {
                          switch (item.colName) {
                            case EMPLOYEE_LIST_EMPLOYEE_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.employeeNameTemplate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                  freeze='Left'
                                />
                              );
                            case EMPLOYEE_LIST_ACTION:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.actionTemplate}
                                  filter={false}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  allowFiltering={false}
                                  freeze='Left'

                                />
                              );
                            case EMPLOYEE_CODE: /**NOSONAR */
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                  freeze='Left'
                                />
                              );

                            case LEVEL_1_TEAM_MEMBER_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level1ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case BASE_LOCATION1:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.baselocationTemp}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case REVISED_ROLE_TITLE:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.roleTemp}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case BUSINESS_UNIT_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level2ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case OPS_SALE_CENTRAL_PNL_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level3ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case OPS_SUB_SALE_PNL_FUNCTION_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level4ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                  type={"string"}
                                />
                              );
                            case SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level5ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}

                                />
                              );
                            case GROUP_SALE_MANAGER_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level6ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case PROJECT_MANAGER_SALES_PERSON_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level7ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case LEVEL_9:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level9}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case LEVEL8_TEAM_MEMBER_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level8ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case LEVEL9_TEAM_MEMBER_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level9ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case DATE_OF_JOINING:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.dateOfJoining}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  // filter={this.joiningDateFilterValue}
                                  // type={"datetime"}
                                  // filter={this.Filter}
                                  type="date"
                                  format="dd-MMM-yyyy"
                                  filter={{ params: { format: 'dd-MM-yyyy' } }}
                                // filter={{ type: "CheckBox" }}
                                />
                              );
                            case DATE_OF_BIRTH:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.dateOfBirth}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  // filter={this.dateOfBirthFilterValue}
                                  type="date"
                                  format="dd-MMM-yyyy"
                                  filter={{ params: { format: 'dd-MM-yyyy' } }}
                                />
                              );
                            case TENURE_END_DATE:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.tenureEndDate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  // filter={this.tenureEndDateFilterValue}
                                  type="date"
                                  format="dd-MMM-yyyy"
                                  filter={{ params: { format: 'dd-MM-yyyy' } }}
                                // filter={{ type: "CheckBox" }}
                                />
                              );
                            case LEFT_DATE:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.leftDate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  // filter={this.leftDateFilterValue}
                                  type="date"
                                  format="dd-MMM-yyyy"
                                  filter={{ params: { format: 'dd-MM-yyyy' } }}
                                // filter={{ type: "CheckBox" }}
                                />
                              );
                            case OFFERED_DATE:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.offeredDate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  // filter={this.leftDateFilterValue}
                                  type="date"
                                  format="dd-MMM-yyyy"
                                  filter={{ params: { format: 'dd-MM-yyyy' } }}
                                // filter={{ type: "CheckBox" }}
                                />
                              );
                            case OFFER_ACCEPTANCE_DATE:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.offerAcceptanceDate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  // filter={this.leftDateFilterValue}
                                  type="date"
                                  format="dd-MMM-yyyy"
                                  filter={{ params: { format: 'dd-MM-yyyy' } }}
                                // filter={{ type: "CheckBox" }}
                                />
                              );
                            default:
                              if (EMPLOYEE_LIST_ACTION === item.colName && !this.viewActionColumn) {
                                return null;
                              }
                              else {
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    textAlign={item.align}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                  />
                                );
                              }

                          }
                        })}
                      </ColumnsDirective>) : (
                        <ColumnsDirective>
                          {getGridColumn("nonPeopleFunction").map((item) => {
                            switch (item.colName) {
                              case EMPLOYEE_LIST_EMPLOYEE_NAME:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.employeeNameTemplate}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                    freeze='Left'
                                  />
                                );
                              case EMPLOYEE_CODE:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                    freeze='Left'
                                  />
                                );
                              case BASE_LOCATION1:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.baselocationTemp}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                  />
                                );
                              case REVISED_ROLE_TITLE:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.roleTemp}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                  />
                                );
                              case BUSINESS_UNIT_HEAD:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.level2ActionRole}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                  />
                                );
                              case OPS_SALE_CENTRAL_PNL_HEAD:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.level3ActionRole}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                  />
                                );
                              case OPS_SUB_SALE_PNL_FUNCTION_HEAD:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.level4ActionRole}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                    type={"string"}
                                  />
                                );
                              case SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.level5ActionRole}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                    type={"string"}
                                  />
                                );
                              case DATE_OF_JOINING:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.dateOfJoining}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    // filter={this.joiningDateFilterValue}
                                    // type={"Datetime"}
                                    // actualFilterValue={(e)=>(<AllDateFormat date={e.JoiningDate} />)}
                                    type="date"
                                    format="dd-MMM-yyyy"
                                    filter={{ params: { format: 'dd-MM-yyyy' } }}
                                  />
                                );
                              case DATE_OF_BIRTH:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.dateOfBirth}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    // filter={this.dateOfBirthFilterValue}
                                    type="date"
                                    format="dd-MMM-yyyy"
                                    filter={{ params: { format: 'dd-MM-yyyy' } }}
                                  />
                                );
                              case TENURE_END_DATE:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    template={this.tenureEndDate}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    // filter={this.tenureEndDateFilterValue}
                                    type="date"
                                    format="dd-MMM-yyyy"
                                    filter={{ params: { format: 'dd-MM-yyyy' } }}
                                  />
                                );
                              default:
                                return (
                                  <ColumnDirective
                                    key={item.field}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={this.check}
                                  />
                                );
                            }
                          })}
                        </ColumnsDirective>)}
                      <Inject services={[Page, DetailRow, Filter, Freeze, Toolbar, ExcelExport, Sort]} />
                    </GridComponent>
                  </div>
                </div>
              </div>
              :
              <div className="control-section col-md-12" style={{ height: 75 }}>
                {employeeData !== "error" && employeeData.length > 0 ?/**NOSONAR */ (
                  <Progressbar />
                ) : (
                  <ErrorMessage message="No Data Available" />
                )}
              </div>
          }
          {showLoading && <Progressbar />}
        </div>
        <div style={{ width: "350px" }}>
          <Dialog open={openDialog}>
            <DialogTitle> Move left team member </DialogTitle>
            <DialogContent>
              Are you sure you want to move left team member to active ?
            </DialogContent>
            <DialogActions>
              <Button id="confirm" onClick={() => this.moveLeftEmployee()}>
                {" "}
                Yes{" "}
              </Button>
              <Button onClick={this.closeDialog}> No </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div style={{ width: "350px" }}>
          <Dialog
            aria-labelledby="uploading-title"
            open={editEmployeeData}
            fullWidth
            // width="1000"
            maxWidth="lg"
          >
            <DialogTitleTMLC>
              <div className='col-lg-12' style={{ paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12 pt-3">Update | Team Member Id : {employeeUpdateData.EmpCode} | Team Member : {employeeUpdateData.FirstName}{" "}{employeeUpdateData.LastName}</div>
                <div style={{ float: "right", marginRight: "-5px" }}>
                  <IconButton style={{ color: "white", marginRight: "-1.4em" }} aria-label="close" onClick={this.closeEditEmployeePopup}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitleTMLC>
            <ErrorBoundary>
              <DialogContent>
                {
                  <EmployeeEditPopup employeeUpdateData={employeeUpdateData} getEmployeeData={this.props.getEmployeeData} closeEditEmployeePopup={this.closeEditEmployeePopup} />
                }
              </DialogContent>
            </ErrorBoundary>
          </Dialog>
        </div>
        <div style={{ width: "350px" }}>
          <Dialog
            open={openCostSharing}
            fullWidth
            maxWidth='sm'
          >
            <DialogTitleTMLC>
              <div className='col-lg-12' style={{ paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12">Add / Edit Cost Sharing % - {updateCostSharingData.EmployeeName}</div>
                <div style={{ float: "right", marginRight: "-5px" }}>
                  <div style={{ float: "right" }}>
                    <IconButton style={{ color: "white", marginRight: "-1.4em" }} aria-label="close" onClick={this.closeCostSharingPopup}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </DialogTitleTMLC>
            <ErrorBoundary>
              <DialogContent dividers>
                {
                  getCostSharing.length > 0 ?
                    <EmployeeCostSharing updateCostSharingData={updateCostSharingData}
                      reportingHeadMaster={reportingHeadMaster}
                      getCostSharing={this.state.getCostSharing} />
                    :
                    <div className='progress-bar-div'>
                      <Progressbar />
                    </div>
                }
              </DialogContent>
              <DialogActions style={{ paddingRight: "32px" }}>
                <Button color="default" variant="contained" onClick={() => this.closeCostSharingPopup()}> Cancel </Button>
                {
                  <Button color="primary" variant="contained" onClick={() => this.addEditCostSharing()} > Save </Button>

                }
              </DialogActions>
            </ErrorBoundary>
          </Dialog>
        </div>
        <div>
          <Dialog
            aria-labelledby="uploading-title"
            open={openOtherCalendarAccessPopup}
            fullWidth
            maxWidth="md"
            backgroundColor="#4B4B55"
          >
            <DialogTitleTMLC>
              <div className='col-lg-12' style={{ paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12 pt-3">Edit Others Calendar Access of {employeeName}</div>
                <div style={{ float: "right", marginRight: "-5px" }}>
                  <IconButton style={{ color: "white", marginRight: "-1.4em" }} aria-label="close" onClick={this.closeOtherCalendarPopup} >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitleTMLC>
            <DialogContent dividers className='heightOther'>

              <OtherCalendarAccessPopup employeeId={empId} employeeName={employeeName} addEditCalendarAccess={this.addEditCalendarAccessApi} othersCheckAvailablity={othersCheckAvailablity} />

            </DialogContent>
          </Dialog>
        </div>
        <div>
          <Dialog
            // aria-labelledby="uploading-title"
            open={openOKRLockPopup}
            maxWidth="sm"
            fullWidth
            backgroundColor="#4B4B55"
          >
            <DialogTitleTMLC>
              <div className='col-lg-12' style={{ paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12 pt-3">Manage OKR Lock and Does Not Apply</div>
                <div style={{ float: "right", marginRight: "-5px" }}>
                  <IconButton style={{ color: "white", marginRight: "-1.4em" }} aria-label="close" onClick={this.closeOKRLockPopup} >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitleTMLC>

            <LockOKRRatingPopup empId={empId} employeeName={employeeName} closeOKRLockPopup={this.closeOKRLockPopup} />
          </Dialog>
        </div>

      </div >
    );
  }
}
const mapStateToProps = ({ auth, orgchartreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { empCode, orgchartupdateStatus, employeeId, fistName, lastName, designation, titleRole, employmentType, entityId, serviceDevision,
    reportingSupervison, band, emailId, country, location, functiondata, costSharing, lavel6Acting, lavel7Acting, lavel8Acting, reportingActing,
    lavel6HeadId, lavel7HeadId, lavel8HeadId, joiningDate, leftDate, legalName } = orgchartreducer;
  const { othersCheckAvailablity, } = meetingmaster;
  return {
    empCode, authUser, orgchartupdateStatus, employeeId, fistName, lastName, designation, titleRole, employmentType, entityId, serviceDevision,
    reportingSupervison, band, emailId, country, location, functiondata, costSharing, lavel6Acting, lavel7Acting, lavel8Acting, reportingActing,
    lavel6HeadId, lavel7HeadId, lavel8HeadId, joiningDate, leftDate, legalName, othersCheckAvailablity
  };
};

export default connect(mapStateToProps, {
  setMeetingCategory, updateOrgChartDetailsAction, setOrhChartDetaila, addEditCostSharingAction,
  addUpdateSeparationDetails, addUpdateObjectveKpi, updateCandidateData, addEditCalendarAccess, syncUser,
  updateHelpDeskUserInfo
})(
  Employeelist
);
