import React, { Component } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import defaultTheme from "../../assets/themes/defaultTheme";
import {
  setInitUrl,
  userSignOut,
  changeAuthUser,
  updateUserInfo,
} from "../../redux/actions/auth";
import Default from "./default";
import LogIn from "./login";
import LP from "./lp";
import Route66 from "./route66";
import Logout from "../../components/logout/logout";
import SessionExpired from "../../components/logout/session_expired";
import {
  defEnvironment,
  commonAccessRightsFlag,
  defMeetingCategory,
  defNotificationSetInterval,
  urlConfig,
} from "../../config/config";
import Error404 from "../../components/error/error404";

import TIBRLayout from "./tibrlayout";
import PreOfferLayout from "./preofferlayout";
import Onboardinglayout from "./onboardinglayout";
import CandUplLayout from "./candupllayout";
import LeaveEmailApprove from "./lna/leaveemailapprove";
import ConfirmationLayout from "./confirmationlayout";
import OKRLayout from "./okrlayout";
import SeparationLayout from "./separationlayout/index";
import OrgChartLayout from "./orgchartlayout";
import HomeOfficeLayout from "./homeoffice";
import AdminLayout from "./admin";
import HelpDesk from "./helpdesk";
import LnAlayout from "./lna/lnalayout";
import OKRDashboardLayout from "./okrdashboardlayout";

import {
  SidebarComponent,
  MenuComponent,
} from "@syncfusion/ej2-react-navigations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faInfoCircle,
  faCog,
  faHandshake,
  faClipboardCheck
} from "@fortawesome/free-solid-svg-icons";
import { faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons';
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import { setMeetingMaster } from "../../redux/actions/meetingmasteraction";
import { addUpdateOkrDashboardRedux } from "../../redux/actions/okrdashboardaction/okrdashboardaction";
import {
  getDefaltSettingConfig,
  setModuleAttributeSettings,
} from "../../redux/actions/settingaction";
import { setMeetingCategory } from "../../redux/actions/meetingcategoryaction";
import { setMonths, addUpdateTibData } from "../../redux/actions/tibacton";
import { saveBasicInformationInRedux } from "../../redux/actions/entryexit/onboardingaction";
import {
  addUpdateObjectveKpi,
  setCentralOkrKPIRedux,
} from "../../redux/actions/okr/okractions";
import { updateCandidateData } from "../../redux/actions/entryexit/candidateaction";
import { updateLnAData } from "../../redux/actions/lna/lnaaction";
import { addUpdateOkrRatingRedux } from "../../redux/actions/okrrating/okrratingaction";
import dataService from "../../services/services";
import { Button } from "@material-ui/core";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import NotificationPopUp from "../../components/notification/notification";
import Legends from "../../components/legends/legends";
import TibrLegends from "../../components/legends/tibrlegends";
import OrgChartSideBar from "../../components/legends/orgchartsidebar";
import Setting from "../../components/settings/settings";
import Footer from "../../components/footer/footer";
import { addEditHomeOfficeDetails } from "../../redux/actions/homeoffice/homeofficeaction";
import {
  MY_MEETING,
  MEETING_SET_BY_ME,
  PLAN_MEETING,
  ACTUAL_MEETING,
  PLAN_VS_ACTUAL_MEETING,
  OTHERS_CALENDAR,
  OTHERS_CHECK_AVAILABILITY,
  PRE_OFFERE,
  RESIGNATIONMANAGERAPPROVAL,
  RESIGNATIONPNLAPPROVAL,
  HRAPPROVAL,
  RESIGNATION,
  ABSCONDING,
  TERMINATIONHR,
  EXITCLEARANCEGRID,
  TERMINATION,
  ABSCONDINGHR,
  EXIT_CLEARANCE_FUNCTION,
  ABSCONDINGMANAGER,
  EMPLOYEE_LIST,
  EXIT_CLEARANCE_MANAGER,
  TERMINATIONMANAGER,
  UPLOAD_DAILY_DATA,
  DOWNLOAD_USER_ACTIVITY_DATA,
  TIME_IN_BUSINESS,
  HOME_OFFICE,
  ORG_CHART,
  ORG_CHART_MASTERS,
  ORG_CHART_NEW,
  OKR_OBJECTIVE_KPI,
  OKR_PRODUCTIVE_ALLOCATION,
  OKR_COST_SHARING,
  CALENDAR_REPORT,
  OKR_SUPERVISOR_REVIEW,
  OKR_THIRD_PART_REVIEW,
  OKR_REVIEW,
  ON_BOARDING,
  EMPLOYEE_PROFILE,
  ON_BOARDING_FUNCTION,
  TIB_AVERAGE_REPORT,
  USER_ROLE_MAPPING,
  ROLE_MASTER,
  MODULE_MASTER,
  ORG_MASTER,
  ORG_MAPPING,
  OKR_SELF_REVIEW,
  OKR_SIDA,
  OKR_RATING_AND_REVIEW, // , LNALAYOUT
  UTILIZATION_OF_TIME_REPORT,
  HOLIDAY_LIST,
  ADMIN_MODULE,
  DASHBOARD,
  APPLYLEAVE,
  VIEWLEAVEBALACE,
  APPLYCOMPOFF,
  ALLAPPROVAL,
  APPROVELEAVE,
  APPROVESWIPE,
  LEAVETRANSFER,
  VIEWDAILY,
  VIEWMONTHLY,
  APPLYSWIPE,
  BUSINESS_RULE_ENGINE,
  CONNECT_LANDING_PAGE_APP,
  PRE_OFFER_APP,
  ON_BOARDING_APP,
  CONFIRMATIONS_APP,
  SEPARATION_APP,
  ON_BOARDING_NEW_APP,
  VENDOR_MANAGEMENT_APP,
  PURCHASE_ORDER_APP,
  MASTERS_APP,
  APPROVALS_APP,
  SPECIAL_ATTENDANCE,
  VIEWYEARLY,
  ATTENDANCEHRREPORT,
  TICKET_RESOLUTION,
  TICKET_REPORTS,
  TICKET_ADMIN_MODULE,
  NEWSERVICEREQUEST,
  NEWIDEA,
  MYTICKET,
  CANCELLEAVE,
  ONLEAVE,
} from "../../redux/constant/meetingcategoryconstant";
import {
  closeAgendaPopup,
  decodeString,
  dencrypt,
  encodeString,
  encrypt,
} from "../other/commonfunction";
import SocketIO from "../../components/socketIO/socketIO";
import RatingLayout from "./okrratinglayout/okrratinglayout";
import queryString from "query-string";
import { setOrhChartDetaila } from "../../redux/actions/entryexit/orgchartaction";
import {
  getEmpMasterData,
  getTimezoneMasterData,
  getLnAData,
} from "../other/commonlayoutfunction";
import md5 from "md5";
import { format } from "date-fns";
import {
  faScrewdriverWrench,
} from "@fortawesome/pro-solid-svg-icons";
import ConfirmationPopup from "../../utilities/confirmationpopup";
import { adLogout } from "../../redux/middleware/authmiddleware";

const buttoncss = `
.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 50px;
    box-sizing: border-box;
    text-align: capitalize;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

  
}
`;
const RestrictedRoute = ({ component: LayoutComponent, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <LayoutComponent {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.logintype = "User";
    this.mediaQuery = "(min-width: 600px)";
    document.title = "Connect";
    this.AccountMenuItem = [
      {
        text: decodeString(localStorage.getItem("user_nm")),
        items: [{ text: "Sign out", id: "sign01" }],
      },
    ];
    this.listtz = null;
    this.enableDock = true;
    this.dockSize = "52px";
    this.width = "220px";
    this.target = ".main-content";
    this.onSelect = this.onSelect.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.legendClick = this.legendClick.bind(this);
    this.countdown = null;
    this.counter = 0;
    this.state = {
      open: false,
      categoryName:
        typeof localStorage.getItem("meeting_category") === "undefined" ||
          decodeString(localStorage.getItem("meeting_category")) === ""
          ? defMeetingCategory
          : decodeString(localStorage.getItem("meeting_category")),
      appNotification: false,
      newFeature: false,
      stime: null,
      etime: null,
      temporaryNotification: null,
      notificationCount: 0,
      userHelp: false,
      userAccessLinks: [],
      redirectLink: "",
      showSuccessMessage: false,
      newAnnouncement: true,
      changedData: false,
      navEvent: null,
      navMessage: "",
      navTitle: "",
      navTabOpen: false,
      button2Caption: "No",
      button1Caption: "Yes",
      empCode: "",
      showOverView: true,
      moduleId: 0,
    };
    this.navFlag = false;
    this.showSidebarMenu = false;
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.hideSidebarInfoEvent = this.hideSidebarInfoEvent.bind(this);
    this.openClick = this.openClick.bind(this);
    this.getHelp = this.getHelp.bind(this);
    this.closeSetting = this.closeSetting.bind(this);
    this.onAppNotificationSelect = this.onAppNotificationSelect.bind(this);
    this.onFeatureSelect = this.onFeatureSelect.bind(this);
    this.getTicketUrlPath = this.getTicketUrlPath.bind(this);
    this.SDNM = "";
    this.props.setMeetingCategory(
      this.state.categoryName === null
        ? defMeetingCategory
        : this.state.categoryName
    );
    this.calendarModuleIds = [
      MY_MEETING,
      OTHERS_CALENDAR,
      MEETING_SET_BY_ME,
      PLAN_MEETING,
      ACTUAL_MEETING,
      PLAN_VS_ACTUAL_MEETING,
      OTHERS_CHECK_AVAILABILITY,
      CALENDAR_REPORT,
    ];
  }

  handleNotification = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  openClick() {
    // setTimeout(() =>
    //     this.sidebarobj.refresh(), 200
    if (this.showSidebarMenu) {
      this.sidebarobj.hide();
    } else {
      this.sidebarobj.show();
    }

    this.changeMenuIconSize();
    if (
      this.props.location.pathname.toUpperCase() === "/DEFAULT" &&
      this.sidebarInstance !== null
    )
      this.sidebarInstance.hide();
  }

  changeMenuIconSize = () => {
    this.showSidebarMenu = this.sidebarobj.isOpen;
    try {
      const menuImages = document.querySelectorAll("#menu-side-bar-icons");
      if (menuImages) {
        for (let each of menuImages) {
          each.style.fontSize = this.showSidebarMenu ? "16px" : "20px";
        }
      }
    } catch (err) { }
  };

  async componentDidMount() {
    // Calculate the width and height and update the control dimensions
    this.updateDimensions();
    /**nosonar**/
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.componentRefresh();
    try {
      this.updateModuleId();
    } catch (error) { }
    
  }

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    clearInterval(this.countdown);
    closeAgendaPopup();
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidUpdate(_prevProps, _prevState) {
    try {
      const { showMessage, authUser, location, history } = this.props;
      if (showMessage) {
        setTimeout(() => {
          this.props.hideMessage();
        }, 100);
      }
      if (authUser === null || isNaN(authUser)) {
        if (
          (defEnvironment === "LIVE" || defEnvironment === "UAT") &&
          location.pathname !== "/login" &&
          location.pathname !== "/candupl" &&
          location.pathname !== "/leaveApproval"
        ) {
          history.push("/logout");
        } else if (
          location.pathname !== "/login" &&
          location.pathname !== "/candupl" &&
          location.pathname !== "/leaveApproval"
        )
          history.push("/login");
      }
      if (_prevProps.location.search !== location.search) {
        this.updateModuleId();
      }
    } catch (error) { }
  }

  updateModuleId = () => {
    const { categoryName } = this.props;
    const params = queryString.parse(this.props.location.search);
    let moduleId = 0;
    if (params.id !== undefined) {
      moduleId = dencrypt(params.id).toString();
    } else {
      moduleId = categoryName;
    }
    this.setState({ moduleId: moduleId });
    return moduleId;
  };

  onSelect = async (e) => {
    try {
      if (e.item.properties.id === "sign01") {
        const adLogoutRes = await adLogout('Connect-landing-page')
        if (adLogoutRes.data) {
          this.props.userSignOut();
          this.props.setMeetingMaster({ executiveAssistant: null });
          this.props.setCentralOkrKPIRedux({ OkrMasters: [] });
          this.props.setMeetingCategory(defMeetingCategory);
          this.props.addUpdateTibData({ tibAvgSelectedYearOption: 1 });
        }
      }
    } catch (error) { }
  };
  handleConfirmClose = () => {
    this.setState({
      navTabOpen: false,
      navMessage: "",
      navTitle: "",
    });
  };
  handleNavYes = () => {
    this.navFlag = true;
    this.handleCategoryChange(this.state.navEvent);
    this.handleConfirmClose();
  };
  handleNavNo = () => {
    this.navFlag = false;
    this.handleCategoryChange(this.state.navEvent, false);
    this.handleConfirmClose();
  };
  handleCategoryChange = (e, navFlag) => {
    // NOSONAR
    // return;
    let moduleId = this.updateModuleId();
    if (e === "sidebarmenu") {
      this.sidebarobj.hide();
      this.changeMenuIconSize();
      return;
    }
    const {
      location,
      otherUser,
      categoryName,
      objectiveData,
      pendingForApprovalObjectiveData,
    } = this.props;
    if (categoryName === OKR_OBJECTIVE_KPI && this.navFlag === false) {
      let cnt = 0;
      // eslint-disable-next-line
      objectiveData.map((item, i) => {
        if (item.isDraft === "0") {
          document.getElementById(`addObjective${i}`).innerHTML =
            "Please save as a draft or submit the objective";
          cnt++;
        }
      });
      if (cnt > 0) {
        this.setState({
          navTabOpen: true,
          changeTab: true,
          navMessage:
            "You have not saved as a draft or submited the one or more objective details (Create & Draft Tab) , move to another page will loss the information.",
          navTitle: "Do you to still wish to move ?",
          navEvent: e,
        });
        return;
      }
      let pendcnt = 0;
      // eslint-disable-next-line
      pendingForApprovalObjectiveData.map((item, i) => {
        if (item.isDraft === "1") {
          document.getElementById(`editObjective${i}`).innerHTML =
            "Please approve or reject the objective";
          pendcnt++;
        }
      });
      if (pendcnt > 0) {
        this.setState({
          navTabOpen: true,
          changeTab: true,
          navMessage:
            "You have not approved or rejected one or more objective details (Pending For Approval Tab), move to another page will loss the information.",
          navTitle: "Do you to still wish to move ?",
          navEvent: e,
        });
        return;
      }
      this.props.setMeetingCategory(moduleId);
      this.setState({ categoryName: moduleId });
    }
    this.navFlag = false;
    if (typeof this.sidebarobj !== "undefined" && this.sidebarobj !== null) {
      this.sidebarobj.hide();
      this.changeMenuIconSize();
    }

    this.closeSetting();
    const param = queryString.parse(this.props.location.search);
    let candidateId = 0;
    if (param.candid !== undefined && dencrypt(param.candid) !== undefined) {
      candidateId = param.candid;
    }
    if (e.item.properties && e.item.properties.id !== "MainTitle") {
      if (moduleId === PRE_OFFERE && candidateId === 0) {
        this.props.saveBasicInformationInRedux({
          candidateid: 0,
          salutation: "",
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          mobileNumber: "",
          alternetNumber: "",
          emailId: "",
          panNumber: "",
          adharNumber: "",
          uanNumber: "",
          highestEducationQualification: "",
          highestEducationQualificationSpecial: "",
          workExperiance: 0,
          title: "",
          role: "",
          band: "",
          empType: "",
          baseCounty: "",
          baseLocation: "",
          entity: "",
          reportingSupervisor: "",
          costSharing: 0,
          address: "",
          send_links: [],
          imageName: "",
          servceDivisionSalesUnit: "",
          status: "Refresh",
          dateOfBirth: null,
          readyToJoinDate: null,
          tenureEndDate: null,
          roleName: "",
          address2: "",
          city: "",
          pincode: "",
          state: "",
          other: "",
          persCountryCode: "",
          altCountryCode: "",
          recruitmentManagerId: 0,
          functionId: "",
          sourceCategory: null,
          jobPortalId: null,
          referralId: null,
          agencyName: "",
          tctc: null,
          oti: null,
          fixedCTC: null,
          layerId: null,
          subLayerId: null,
          legalName: "",
          addressCountryId: null,
          currencyId: null,
          ctcFrequency: null,
          subFunction: null,
          docLinkForExternal: "Y",
        });
        this.props.setMeetingCategory(moduleId);
        this.setState({ categoryName: moduleId });
      } else {
        localStorage.setItem("meeting_category", encodeString(moduleId));
        setTimeout(() => {
          if (moduleId !== OTHERS_CALENDAR && otherUser !== 0) {
            this.props.changeAuthUser({ otherUser: 0, otherUserAccess: "" });
          }
          this.props.setMeetingCategory(moduleId);
          this.setState({ categoryName: moduleId });
          if (
            location.pathname.toUpperCase() === "/DEFAULT" &&
            this.sidebarInstance !== null &&
            typeof this.sidebarInstance !== 'undefined'
          )
            this.sidebarInstance.hide();
        }, 10);
      }
    }
  };
  onLeaveDetailsClick(args) {
    this.props.history.push(
      `/lna?id=${encrypt(APPLYLEAVE)}&appId=${encrypt(1)}`
    );
  }
  legendClick() {
    this.closeSetting();
    if (this.sidebarInstance !== null && typeof this.sidebarInstance !== 'undefined') {
      this.sidebarInstance.toggle();
    }
  }

  onAppNotificationSelect = () => {
    const { appNotification } = this.state;
    this.closeSetting();
    this.setState({
      appNotification: !appNotification,
    });
  };

  onFeatureSelect = () => {
    const { newFeature } = this.state;
    this.closeSetting();
    this.setState({
      newFeature: !newFeature,
    });
  };

  //  Author:- Prashant Waphare | open seeting Box
  // Change by:- Prashant Waphare | remove toggle open and close box on click on same function
  getHelp = () => {
    this.setState({ userHelp: true });
  };

  componentWillReceiveProps = async (newProps) => {
    if (
      this.sidebarInstance !== null &&
      this.sidebarInstance !== undefined &&
      this.props.location.pathname == "/orgchart"
    ) {
      if (newProps.openOrgChartSidebar) this.sidebarInstance.show();
      else this.sidebarInstance.hide();
    }

    if (newProps.meetingStatus === "updated") {
      let responseNotificationCount = await dataService.getMeetingNotifiction(
        this.props.authUser,
        "all"
      );
      this.setState({
        notificationCount: responseNotificationCount.result.length,
      });
    }
    if (this.props.authUser === null || isNaN(this.props.authUser)) {
      if (
        (defEnvironment === "LIVE" || defEnvironment === "UAT") &&
        this.props.location.pathname !== "/login"
      ) {
        this.setState({ redirectLink: "/logout" });
      } else if (this.props.location.pathname !== "/login")
        this.setState({ redirectLink: "/login" });
    }
  };

  getHeading = () => {
    // NOSONAR
    const { location } = this.props;
    const { moduleId } = this.state;
    closeAgendaPopup();
    let urlOrigin = window.location.origin;

    switch (location.pathname.toUpperCase()) {
      case "/TIB":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/TimeInBusiness.ico`);
        }, 0);
        if (moduleId === TIME_IN_BUSINESS) {
          document.title = "Connect - Time In Business";
          // eslint-disable-next-line
          return "Time In Business";
        } else if (moduleId === UPLOAD_DAILY_DATA) {
          document.title = "Time In Business - Attendance Data Management";
          // eslint-disable-next-line
          return "Time In Business - Attendance Data Management";
        } else if (moduleId === DOWNLOAD_USER_ACTIVITY_DATA) {
          document.title = "Time In Business - Download User Activity Report";
          // eslint-disable-next-line
          return "Time In Business - Download User Activity Report";
        } else if (moduleId === TIB_AVERAGE_REPORT) {
          document.title = "Time In Business - Average Report";
          // eslint-disable-next-line
          return "Time In Business - Average Report";
        } else if (moduleId === UTILIZATION_OF_TIME_REPORT) {
          document.title = "Utilization of Time in Business Report";
          // eslint-disable-next-line
          return "Utilization of Time in Business Report";
        }
        return "Time In Business";
      case "/DEFAULT":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/EnterpriseCalendar.ico`);
        }, 0);

        if (moduleId === MY_MEETING) {
          // eslint-disable-next-line
          return "Enterprise Calendar - My Meetings";
        }
        if (moduleId === OTHERS_CALENDAR) {
          // eslint-disable-next-line
          return "Enterprise Calendar - Others Calendar";
        }
        if (moduleId === MEETING_SET_BY_ME) {
          // eslint-disable-next-line
          return "Enterprise Calendar - Meeting Set By Me";
        }
        if (moduleId === PLAN_MEETING) {
          // eslint-disable-next-line
          return "Enterprise Calendar - Schedule Meetings";
        }
        if (moduleId === ACTUAL_MEETING) {
          // eslint-disable-next-line
          return "Enterprise Calendar - Actual Meetings";
        }
        if (moduleId === PLAN_VS_ACTUAL_MEETING) {
          // eslint-disable-next-line
          return "Enterprise Calendar - Schedule vs Actual Meetings";
        }
        if (moduleId === OTHERS_CHECK_AVAILABILITY) {
          // eslint-disable-next-line
          return "Enterprise Calendar - Check Availability";
        }
        if (moduleId === CALENDAR_REPORT) {
          document.title = "Calendar Report";
          // eslint-disable-next-line
          return "Calendar Report";
        }
        if (moduleId === HOLIDAY_LIST) {
          document.title = "Connect - Holiday List";
          return "Holiday List";
        }

        return "Enterprise Calendar";

      // eslint-disable-next-line
      case "/CONFIRMATION":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        document.title = "Connect - Confirmations";
        return "Confirmations";

      case "/ONBOARD":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        this.funSetDefault("");
        document.title = "Connect - Onboarding";
        if (moduleId === ON_BOARDING_FUNCTION && this.SDNM !== "") {
          return "Onboarding - " + this.SDNM;
        } else return "Onboarding";
      case "/LOGIN":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        document.title = "Connect - Login";
        return moduleId;
      case "/LOGOUT":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        document.title = "Connect - Logout";
        return moduleId;
      case "/PREOFFER":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/PreOffer.ico`);
        }, 0);
        // this.funSetDefault()
        const getTabActive = localStorage.getItem("SelectedTabAction");
        if (
          getTabActive !== null &&
          getTabActive !== "Pre Offer" &&
          this.props.candidateid > 0 &&
          this.props.docLinkForExternal === "Y"
        ) {
          document.title = "Connect - Onboarding";
          return "Onboarding - " + getTabActive;
        } else {
          document.title = "Connect - Pre Offer";
          return "Pre Offer";
        }
      case "/SEPARATION":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        if (moduleId === RESIGNATION) {
          document.title = "Separation - Resignation";
          return "Separation - Resignation";
        } else if (moduleId === RESIGNATIONMANAGERAPPROVAL) {
          document.title = "Separation - Supervisor Approval";
          return "Separation - Supervisor Approval";
        } else if (moduleId === RESIGNATIONPNLAPPROVAL) {
          document.title =
            "Separation - Business Unit / Group Function  Head Approval";
          return "Separation - Business Unit / Group Function  Head  Approval";
        } else if (moduleId === HRAPPROVAL) {
          document.title = "Separation - HR Approval";
          return "Separation - HR Approval";
        } else if (moduleId === ABSCONDING) {
          document.title = "Separation - Absconding";
          return "Separation - Absconding";
        } else if (moduleId === ABSCONDINGHR) {
          document.title = "Separation - Absconding HR Approval";
          return "Separation - Absconding HR Approval";
        } else if (moduleId === ABSCONDINGMANAGER) {
          document.title = "Separation - Absconding Supervisor Approval";
          return "Separation - Absconding Supervisor Approval";
        } else if (moduleId === TERMINATION) {
          document.title = "Separation - Termination";
          return "Separation - Termination";
        } else if (moduleId === TERMINATIONMANAGER) {
          document.title = "Separation - Termination Supervisor Approval";
          return "Separation - Termination Supervisor Approval";
        } else if (moduleId === TERMINATIONHR) {
          document.title = "Separation - Termination HR Approval";
          return "Separation - Termination HR Approval";
        } else if (moduleId === EXITCLEARANCEGRID) {
          document.title = "Separation - Exit Clearance";
          return "Separation - Exit Clearance";
        } else if (moduleId === EXIT_CLEARANCE_MANAGER) {
          document.title = "Separation - Supervisor Clearance";
          return "Separation - Supervisor Clearance";
        } else if (moduleId === EXIT_CLEARANCE_FUNCTION) {
          document.title = "Separation - " + this.SDNM + " Clearance";
          return "Separation - " + this.SDNM + " Clearance";
        } else {
          document.title = "Separation";
          return "Separation";
        }
      case "/ORGCHART":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/OrgChart.ico`);
        }, 0);
        if (moduleId === EMPLOYEE_LIST) {
          document.title = "Org-Chart - Team Member List";
          return "Org-Chart - Team Member List";
        } else if (moduleId === ORG_CHART || moduleId === ORG_CHART_NEW) {
          document.title = "Organization Chart";
          return "Organization Chart";
        } else if (moduleId === ORG_CHART_MASTERS) {
          document.title = "Organization Master";
          return "Organization Master ";
        }
        break;
      case "/HOMEOFFICE":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/HomeOffice.ico`);
        }, 0);
        if (moduleId === HOME_OFFICE)
          document.title =
            "Connect - Home Office Registration - Prerequisites Confirmation Form";
        return "Home Office Registration - Prerequisites Confirmation Form";
      case "/OKR":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/OKR.ico`);
        }, 0);
        this.funSetDefault("/OKR");
        const params = queryString.parse(this.props.location.search);
        if (typeof params.goto !== "undefined" && params.goto !== null) {
          if (params.goto === "library") {
            document.title = "OKR Library";
            return "OKR Library";
          }
        } else if (moduleId === OKR_OBJECTIVE_KPI) {
          document.title = "OKR Library";
          return "OKR Library";
        } else if (moduleId === OKR_PRODUCTIVE_ALLOCATION) {
          document.title = "OKR View / Add / Edit";
          return "OKR View / Add / Edit";
        } else if (moduleId === OKR_COST_SHARING) {
          document.title = "OKR - Cost Sharing";
          return "OKR - Cost Sharing";
        }
        break;
      case "/OKRREVIEW":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/OKR.ico`);
        }, 0);
        this.funSetDefault("/OKRREVIEW");
        if (moduleId === OKR_RATING_AND_REVIEW) {
          document.title = "OKR - Rating & Review";
          return "OKR - Rating & Review";
        } else if (moduleId === OKR_SELF_REVIEW) {
          document.title = "OKR - Self Rating";
          return "OKR - Self Rating";
        } else if (moduleId === OKR_SUPERVISOR_REVIEW) {
          document.title = "OKR - Supervisor Rating";
          return "OKR - Supervisor Rating";
        } else if (moduleId === OKR_THIRD_PART_REVIEW) {
          document.title = "OKR - Third Party Review";
          return "OKR - Third Party Review";
        } else if (moduleId === OKR_REVIEW) {
          document.title = "OKR - Review";
          return "OKR - Review";
        }
        break;
      case "/LP":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        return ".";
      case "/ROUTE66": //NOSONAR
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        return ".";
      case "/ADMIN":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/AdminSettings.ico`);
        }, 0);
        if (moduleId === USER_ROLE_MAPPING) {
          document.title = "Connect - User Role Mapping";
          return "Admin Setting - User Role Mapping";
        } else if (moduleId === ROLE_MASTER) {
          document.title = "Connect - Manage Master Roles";
          return "Admin Setting - Manage Master Roles";
        } else if (moduleId === MODULE_MASTER || moduleId === ORG_MASTER) {
          document.title = "Connect - Manage Master Modules";
          return "Admin Setting - Manage Master Modules";
        } else if (moduleId === ORG_MAPPING) {
          document.title = "Connect - Manage Master Mapping";
          return "Admin Setting - Master Modules";
        } else if (moduleId === ADMIN_MODULE) {
          document.title = "Connect - Admin Modules";
          return "Connect - Admin Modules";
        }
        break;
      case "/LNA":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/Leave&Attendance.ico`);
        }, 0);
        switch (moduleId) {
          case APPLYLEAVE:
          case CANCELLEAVE:
            document.title = "Connect - Leave Application";
            return "Leave And Attendance";
          case APPLYSWIPE:
            document.title = "Connect -  Regularization Application";
            return "Leave And Attendance";
          case APPLYCOMPOFF:
            document.title = "Connect - Comp. Off Application";
            return "Leave And Attendance";
          case VIEWLEAVEBALACE:
            document.title = "Connect - Leave Balance";
            return "Leave Balance";
          case LEAVETRANSFER:
            document.title = "Connect - Leave Transfer Application";
            return "Leave And Attendance";
          case SPECIAL_ATTENDANCE:
            document.title = "Connect - Special Attendance";
            return "Leave And Attendance";
          case ONLEAVE:
            document.title = "Connect - Leave Plan";
            return "Leave And Attendance - Leave Plan";
          case APPROVESWIPE:
          case APPROVELEAVE:
          case ALLAPPROVAL:
            document.title = "Connect - Leave Approval";
            return "Approval - Leave And Attendance";
          case VIEWDAILY:
            document.title = "Connect - Attendance Report - Weekly";
            return "Attendance Report";
          case VIEWMONTHLY:
            document.title = "Connect - Attendance Report - Monthly";
            return "Attendance Report - Monthly";
          case VIEWYEARLY:
            document.title = "Connect - Attendance Report - Yearly";
            return "Attendance Report - Yearly";
          case ATTENDANCEHRREPORT:
            document.title = "Connect - Attendance Summary";
            return "Attendance Summary";
          default:
            break;
        }
        break;
      case "/HOLIDAYLIST":
        if (moduleId === HOLIDAY_LIST) {
          document.title = "Connect - Holiday List";
          return "Holiday List";
        }
        break;
      case "/DASHBOARD":
        if (moduleId === DASHBOARD) {
          document.title = "OKR Dashboard";
          return "OKR Dashboard";
        }
        break;
      case "/HELPDESK":
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/EnterpriseHelpdesk.ico`);
        }, 0);
        switch (moduleId) {
          case NEWSERVICEREQUEST:
            document.title = "Connect - Enterprise Helpdesk";
            return (
              <>
                Enterprise Helpdesk{" "}
                <sup style={{ fontSize: "14px", fontStyle: "italic" }}>
                  Beta
                </sup>{" "}
                - New Service Request
              </>
            );
          case NEWIDEA:
            document.title = "Connect - Enterprise Helpdesk";
            return (
              <>
                Enterprise Helpdesk{" "}
                <sup style={{ fontSize: "14px", fontStyle: "italic" }}>
                  Beta
                </sup>{" "}
                - New Idea
              </>
            );
          case MYTICKET:
            document.title = "Connect - Enterprise Helpdesk";
            return (
              <>
                Enterprise Helpdesk{" "}
                <sup style={{ fontSize: "14px", fontStyle: "italic" }}>
                  Beta
                </sup>{" "}
                - My Service Requests
              </>
            );
          default:
            break;
        }
        break;
      default:
        setTimeout(() => {
          window.checkURLchange(`${urlOrigin}/images/defaultIcon.ico`);
        }, 0);
        document.title = "Connect";
        return "Connect";
    }
  };

  async componentRefresh() {
    // NOSONAR
    try {
      if (
        this.props.location.pathname !== "undefined" &&
        this.props.location.pathname.toUpperCase() !== "/LOGIN" &&
        this.props.location.pathname.toUpperCase() !== "/LOGOUT" &&
        this.props.location.pathname.toUpperCase() !== "/ERROR" &&
        this.state.userAccessLinks.length === 0
      ) {
        if (
          this.props.authUser !== null &&
          typeof this.props.authUser !== "undefined"
        ) {
          var useraccess;
          const authRes = await dataService.authenticateApplication();
          if (!authRes) {
            console.log("Error in Application Authentication");
          }
          //get the user access links if not populated already
          if (commonAccessRightsFlag === "TRUE") {
            let empDetail = await dataService.getEnployeeBasicInformation(
              this.props.authUser,
              this.props.authUser
            );

            let empEmail = Buffer.from(empDetail[0].EmailAddress).toString(
              "base64"
            );
            useraccess = await dataService.getUserAccessCAR(empEmail);
            this.props.setModuleAttributeSettings(useraccess);
          } else {
            useraccess = await dataService.getUserAccess(this.props.authUser);
          }
          if (!useraccess) {
            console.log("Error in Access Athentication");
            process.exit();
          }
          let access = [];
          useraccess.forEach((ele) => {
            access.push(ele.id);
          });
          const param = queryString.parse(this.props.location.search);

          if (param.id !== undefined) {
            if (!access.includes(dencrypt(param.id).toString())) {
              // NOSONAR
            }
          }
          // Set custom Menu for new orchart Implementation
          // useraccess.map(ele=>{
          //      if(ele.appId == 7){
          //         ele.items.push({id: '135', route: 'orgchart', text: 'Organization Chart', appId: 7, newTab: 'Y'})
          //      }
          // })
          this.setState({ userAccessLinks: useraccess });

          const empMasterData = await getEmpMasterData(
            this.props.authUser,
            "Basic"
          );
          if (empMasterData !== null) {
            this.props.addUpdateTibData({
              fyId: empMasterData.currFY,
              tibAvgSelectedFy: empMasterData.currFY,
            });
            this.props.addUpdateOkrRatingRedux({
              fyId: empMasterData.currFY,
            });
            this.props.addUpdateObjectveKpi({
              fyid: empMasterData.currFY,
            });

            if (typeof empMasterData.TIBAccess !== "undefined") {
              this.props.changeAuthUser({
                otherUser: 0,
                otherUserAccess: "",
                tibAccess: empMasterData.TIBAccess,
                empCode: empMasterData.empCode,
              });
            }
            this.props.setMeetingMaster({
              finacialYearData: empMasterData.FYData,
              currentFinancialYearId: empMasterData.currFY,
            });
            this.props.addUpdateOkrDashboardRedux({
              fyId: empMasterData.currFY,
            });
            this.props.getDefaltSettingConfig({
              defaultsetting: empMasterData.DefTZ,
              baseCountry: empMasterData.baseCountry,
              holidayCountry: empMasterData.holidayCountry,
            });
            this.setState({ empCode: empMasterData.empCode });
          }
          //getting the updated Finance Year data.
          const lnaMastersData = await getLnAData(
            this.props.authUser,
            "masterYearConfig"
          );
          if (lnaMastersData !== null) {
            this.props.setMeetingMaster({
              leaveYear: lnaMastersData.updatedYear,
              leaveYearId: lnaMastersData.updatedYear[0].currFY, // hardcode need to make dynamic...
            });
          }

          //get the meeting type from the database
          let responseNotificationCount =
            await dataService.getMeetingNotifiction(this.props.authUser, "all");
          if (responseNotificationCount !== null) {
            this.setState({
              notificationCount: responseNotificationCount.result.length,
            });
          }
          // Interval for every 5 sec
          this.countdown = setInterval(async () => {
            responseNotificationCount = await dataService.getMeetingNotifiction(
              this.props.authUser,
              "all"
            );
            if (responseNotificationCount !== null) {
              this.setState({
                notificationCount: responseNotificationCount.result.length,
              });
            }
          }, defNotificationSetInterval);

          const timezoneMasterData = await getTimezoneMasterData(
            this.props.authUser
          );
          if (timezoneMasterData !== null) {
            this.props.setMeetingMaster({
              timeZone: timezoneMasterData.timeZone,
              clientTimeZone: timezoneMasterData.cltz,
            });
          }

          setTimeout(() => {
            if (
              this.props.location.pathname.toUpperCase() === "/DEFAULT" &&
              (document.getElementById("e-schedule") === null ||
                typeof document.getElementById("e-schedule") === "undefined")
            ) {
              this.props.history.push("/temp");
              this.props.history.goBack();
            }
          }, 10);

          this.funSetDefault("");
          setTimeout(() => {
            this.hideSidebarInfoEvent();
            if (
              typeof this.sidebarobj !== "undefined" &&
              this.sidebarobj !== null
            ) {
              this.sidebarobj.hide();
              this.changeMenuIconSize();
            }
          }, 100);
        }
      }
    } catch (error) {
      console.error(error, "error");
    }
  }

  funSetDefault(type) {
    // NOSONAR
    const { location } = this.props;
    // Set default meeting category oncxe refresh the EC
    switch (type === "" ? location.pathname.toUpperCase() : type) {
      case "/DEFAULT":
        const m_arrCategory = [
          MY_MEETING,
          MEETING_SET_BY_ME,
          PLAN_MEETING,
          ACTUAL_MEETING,
          PLAN_VS_ACTUAL_MEETING,
          OTHERS_CALENDAR,
          OTHERS_CHECK_AVAILABILITY,
          CALENDAR_REPORT,
        ];
        if (
          !(
            typeof decodeString(localStorage.getItem("meeting_category")) ===
            "undefined" ||
            decodeString(localStorage.getItem("meeting_category")) === ""
          )
        ) {
          if (
            !m_arrCategory.includes(
              decodeString(localStorage.getItem("meeting_category"))
            )
          ) {
            localStorage.setItem(
              "meeting_category",
              encodeString(defMeetingCategory)
            );
            this.props.setMeetingCategory(defMeetingCategory);
          } else {
            this.props.setMeetingCategory(
              decodeString(localStorage.getItem("meeting_category"))
            );
          }
        }
        break;
      case "/ONBOARD":
        const m_arrOnBoardCategory = [
          ON_BOARDING,
          EMPLOYEE_PROFILE,
          ON_BOARDING_FUNCTION,
          EMPLOYEE_LIST,
          ORG_CHART,
        ];
        if (
          !(
            typeof decodeString(localStorage.getItem("meeting_category")) ===
            "undefined" ||
            decodeString(localStorage.getItem("meeting_category")) === ""
          )
        ) {
          if (
            !m_arrOnBoardCategory.includes(
              decodeString(localStorage.getItem("meeting_category"))
            )
          ) {
            localStorage.setItem("meeting_category", encodeString(ON_BOARDING));
            this.props.setMeetingCategory(ON_BOARDING);
          } else {
            this.props.setMeetingCategory(
              decodeString(localStorage.getItem("meeting_category"))
            );
          }
        }
        break;
      case "/SEPARATION":
        const m_arrSeparationCategory = [
          RESIGNATIONMANAGERAPPROVAL,
          RESIGNATIONPNLAPPROVAL,
          RESIGNATION,
          HRAPPROVAL,
          ABSCONDING,
          TERMINATION,
          EXIT_CLEARANCE_FUNCTION,
          ABSCONDINGMANAGER,
          ABSCONDINGHR,
          TERMINATIONHR,
          EXITCLEARANCEGRID,
          EXIT_CLEARANCE_MANAGER,
        ];
        if (
          !(
            typeof decodeString(localStorage.getItem("meeting_category")) ===
            "undefined" ||
            decodeString(localStorage.getItem("meeting_category")) === ""
          )
        ) {
          if (
            !m_arrSeparationCategory.includes(
              decodeString(localStorage.getItem("meeting_category"))
            )
          ) {
            localStorage.setItem("meeting_category", encodeString(RESIGNATION));
            this.props.setMeetingCategory(RESIGNATION);
          } else {
            this.props.setMeetingCategory(
              decodeString(localStorage.getItem("meeting_category"))
            );
          }
        }
        break;
      case "/OKR":
        const m_arrOKRCategory = [
          OKR_OBJECTIVE_KPI,
          OKR_PRODUCTIVE_ALLOCATION,
          OKR_PRODUCTIVE_ALLOCATION,
        ];
        if (
          !(
            typeof decodeString(localStorage.getItem("meeting_category")) ===
            "undefined" ||
            decodeString(localStorage.getItem("meeting_category")) === ""
          )
        ) {
          if (
            !m_arrOKRCategory.includes(
              decodeString(localStorage.getItem("meeting_category"))
            )
          ) {
            localStorage.setItem(
              "meeting_category",
              encodeString(OKR_OBJECTIVE_KPI)
            );
            this.props.setMeetingCategory(OKR_OBJECTIVE_KPI);
          } else {
            this.props.setMeetingCategory(
              decodeString(localStorage.getItem("meeting_category"))
            );
          }
        }
        break;
      case "/OKRREVIEW":
        const m_arrOKRRatingCategory = [
          OKR_SELF_REVIEW,
          OKR_SUPERVISOR_REVIEW,
          OKR_THIRD_PART_REVIEW,
          OKR_REVIEW,
          OKR_RATING_AND_REVIEW,
        ];
        if (
          !(
            typeof decodeString(localStorage.getItem("meeting_category")) ===
            "undefined" ||
            decodeString(localStorage.getItem("meeting_category")) === ""
          )
        ) {
          if (
            !m_arrOKRRatingCategory.includes(
              decodeString(localStorage.getItem("meeting_category"))
            )
          ) {
            localStorage.setItem(
              "meeting_category",
              encodeString(OKR_RATING_AND_REVIEW)
            );
            this.props.setMeetingCategory(OKR_RATING_AND_REVIEW);
          } else {
            this.props.setMeetingCategory(
              decodeString(localStorage.getItem("meeting_category"))
            );
          }
        }
        break;
      default:
    }
  }

  hideSidebarInfoEvent = () => {
    const { userHelp } = this.state;
    if (userHelp === true) {
      this.closeSetting();
    }
    if (
      typeof this.sidebarInstance !== "undefined" &&
      this.sidebarInstance !== null
    ) {
      setTimeout(() => {
        if (
          typeof this.sidebarInstance !== "undefined" &&
          this.sidebarInstance !== null &&
          this.props.location.pathname.toUpperCase() !== "/ORGCHART"
        )
          this.sidebarInstance.hide();
      }, 200);
    }
  };

  //  Author:- Prashant Waphare | close seeting Box
  closeSetting = () => {
    this.setState({ userHelp: false });
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const { location } = this.props;
    const updateWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const updateHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) -
      (location.pathname.toUpperCase() === "/CANDUPL" ? 0 : 50.5);

    this.setState({ height: updateHeight, width: updateWidth });
    this.hideSidebarInfoEvent();
    setTimeout(() => {
      if (typeof this.sidebarobj !== "undefined" && this.sidebarobj !== null) {
        this.sidebarobj.hide();
      }
    }, 1000);
  }

  showOrgchartOverView = () => {
    const { showOverView } = this.state;
    const hideShowdiv = document.getElementById("overview-div-show-hide");
    hideShowdiv.style.display =
      hideShowdiv.style.display === "none" ? "block" : "none";
    this.setState({
      showOverView: !showOverView,
    });
  };

  getTicketUrlPath = () => {
    const userName = decodeString(localStorage.getItem("helpdeskUserName"));
    const userEmail = decodeString(localStorage.getItem("emailAddress"));
    const haskString =
      userName +
      userEmail +
      process.env.REACT_APP_HD_SHARED_SECRET +
      format(new Date(), "ddMM");
    const hash = md5(haskString);
    return `User/AutoLogin?username=${userName}&email=${userEmail}&userHash=${hash}`;
  };

  handleClick = (isScheduleVsActual, lintoURL, item, appId, e, isBre) => {
    // e.stopPropagation();
    const currentUrl = window.location.origin; // Get the current URL
    const targetUrl = isScheduleVsActual ? '/app/calendar' : typeof lintoURL === 'string' ? lintoURL : lintoURL.pathname;
    if (item.link === currentUrl) {
      if (item.newTab === 'Y') {
        window.open(targetUrl, '_blank');
      } else {
        this.props.history.push(targetUrl);
      }
    } else {
      const appID = [CONNECT_LANDING_PAGE_APP,
        // PRE_OFFER_APP,
        // ON_BOARDING_APP, 
        // CONFIRMATIONS_APP, 
        // SEPARATION_APP, 
        ON_BOARDING_NEW_APP,
        VENDOR_MANAGEMENT_APP,
        PURCHASE_ORDER_APP,
        MASTERS_APP,
        APPROVALS_APP
      ];
      const originUrl = appID.includes(appId) ? `${item.link}/auth/login` : `${item.link}/login`
      const newUrl = `${originUrl}?id=${encrypt(item?.id)}&appId=${encrypt(appId)}&authUser=${encrypt(this.props.authUser)}&appCode=${encodeString(item.appName)}&redirectUrl=${item.menuComponentLink}&token=${localStorage.getItem("jwtToken").split(' ')[1]}`;
      if (item.newTab === 'Y') {
        window.open(newUrl, '_blank');
      } else {
        window.location.href = newUrl;
      }
    }
  };
  openlandingPage = (data) => {
    if (data?.id === '139') {
      const item = data?.subMenu[0];
      const originUrl = item.appName === 'EC' ? `${item.link}/login` : `${item.link}/auth/login`;
      const newUrl = `${originUrl}?id=${encrypt(item?.id)}&appId=${encrypt(data.appId)}&authUser=${encrypt(this.props.authUser)}&appCode=${encodeString(item.appName)}&redirectUrl=${item.menuComponentLink}&token=${localStorage.getItem("jwtToken").split(' ')[1]}`;
      if (item.newTab === 'Y') {
        window.open(newUrl, '_blank');
      } else {
        window.location.href = newUrl;
      }
    }
  };

  menuTemplate(data, showSidebarMenu) {
    try {
      if (data.menuLevel === 0) {
        return (
          <div style={{ display: "flex" }}
            onClick={() => this.openlandingPage(data.menuChildren[0])}>
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // width: 40,
                // marginTop: -4,
              }}
            >
              <div id="menu-side-bar-icons" style={{ color: 'white', fontSize: showSidebarMenu ? 15 : 20, marginRight: 20 }}>
                <div dangerouslySetInnerHTML={{ __html: data.svgiconCss }} />
              </div>
              {/* <i
                className={data.icon}
                id="menu-side-bar-icons"
                style={{
                  color: "white",
                  fontSize: showSidebarMenu ? 15 : 20,
                  paddingLeft: 3.5,
                  marginRight: 19.5,
                }}
              ></i>   */}
            </div>
            <div className="menu_list" style={{ fontSize: 14, marginLeft: 2 }}>
              {data.sectionName}
            </div>
          </div>
        );
      }
      if (data.menuLevel === 1 ) {
        return (
          <div className="menu-container" id="menuContainer-SideBar">
            {data.subMenu && (
              <div className="menu-div">
                <div className="menuTitle" style={{ width: "18.5rem" }}>
                  <div
                    style={{
                      paddingLeft: "1.4rem",
                      marginLeft: "10px",
                      marginRight: "15px",
                      cursor: "default",
                      fontWeight: "100",
                    }}
                  >
                    {" "}
                    {data.menuName}
                  </div>

                  <hr style={{ minWidth: "100px" }} />

                  <div
                    className={`flex-list ${data.subMenu?.length === 1
                      ? "menu-on-hover-background"
                      : ""
                      }`}
                    style={{ paddingLeft: 24 }}
                  >
                    {
                      data?.subMenu.map((item, index) => {
                        let lintoURL = `${item.menuComponentLink}?id=${encrypt(
                          item.id
                        )}&appId=${encrypt(data.appId)}`;
                        let isSida = item.id.toString() === OKR_SIDA;
                        if (isSida) {
                          lintoURL = {
                            pathname: process.env.REACT_APP_SIDA_URL,
                          };
                        } else if (item.id.toString() === TICKET_RESOLUTION) {
                          lintoURL = {
                            pathname: `${urlConfig.helpDeskUrl
                              }${this.getTicketUrlPath()}`,
                          };
                        } else if (item.id.toString() === TICKET_REPORTS) {
                          lintoURL = {
                            pathname: `${urlConfig.helpDeskUrl
                              }${this.getTicketUrlPath()}&ReturnUrl=%2fReporting`,
                          };
                        } else if (item.id.toString() === TICKET_ADMIN_MODULE) {
                          lintoURL = {
                            pathname: `${urlConfig.helpDeskUrl
                              }${this.getTicketUrlPath()}&ReturnUrl=%2fAdmin`,
                          };
                        }
                        return (
                          <div key={item.id}>
                            {data.subMenu?.length === 1 ? (
                              <div
                                className="menu-link-style"
                                style={{
                                  fontWeight: 100,
                                }}
                              >
                                {isSida ? (
                                  <span
                                    onKeyDown={(event) => {
                                      if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
                                        this.funOpenSIDA();
                                      }
                                    }}
                                    onClick={this.funOpenSIDA}>
                                    <div
                                      className="menu-on-hover-background"
                                      style={{
                                        // padding: "0px .5rem",
                                        fontWeight: 100,
                                      }}
                                    >
                                      <div className="title-sidebar-menu">
                                        {" "}
                                        {item.menuName}
                                      </div>
                                    </div>
                                  </span>
                                ) : (
                                  <div
                                    className="menu-on-hover-background"
                                    style={{
                                      // padding: "0px .5rem",
                                      fontWeight: 100,
                                    }}
                                    onClick={(e) => this.handleClick(false, lintoURL, item, data.appId, e, item.id.toString() === BUSINESS_RULE_ENGINE)}
                                  >
                                    <div className="title-sidebar-menu">
                                      {" "}
                                      {item.menuName}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <div className="menu-link-style">
                                  {isSida ? (
                                    <span
                                      onKeyDown={(event) => {
                                        if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
                                          this.funOpenSIDA();
                                        }
                                      }}
                                      onClick={this.funOpenSIDA}
                                    >
                                      <div
                                        className="menu-on-hover-background"
                                        style={{
                                          // padding: "0px .5rem",
                                          fontWeight: 100,
                                        }}
                                      >
                                        <div className="title-sidebar-menu">
                                          {" "}
                                          {item.menuName}
                                        </div>
                                      </div>
                                    </span>
                                  ) : (
                                    <div
                                      className="menu-on-hover-background"
                                      style={{
                                        // padding: "0px .5rem",
                                        fontWeight: 100,
                                      }}
                                      onClick={(e) => this.handleClick(false, lintoURL, item, data.appId, e, item.id.toString() === BUSINESS_RULE_ENGINE)}
                                    >
                                      <div className="title-sidebar-menu">
                                        {" "}
                                        {item.menuName}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {data.subMenu.length !== index + 1 && (
                                  <div className="pipe-sidebar-menu"></div>
                                )}
                              </div>
                            )}
                            <div className="flex-list">
                              {item.subMenu &&
                                item.subMenu.map((menu_item) => {
                                  return (
                                    <Link
                                      to={`${menu_item.menuLink}?id=${encrypt(
                                        menu_item.id
                                      )}&appId=${encrypt(item.id)}`}
                                      className="menu-linkto"
                                    >
                                      <div
                                        className="list-items"
                                        key={menu_item.menuName}
                                        style={{
                                          color: "white",
                                          minWidth: 120,
                                          fontSize: 14,
                                        }}
                                      >
                                        {menu_item.menuName}{" "}
                                        {item.subMenu.length !== index + 1 && (
                                          <span className="pipe-sidebar-menu"></span>
                                        )}
                                      </div>
                                    </Link>
                                  );
                                  // }
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }
    } catch (err) { }
  }

  funOpenSIDA = () => {
    try {
      const { empCode } = this.props;
      var form = document.createElement("form");
      form.action = process.env.REACT_APP_SIDA_URL;
      form.method = "POST";
      form.target = "_blank";

      var emp_id = document.createElement("input");
      emp_id.value = empCode;
      emp_id.name = "emp_id_sida";
      emp_id.type = "hidden";
      form.appendChild(emp_id);

      document.body.appendChild(form);
      form.submit();
    } catch (e) { }
  };

  navToNewServiceRequest = () => {
    const { id } = queryString.parse(this.props.location.search);
    const path = `/helpdesk?id=${encrypt(
      NEWSERVICEREQUEST
    )}&appId=MjIxNTE5MC43Mg==&sourceId=${id}`;
    return window.open(path);
  };
  navToNewIdeas = () => {
    const { id } = queryString.parse(this.props.location.search);
    const path = `/helpdesk?id=${encrypt(
      NEWIDEA
    )}&appId=MjIxNTE5MC43Mg==&sourceId=${id}`;
    return window.open(path);
  };

  render() {
    // NOSONAR
    const fields = {
      text: ["sectionName"],
      children: ["menuChildren"],
    };
    const { match, location, authUser, initURL, timeZone } = this.props;
    const {
      notificationCount,
      newAnnouncement,
      userHelp,
      empCode,
      showOverView,
      moduleId,
    } = this.state; //

    const actEmpCode =
      defEnvironment === "QA" && empCode !== ""
        ? defEnvironment + empCode
        : empCode;
    if (location.pathname === "/") {
      if (
        (authUser === null || isNaN(this.props.authUser)) &&
        defEnvironment === "QA"
      ) {
        return <Redirect to={"/login"} />;
      } else if (
        (authUser === null || isNaN(this.props.authUser)) &&
        (defEnvironment === "LIVE" || defEnvironment === "UAT")
      ) {
        return <Redirect to={"/error"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/login") {
        return (
          <Redirect
            to={`/default?id=${encrypt(PLAN_VS_ACTUAL_MEETING)}&appId=${encrypt(
              1
            )}`}
          />
        );
      } else {
        return <Redirect to={initURL} />;
      }
    }
    const applyTheme = createTheme(defaultTheme);
    const heading = this.getHeading();

    let isHide = true;
    if (
      location.pathname === "undefined" ||
      location.pathname.toUpperCase() === "/CANDUPL" ||
      location.pathname.toUpperCase() === "/LOGIN" ||
      location.pathname.toUpperCase() === "/LOGOUT" ||
      location.pathname.toUpperCase() === "/ERROR"
    ) {
      isHide = false;
    }
    const style = `
        .menu-linkto{
            text-decoration: none !important;
        }
        .new-menu-sidebar-links{
            height:42px!important;
            width: 234px;
        }
	.e-menu-wrapper .e-ul .e-menu-item span {
		color: #ffffff !important;
		font-size: 14px !important;
	}
	.e-menu-wrapper .e-ul .e-menu-item{
		padding:0 0px !important;
	}
    #sign01{
        padding:0 6px !important;
    }
	
	.menuTitle{
		font-weight: bold;
	}
	/* end of horizontal-menu styles */
	/* vertical-menu styles */

	.menu-title-sidebar-new{
        font-size:14px !important;
    }
	
	
	.dock-menu.e-close .e-menu-wrapper ul .e-menu-item {
		width: 56px;
		height: 42px;
	}
	.pipe-sidebar-menu {
        border: 1px solid white;
        height: 1.2rem;
        margin: 0 .5rem;
    }
	
	#sidebar-menu.e-dock.e-close .e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
	#sidebar-menu.e-dock.e-close .e-menu-wrapper ul.e-vertical {
		min-width: 0px;
		width: 54px !important;
		padding-top: 0px;
		height: 100% !important;
	}

	#sidebar-menu,
	#sidebar-menu ul,
	.dock-menu .e-menu-wrapper,
	.dock-menu.e-menu-wrapper,
	.dock-menu.e-menu-wrapper ul>*,
	.dock-menu .e-menu-wrapper ul>* {
		background-color: black !important;
	}
	
    .sidebar-menu-new .e-ul {
        max-width:56rem !important;
        display:flex !important;
        flex-wrap:wrap !important;
    }
        
	/*end of main-menu-header  styles */
	
	
	
	#sidebar-menu {
		background-color: black !important;
		border: none !important;
	}
	.e-menu-wrapper .e-ul {
        background-color: black !important;
    }
    
	// .e-menu-wrapper.e-popup.e-menu-popup  {
    //     max-width: 53vw !important;
	// 	height: auto;
	// 	// position: fixed;
	// 	// top: 56px !important;
	// 	border-radius: 0 0 5px 0;
	// 	background-color: black !important;
	// 	// max-width: 702px;
    //     margin-left:-2px ;  
	// }

    

  .menu-container .e-popup-open{
    left: 50px !important;
  }
    
       
	.flex-list {
	
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// width : 220px
		// padding-left: 14px!important;
	}
	
	.flex-list ul {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// margin-left: -1px;
	}
	
	.flex-list li {
		flex-basis: auto;
		padding: 0px 0.5rem 0.5em;
		border-left: 1px solid rgb(255, 255, 255);
		color: #fff;
	}
    .sidebar-menu-new .e-ul .e-focused {
        background:black !important;
    }
	
	ul {
        /*	list-style-type: none;
		margin-block-start: 0px;
		margin-block-end: 0px;
		padding-inline-start: 0px;*/
	}

    .flex-list-inline {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
    }
	
	.e-template-menu.e-menu-wrapper ul.e-ul .e-menu-item {
		height: auto;
	}
	
	.e-menu-wrapper .e-ul .e-menu-item,
	.e-menu-container .e-ul .e-menu-item {
		height: auto !important
	}
	
	

	
	hr {
		background-color: #fff !important;
		margin: 0px !important;
		padding: 0px !important;
		opacity: 0.4;
		// width: 100%;
	}
	
	.single-menu {
		// min-width: 200px;
		
		font-weight: normal;
		height:42px !important;
		border-bottom: 1px solid weight;
        font-size:14px !important;
      
	}
	
	.list-items {
		margin: 10px 0px 10px 0px !important;
		padding: 0px 10px 0px 5px !important;
		line-height: 13px;
		cursor: pointer;
		border-left: 1px solid rgb(255, 255, 255);
		color: #fff;
		
	}

	
	.hr-style {
		text-align: left;
		
	}
	
	.menu-div {
		background-color: black;
		color: white !important;
	}
	.menu_icon{
		font-size : 24px ;
		display: inline-flex;
		flex-direction: row;
		// margin-left: 14px;
		margin-right: 10px;
		height: 24px;
		width: 54px;
		line-height: 46px;
	}
	.menu_list{
		padding:1px 0px 5px 0px ;
		height:42px !important;
		font-size: 16px;
	}
	.e-menu-wrapper ul .e-menu-item .e-caret{
		line-height: 46px !important;
	}
	
	
	
	.flex-list :first-child{
		border-left: none;
		// padding-left: 0px !important;
	}
	.flex-list :nth-child(4){
		border-left: none;
		padding-left: 0px !important;
	}

	.e-menu-wrapper .e-menu .e-menu-item.e-selected{
		color:#fff !important;
		// background-color: #F94F5E !important;
	}
    .title-sidebar-menu{
		color:white;
	}
	// .title-sidebar-menu:hover{
	// 	color:#F94F5E !important;
	// }

    .menu-on-hover-background {
        padding: 0 .5rem;
    }

    .menu-on-hover-background:hover {
        background-color: #ED4B5A;
        color: #fff;
    }

    .menu-link-style a {
        text-decoration: none;
    }

    #maintext .e-control.e-sidebar.e-lib.e-sidebar-absolute.e-left.e-dock.e-transition.e-touch.e-close.e-push{
        transform: translateX(-100%) translateX(53px) !important;
    }

    
    @media only screen and (min-device-width: 1920px) and (max-device-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-width: 1536px) and (max-device-width: 1536px) and (-webkit-min-device-pixel-ratio: 1.25) and (min-resolution: 192dpi),
    only screen and (min-device-width: 1440px) and (max-device-width: 1440px) and (-webkit-min-device-pixel-ratio: 1.25) and (min-resolution: 192dpi) {
    .e-menu-wrapper.e-popup.e-menu-popup {
        max-width: 40vw !important;
        height: auto;
		border-radius: 0 0 5px 0;
		background-color: black !important;
        margin-left:-10px !important;
    }
    
  }

  @media screen and (max-width: 1536px) and (max-height: 746px) {
    #maintext .e-control.e-sidebar.e-lib.e-sidebar-absolute.e-left.e-dock.e-transition.e-touch.e-close.e-push{
        transform: translateX(-100%) translateX(53px) !important;
    }
  }
    
	`;
    const featureStyleClass =
      notificationCount === 0
        ? "right-header list featureStyleClone"
        : "right-header list featureStyle";
    return (
      <MuiThemeProvider theme={applyTheme}>
        <div className="app-main">
          {dencrypt(localStorage.getItem("notificationSettingAccess")) === 1 &&
            actEmpCode !== "" && <SocketIO empCode={actEmpCode} />}
          <div className="control-section">
            {
              <div
                id="wrapper"
                style={{ borderColor: "2px solid red", overflow: "hidden" }}
              >
                <div className="col-lg-12 col-sm-12 col-md-12">
                  {isHide && (
                    <div className="header-section dock-menu" id="header">
                      <ul className="header-list">
                        <li
                          id="hamburger"
                          className="icon-menu icon list"
                          onClick={this.openClick}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
                              this.openClick();
                            }
                          }}
                        ></li>
                        <span
                          className="brand header-class-enterprise"
                          style={{ color: heading === "." ? "black" : "" }}
                        >
                          {heading}
                        </span>

                        <li className="right-header list">
                          <div className="horizontal-menu">
                            <MenuComponent
                              items={this.AccountMenuItem}
                              select={this.onSelect}
                              cssClass="dock-menu"
                            ></MenuComponent>
                          </div>
                        </li>
                        <li className="right-header list tour">
                          <Dropdown
                            className="quick-menu"
                            isOpen={userHelp}
                            toggle={this.getHelp}
                          >
                            <DropdownToggle
                              className="d-inline-block"
                              tag="span"
                              data-toggle="dropdown"
                              onClick={this.closeSetting}

                            >
                              <FontAwesomeIcon
                                title="Settings" style={{ color: "#fff", padding: 0 }}
                                icon={faCog}
                                onClick={() => this.hideSidebarInfoEvent()}
                              />
                            </DropdownToggle>
                            {timeZone.length > 0 && (
                              <DropdownMenu
                                right
                              // style={{transform: 'translate3d(-398px, 28px, 0px)'}}
                              >
                                <Setting
                                  timeZoneSetting={timeZone}
                                  hideSetting={this.closeSetting}
                                />
                              </DropdownMenu>
                            )}
                          </Dropdown>
                        </li>
                        <li className="right-header list tour">
                          <div
                            className="sample_container badge-toolbar mt-none"
                            style={{ position: "relative" }}
                          >
                            <div className="badge-block">
                              {this.state.notificationCount === 0 ? (
                                <FontAwesomeIcon
                                  icon={faBell}
                                  style={{ color: "#fff" }}
                                  onClick={() => {
                                    this.closeSetting();
                                    this.hideSidebarInfoEvent();
                                  }}
                                />
                              ) : (
                                <Dropdown
                                  className="quick-menu"
                                  isOpen={this.state.appNotification}
                                  toggle={this.onAppNotificationSelect}

                                >

                                  <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown"
                                    onClick={this.onAppNotificationSelect}

                                  >
                                    <IconButton
                                      className="icon-btn"
                                      style={{ padding: 0 }}
                                      onClick={() => {
                                        this.closeSetting();
                                        this.hideSidebarInfoEvent();
                                      }}
                                    >
                                      <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                                    </IconButton>
                                    <FontAwesomeIcon
                                      icon={faBell}
                                      style={{ color: "#fff" }}
                                      title="Notifications"
                                      onClick={() => {
                                        this.closeSetting();
                                        this.hideSidebarInfoEvent();
                                        this.sidebarobj.hide();
                                        this.changeMenuIconSize();
                                      }}
                                    />
                                    <span
                                      style={{ top: "0" }}
                                      className="e-badge e-badge-danger e-badge-notification e-badge-overlap p-none"
                                      onClick={() => {
                                        this.hideSidebarInfoEvent();
                                      }}
                                    >
                                      {this.state.notificationCount}
                                    </span>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    right
                                    className="dropdown-menu-notification"
                                  // style={{transform: 'translate3d(-375px, 28px, 0px)'}}
                                  >
                                    <NotificationPopUp
                                      hideNotification={
                                        this.onAppNotificationSelect
                                      }
                                    />
                                  </DropdownMenu>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                        </li>
                        {/* LP */}
                        <li className="right-header list tour">
                          <Dropdown className="quick-menu">
                            <DropdownToggle
                              className="d-inline-block "
                              tag="span"
                              data-toggle="dropdown"
                            >
                              <Link target="_blank" to="/lp">
                                <FontAwesomeIcon
                                  title="Leadership Principles"
                                  style={{ color: "#fff" }}
                                  icon={faBullseyeArrow}
                                />
                              </Link>
                            </DropdownToggle>
                          </Dropdown>
                        </li>
                        {/* Route 66 */}
                        <li className="right-header list tour">
                          <Dropdown className="quick-menu">
                            <DropdownToggle
                              className="d-inline-block"
                              tag="span"
                              data-toggle="dropdown"
                            >
                              <Link target="_blank" to="/route66">
                                <FontAwesomeIcon
                                  title="Route 66"
                                  style={{
                                    color: "#fff",
                                    margin: "0px 8px 0px 0px",
                                  }}
                                  icon={faHandshake}
                                />
                              </Link>
                            </DropdownToggle>
                          </Dropdown>
                        </li>

                        <li className="right-header list tour">
                          <Dropdown className="quick-menu">
                            <FontAwesomeIcon
                              title="Apply Leave"
                              style={{
                                color: "#fff",
                                margin: "0px 8px 0px 0px",
                              }}
                              icon={faClipboardCheck}
                              onClick={() => this.onLeaveDetailsClick()}
                            />
                          </Dropdown>
                        </li>
                        {/* New service request */}
                        <li className="right-header list tour">
                          <Dropdown className="quick-menu">
                            <FontAwesomeIcon
                              title="New Service Request"
                              icon={faScrewdriverWrench}
                              style={{
                                color: "#fff",
                                margin: "0px 8px 0px 0px",
                              }}
                              onClick={this.navToNewServiceRequest}
                            />
                          </Dropdown>
                        </li>
                        {/* New Idea */}
                        {/* <li className="right-header list tour">
                                                    <Dropdown className="quick-menu" >
                                                        <FontAwesomeIcon
                                                            title='New Idea'
                                                            icon={faLightbulbOn}
                                                            style={{ color: "#fff", margin: "0px 8px 0px 0px" }}
                                                            onClick={this.navToNewIdeas}
                                                        />
                                                    </Dropdown>
                                                </li> */}
                      </ul>
                    </div>
                  )}
                  {isHide && (
                    <SidebarComponent
                      id="sidebar-menu"
                      ref={(Sidebar) => (this.sidebarobj = Sidebar)}
                      enableDock={this.enableDock}
                      mediaQuery={this.mediaQuery}
                      type="Push"
                      isOpen={false}
                      Enable={false}
                      dockSize={this.dockSize}
                      width={this.width}
                      target={this.target}
                      closeOnDocumentClick={true}
                    >
                      {this.state.userAccessLinks.length > 0 && (
                        <div>
                          <style>{style}</style>
                          <MenuComponent
                            style={{
                              transform:
                                "translateX(-100%) translateX(53px) !important",
                            }}
                            items={this.state.userAccessLinks}
                            orientation="Vertical"
                            id="sidebar-menu"
                            cssClass="sidebar-menu-new"
                            select={(e) => this.handleCategoryChange(e, true)}
                            template={(data) =>
                              this.menuTemplate(data, this.showSidebarMenu)
                            }
                            fields={fields}
                          />
                        </div>
                      )}
                    </SidebarComponent>
                  )}

                  <div
                    className="main-content"
                    id="maintext"
                    style={{
                      height: this.state.height,
                      width: this.state.width,
                      overflow: "hidden",
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
                        this.hideSidebarInfoEvent();
                        if (
                          typeof this.sidebarobj !== "undefined" &&
                          this.sidebarobj !== null
                        ) {
                          this.sidebarobj.hide();
                          this.changeMenuIconSize();
                        }
                      }
                    }}
                    onClick={() => {
                      // NOSONAR
                      this.hideSidebarInfoEvent();
                      if (
                        typeof this.sidebarobj !== "undefined" &&
                        this.sidebarobj !== null
                      ) {
                        this.sidebarobj.hide();
                        this.changeMenuIconSize();
                      }
                    }}
                  >
                    <div>
                      <div style={{ height: "100%", width: "100%" }}>
                        <Switch>
                          <RestrictedRoute
                            path={`${match.url}app`}
                            authUser={authUser}
                            component={Default}
                          />
                          <Route path="/default" component={Default} exact />
                          <Route path="/tib" component={TIBRLayout} />
                          <Route
                            path="/confirmation"
                            component={ConfirmationLayout}
                          />
                          <Route path="/okr" component={OKRLayout} />
                          <Route path="/okrreview" component={RatingLayout} />
                          <Route path="/preoffer" component={PreOfferLayout} />
                          <Route path="/onboard" component={Onboardinglayout} />
                          <Route
                            path="/separation"
                            component={SeparationLayout}
                          />
                          <Route path="/orgchart" component={OrgChartLayout} />
                          <Route path="/candupl" component={CandUplLayout} />
                          <Route
                            path="/homeoffice"
                            component={HomeOfficeLayout}
                          />
                          <Route path="/login" component={LogIn} />
                          <Route path="/logout" component={Logout} />
                          <Route
                            path="/session_expired"
                            component={SessionExpired}
                          />
                          <Route path="/lp" component={LP} />
                          <Route path="/route66" component={Route66} />
                          <Route path="/error" component={Error404} />
                          <Route path="/admin" component={AdminLayout} />
                          {/* <Route path='/setup' component={SetupLayout} />                                                     */}
                          <Route path="/lna" component={LnAlayout} />
                          <Route
                            path="/dashboard"
                            component={OKRDashboardLayout}
                          />
                          <Route path="/helpdesk" component={HelpDesk} />
                          <Route
                            path="/leaveApproval"
                            component={LeaveEmailApprove}
                          />
                          {/* <Route path='/teammonthreport' component={TeamMonthReportContainer} /> */}
                          {/* <Route path='/holidaylist' component={HolidayListLayout} />
                                                    <Route path='/employeereport' component={EmployeeReport} />
                                                    <Route path='/weeklyreport' component={WeeklyReport} />
                                                    <Route path='/teammemberreport' component={TeamMemberReport} /> */}
                        </Switch>
                      </div>
                    </div>
                    {isHide && <Footer />}
                  </div>
                  {this.calendarModuleIds.includes(moduleId) && (
                    <div
                      className="sidebutton"
                      style={{
                        display:
                          moduleId === CALENDAR_REPORT ? "none" : "block",
                      }}
                    >
                      <Button
                        onClick={this.legendClick}
                        style={{ width: "25px", height: "25px" }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} title="Info" />
                      </Button>
                    </div>
                  )}
                  {location.pathname.toUpperCase() === "/ORGCHART" &&
                    moduleId === ORG_CHART && (
                      <div
                        className="sidebutton-org"
                        style={{ display: "block" }}
                      >
                        {<style>{buttoncss}</style>}
                        <Button
                          onClick={() => this.showOrgchartOverView()}
                          style={{ width: "25px", height: "29px" }}
                        >
                          {showOverView ? <div>Hide </div> : <div>Show</div>}
                        </Button>
                      </div>
                    )}
                  {((location.pathname.toUpperCase() === "/TIB" &&
                    moduleId === TIME_IN_BUSINESS) ||
                    moduleId === HOLIDAY_LIST) && (
                      <div
                        className="sidebutton"
                        style={{
                          display:
                            moduleId === CALENDAR_REPORT ? "none" : "block",
                        }}
                      >
                        <Button
                          onClick={this.legendClick}
                          style={{ width: "25px", height: "25px" }}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} title="Info" />
                        </Button>
                      </div>
                    )}
                  {(this.calendarModuleIds.includes(moduleId) ||
                    (location.pathname.toUpperCase() === "/TIB" &&
                      moduleId != UTILIZATION_OF_TIME_REPORT) ||
                    (location.pathname.toUpperCase() === "/ORGCHART" &&
                      moduleId === ORG_CHART) ||
                    moduleId === HOLIDAY_LIST) &&
                    moduleId != UTILIZATION_OF_TIME_REPORT && (
                      <SidebarComponent
                        id="default-sidebar"
                        ref={(Sidebar) => (this.sidebarInstance = Sidebar)}
                        position={"Right"}
                        type={"Over"}
                        style={{ height: "92.7%" }}
                      >
                        <div className="sub-title">
                          {this.calendarModuleIds.includes(moduleId) ? (
                            <Legends authUser={authUser} />
                          ) : (
                            <>
                              {moduleId !== HOLIDAY_LIST && (
                                <OrgChartSideBar authUser={authUser} />
                              )}
                            </>
                          )}
                          {location.pathname.toUpperCase() === "/TIB" && (
                            <Legends authUser={authUser} />
                          )}
                          {moduleId === HOLIDAY_LIST && (
                            <Legends authUser={authUser} type={"holidaylist"} />
                          )}
                        </div>
                      </SidebarComponent>
                    )}
                  {location.pathname.toUpperCase() === "/TIB" &&
                    moduleId != UTILIZATION_OF_TIME_REPORT && (
                      <SidebarComponent
                        id="default-sidebar"
                        ref={(Sidebar) => (this.sidebarInstance = Sidebar)}
                        position={"Right"}
                        type={"Over"}
                      >
                        {/* <div className="title-header">
                                                                    <div style={{ display: 'inline-block' }}> Meeting Property </div>
                                                                    <span id="close" className="e-icons" onClick={this.legendClick}></span></div> */}

                        <div className="sub-title">
                          {location.pathname.toUpperCase() === "/TIB" && (
                            <TibrLegends authUser={authUser} />
                          )}
                        </div>
                      </SidebarComponent>
                    )}

                  {location.pathname.toUpperCase() === "/TIB" &&
                    moduleId != UTILIZATION_OF_TIME_REPORT && (
                      <SidebarComponent
                        id="default-sidebar"
                        ref={(Sidebar) => (this.sidebarInstance = Sidebar)}
                        position={"Right"}
                        type={"Over"}
                      >
                        <div className="sub-title">
                          {location.pathname.toUpperCase() === "/TIB" && (
                            <TibrLegends authUser={authUser} />
                          )}
                        </div>
                      </SidebarComponent>
                    )}

                  {location.pathname.toUpperCase() === "/TIB" &&
                    moduleId != UTILIZATION_OF_TIME_REPORT && (
                      <SidebarComponent
                        id="default-sidebar"
                        ref={(Sidebar) => (this.sidebarInstance = Sidebar)}
                        position={"Right"}
                        type={"Over"}
                      >
                        <div className="sub-title">
                          {location.pathname.toUpperCase() === "/TIB" && (
                            <TibrLegends authUser={authUser} />
                          )}
                        </div>
                      </SidebarComponent>
                    )}
                </div>
              </div>
            }
          </div>
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={this.state.navTabOpen}
            button1function={() => this.handleNavYes()}
            button2function={() => this.handleNavNo()}
            headerMessage={this.state.navMessage}
            button1Text={this.state.button1Caption}
            button2Text={this.state.button2Caption}
            handleConfirmClose={this.handleConfirmClose}
          />
        </div>
      </MuiThemeProvider >
    );
  }
}
const mapStateToProps = ({
  auth,
  meetingcategory,
  meetingmaster,
  meetingbooking,
  router,
  onboardingreducer,
  tibReducer,
  homeoffice,
  okrreducer,
  usersettingreducer,
}) => {
  const { location } = router;
  const {
    authUser,
    initURL,
    otherUser,
    otherUserAccess,
    tibAccess,
    openOrgChartSidebar,
    empCode,
  } = auth;
  const { categoryName } = meetingcategory;
  const { candidateid, docLinkForExternal } = onboardingreducer;
  const {
    loader,
    executiveAssistant,
    timeZone,
    clientTimeZone,
    meetingType,
    campaignData,
  } = meetingmaster;
  const { selectedTIBMonths } = tibReducer;
  const { meetingStatus, getNotifyFlag } = meetingbooking;
  const { objectiveData, pendingForApprovalObjectiveData } = okrreducer;
  const { moduleAttributeSetting } = usersettingreducer;

  return {
    authUser,
    campaignData,
    initURL,
    categoryName,
    loader,
    meetingStatus,
    executiveAssistant,
    meetingType,
    otherUser,
    otherUserAccess,
    timeZone,
    clientTimeZone,
    selectedTIBMonths,
    tibAccess,
    getNotifyFlag,
    location,
    candidateid,
    openOrgChartSidebar,
    objectiveData,
    pendingForApprovalObjectiveData,
    moduleAttributeSetting,
    empCode,
    docLinkForExternal,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  userSignOut,
  setMeetingCategory,
  setMeetingMaster,
  changeAuthUser,
  updateMeetingBookingState,
  getDefaltSettingConfig,
  updateUserInfo,
  setMonths,
  saveBasicInformationInRedux,
  addEditHomeOfficeDetails,
  addUpdateOkrDashboardRedux,
  setModuleAttributeSettings,
  setCentralOkrKPIRedux,
  addUpdateTibData,
  updateCandidateData,
  updateLnAData,
  setOrhChartDetaila,
  addUpdateOkrRatingRedux,
  addUpdateObjectveKpi,

})(Layout);
